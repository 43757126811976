import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  // ProfileButtonsContainer,
  ProfileFormContainer,
  // ProfileFormInputsContainer,
  // ProfileFormsContainer,
  // ProfileLastButtonsContainer,
  // FixedAmountText,
  // ProfileSmallButtonGroup,
  // ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  // ProfileFormSeparator,
  ProfileBillingContainer,
  ProfileTableContainer,
  TableTitle,
  // TableInfo,
  TableInfoContainer
} from "./tasks-styles";
import i18n from '../../i18n/i18n';
// import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import UploaderButton from "../../components/buttons/uploader-button/UploaderButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { TASK_DETAILS_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { exportTasks, exportTasksExcel, getTasksAction, getTasksStatusesAction, importTasks } from "../task-details/tasks-actions";
import TextInput from "../../components/inputs/text-input/TextInput";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import SelectButton from "../../components/buttons/select-button/Select-Button";

const TasksScreen = () => {
  // const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search);
  const filter = queryParams.get('filter') || null;
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(30)
  const [taskStatuses, setTaskStatuses] = useState([])
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [filterBy, setFilterBy] = useState(filter ? filter.toUpperCase() : "1")
  const [page, setPage] = useState(0)
  const [loadingSelect,   setLoadingSelect ] = useState(false)
  const [loading, setLoading] = useState("1")
  const [search, setSearch] = useState("")
  const [tasks, setTasks] = useState([])
  const [taskStatus, setTaskStatus] = useState(filter ? filter.toUpperCase() : "")
  const navigate = useNavigate();
  // const { filter } = useParams()

  useEffect(() => {
    getTasksFunc()
    if (user?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    getTaskStatusesFunc()

  }, [taskStatus, search, filterBy, limit, offset])

  const getTaskStatusesFunc = async () => {
    try {
      const res = await getTasksStatusesAction()
      setTaskStatuses(res.data.taskStatuses)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getTasksFunc = async (reset = false) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        driver: null,
        end_date: null,
        incidence: null,
        name: null,
        start_date: null,
        status: taskStatus || null,
        trailer: null,
        vehicle: search || null,
        task_id: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const res = await getTasksAction(data)
      if (res?.data?.myTasks.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let tasksArray = [...tasks].concat(res?.data?.myTasks)
      if ((!search && !taskStatus && !reset && (!filterBy || filterBy === "1")) || limit > 30) {
        setTasks(tasksArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        if(filterBy === "incidents"){
          setTasks(res?.data?.myTasks.filter(item => item.incidents > 0))
        } else {
          setTasks(res?.data?.myTasks)
        }
      }
      setLoading(false)
    } catch (e) {
      // console.log("getTasksFunc / error: ", e)
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") {
        setTasks([])
      }
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setTasks([])
      getTasksFunc()
    }
  };

  const onEditFile = async (file) => {

    try {
      const res = await importTasks(file)
      dispatch(openAlert({
        alertType: "success",
        alertMessage: i18n.t("TASKS.importSucces"),
        isAlertOpen: true
      }))
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const exportTasksFunc = async () => {
    setLoadingSelect(true)
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        driver: null,
        end_date: null,
        incidence: null,
        name: null,
        start_date: null,
        status: taskStatus || null,
        trailer: null,
        vehicle: search || null
      }
      const res = await exportTasks(data)
      setLoadingSelect(false)
      var win = window.open(res.data.exportMyTasks.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      setLoadingSelect(false)
    }
  }

  const exportTasksExcelFunc = async () => {
    setLoadingSelect(true)
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        driver: null,
        end_date: null,
        incidence: null,
        name: null,
        start_date: null,
        status: taskStatus || null,
        trailer: null,
        vehicle: search || null
      }
      const res = await exportTasksExcel(data)
      setLoadingSelect(false)
      var win = window.open(res.data.writeTaskInExcelFile.fullpath, '_blank');
      win.focus();
    } catch (e) {
      setLoadingSelect(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  // console.log("taskStatuses: ", taskStatuses)
  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('TASKS.forms.tasks.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={filterBy}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 4 }}
      defaultValue="1"
      onChange={({ target: { value } }) => {
        setFilterBy(value)
        if (value === "FINISHED" || value === "PROCESS" || value === "PENDING" || value === "DRAFT") {
          setTasks([])
          setTaskStatus(value)
          // getVehiclesFunc()
          setSearch("")
        }
        if (value === "incidents") {
          setTasks([])
          // setTaskStatus(value)
          // getVehiclesFunc()
          setSearch("")
        }
        if (value === "" || value === "1") {
          setTasks([])
          setTaskStatus("")
          setSearch("")
          // getVehiclesFunc()
        }

      }}
      icon={filterByIcon}
      items={taskStatuses?.length > 0
        ? [
          { value: '1', label: i18n.t('VEHICLES.forms.vehicles.buttons.filterBy'), icon: filterByIcon },
          ...taskStatuses?.map(status => ({ value: status.name.toUpperCase(), label: status.translate[0]?.langs[localStorage.getItem("language") || "es"], icon: filterByIcon })),
          { value: 'incidents', label: i18n.t('VEHICLES.forms.vehicles.buttons.incidents'), icon: filterByIcon },
        ]
        : [
          { value: '1', label: i18n.t('VEHICLES.forms.vehicles.buttons.filterBy'), icon: filterByIcon }
        ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('TASKS.searchPlaceHolder')}
      onChange={async (e) => {
        if (e.target.value === "") {
          getTasksFunc(true) 
        }

        setSearch(e.target.value)

      }}
      onKeyPress={handleKeyPress}
    // disabled={loading}
    />
    <UploaderButton 
      width="100"
      title={i18n.t('TASKS.buttons.import')}
      onEditFile={onEditFile}
    />
    <SelectButton
      items={ [{ name: 'XLSX', id: 'XLSX'}, { name: 'CSV', id: "CSV"} ] }
      exportCSV={() => exportTasksFunc()}
      exportExcel={() => exportTasksExcelFunc()}
      loading={ loadingSelect }
      active={false}
      icon=""
      width="100"
      title={i18n.t('TASKS.forms.tasks.buttons.export')}
    />
    <CategoryButton
      onClick={() => navigate(TASK_DETAILS_ROUTE)}
      active
      icon="" 
      width="100"
      title={i18n.t('TASKS.forms.tasks.buttons.add')}
    />
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="tasks"
            header={tableHeader}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(() =>{
                  return offset + increaseLimit
                })
                setLimit(() => {
                  return limit + increaseLimit
                })
              }

              setTimeout(() => {
                getTasksFunc()
              }, 500)
            }}
            updateTable={(reset) => getTasksFunc(reset)}
            titles={[
              { label: i18n.t('TASKS.forms.tasks.name') },
              { label: i18n.t('TASKS.forms.tasks.startDate') },
              { label: i18n.t('TASKS.forms.tasks.endDate') },
              { label: i18n.t('TASKS.forms.tasks.kmEstimated') },
              { label: i18n.t('TASKS.forms.tasks.vehicle') },
              { label: i18n.t('TASKS.forms.tasks.trailer') },
              { label: i18n.t('TASKS.forms.tasks.driver') },
              { label: i18n.t('TASKS.forms.tasks.issue') },
              { label: i18n.t('TASKS.forms.tasks.status') },
              { label: i18n.t('TASKS.forms.tasks.actions') },
            ]}
            data={tasks}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default TasksScreen
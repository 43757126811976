import chroma from "chroma-js";

const colorPrimary = '#F84812'

export const colourStyles = {
    control: (styles) => ({ ...styles, background: '#ffff', color: '#000', minWidth: '300px', width: '300px', lineHeight: '27.2px' }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma(colorPrimary);
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? colorPrimary : isFocused ? color.alpha(0.1).css() : undefined,
        color: isDisabled ? '#fff' : isSelected ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black' : colorPrimary,
        cursor: isDisabled ? 'not-allowed' : 'default',

        zIndex: 9999,
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? isSelected ? colorPrimary : color.alpha(0.3).css() : undefined,
        },
      };
    },
    singleValue: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: !isDisabled ? '#000' : colorPrimary,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    multiValue: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: '#fff',
        backgroundColor: !isDisabled ? colorPrimary : '#747474',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
    multiValueLabel: (styles, { isDisabled }) => ({
      ...styles,
      color: '#fff',
      paddingRight: !isDisabled ? '0px' : '7px'
    }),
    multiValueRemove: (styles, { isDisabled }) => ({
      ...styles,
      display: !isDisabled ? 'flex' : 'none',
      justifyContent: 'center',
      alignsItems: 'center',
      color: '#747474',
      ':hover': {
        backgroundColor: colorPrimary,
        color: 'white',
      },
    }),
  };

import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const ProfileContainer = styled.div`
width: 100%;
// height: 100%;
`;

export const ProfileContentContainer = styled.div`
width: 100%;
// height: 100%;
flex-direction: column;
max-width: 1000px;
margin: auto;
justify-content: center;
align-items: center;
display: flex;
padding-bottom: 100px;
`;

export const ProfileButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
margin-top: 20px;
margin-bottom: 20px;
`;

export const ProfileFormContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: column;

@media (max-width: 900px) {
  align-items: center;
justify-content: center;
}

`;

export const ProfileFormsContainer = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;

@media (max-width: 1200px) {
  align-items: center;
justify-content: center;
}
`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProfileFormInputsContainer = styled.div`
width: 450px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileSmallButtonsGroup = styled.div`
// width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileLastButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;

@media (min-width: 1200px) {
  justify-content: flex-end;
  padding-right: 16%;
}
@media (max-width: 1200px) {
align-items: center;
justify-content: center;
}
`;

export const ProfileSmallButtonGroup = styled.div`
// width: 45%;
display: flex;
flex-direction: row;
align-items: center;
`;

export const FixedAmountText = styled.p`
padding: 0;
padding-right: 10px;
`;

export const TableTh = styled.th`
text-align: center;
`;

export const TableTd = styled.td`
padding-top: 5px;
padding-bottom: 5px;
text-align: center;
`;

export const ProfileTable = styled.table`
box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.08);
border-radius: 5px;
padding: 10px;
`;

export const ProfileFormSeparator = styled.div`
width: 90%;
background: #302521;
opacity: 0.1;
height: 2px;
margin-top: 20px;
margin-bottom: 20px;
align-self: center;
`;

export const ProfileBillingContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;
@media (max-width: 400px) {
  padding: 0px;
}
`;

export const TableInfoContainer = styled.p`
margin-right: 20px;
display: flex;
flex-direction: column;
`;

export const TableTitle = styled.p`
font-weight: bold;
margin: 10px;
margin-top: 30px;
`;

export const TruckLogoWords = styled.img`
width: 100px;
height: 100px;
margin-top: 20px;
`;

export const LoginTitle = styled.h1`
color: ${COLORS_TRUCK.BLACK_TRUCK};
`;

export const SubTitle = styled.h3`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: auto;
margin-bottom: 20px;
`;

export const RadioButton = styled.div`
width: 30px;
height: 30px;
border-radius: 50%;
margin: 5px;
cursor: pointer;
`;

export const PaymentFormTextsContainer = styled.div`
display: flex;
flex-direction: column;
`;
export const PaymentFormCodeInputContainer = styled.div`
display: flex;
`;

export const PaymentFormSubTitle = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
margin: 0;
`;

export const DiscountSuccessMessage = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
// margin: 0;
`;

export const DiscountErrrorMessage = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
// margin: 0;
`;

export const PaymentFormTitle = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
font-weight: bold;
`;

export const PaymentDataTableContainer = styled.div`
display: flex;
flex-direction: column;
border-radius: 10px;
box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.08);
`;

export const PaymentDataTableItemsContainer = styled.div`
display: flex;
flex-direction: row;
`;

export const PaymentDataTableItemContainer = styled.div`
display: flex;
flex-direction: column;
margin: 10px;
min-width: 70px;
`;

export const EnterCodeButtonsContainer = styled.div`
max-width: 500px;
margin: auto;
height: 100%;
background: ${COLORS_TRUCK.WHITE_TRUCK};
margin-left: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const EnterCodeTitle = styled.h2`
color: ${COLORS_TRUCK.BLACK_TRUCK};
text-align: center;
`;

export const EnterCodeSubTitle = styled.h4`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
text-align: center;
`;

export const EnterCodeSubTitleTwo = styled.h4`
color: ${COLORS_TRUCK.BLACK_TRUCK};
text-align: center;
`;

export const EnterCodeDescription = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
text-align: center;
`;

export const PaddingTextContainer = styled.div`
padding-left: 100px;
padding-right: 100px;
display: flex;
flex-direction: column;
@media (max-width: 420px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const MessageIcon = styled.img`
width: 100px;
height: 100px;
margin: auto;
`;

export const EnterLinkDot = styled.img`
width: 10px;
height: 10px;
margin-right: 10px;
`;

export const EnterCodeLink = styled.p`
cursor: pointer;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 5px;
`;


export const EnterLinkContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`;

export const GeneralErrorText = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 5px;
`;

export const GeneralErrorContainer = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
`;

export const OpacityContainer = styled.div`
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.7);
`;

export const PlanIcon = styled.img`
width: 100px;
height: 100px;
`;

export const PlanUserIcon = styled.img`
width: 20px;
height: 20px;
`;

export const PlansButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
`;

export const PlanItemDescriptionContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

export const PlansContentContainer = styled.div`
// max-width: 800px;
height: 100%;
display: flex;
// padding-top: 50px;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
// padding-left: 100px;

// @media (max-width: 765px) {
//     padding: 10px;
//     height: 60%;
//   }
`;

export const PlanTitle = styled.h1`
color: ${COLORS_TRUCK.BLACK_TRUCK};
font-weight: bold;
// font-size: 50px;
margin-bottom: 0;
`;

export const HomeDescription = styled.p`
text-align: center;
color: ${COLORS_TRUCK.BLACK_TRUCK};
@media (max-width: 500px) {
  text-align: center;
  }
`;

export const ButtonText = styled.p`
font-weight: bold;
color: ${COLORS_TRUCK.WHITE_TRUCK};
padding: 0;
margin: 0;
`;

export const Button = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  background: ${COLORS_TRUCK.ORANGE_TRUCK};
  padding: 12px;
  min-width: 150px;
  margin: 10px;
`;

export const PlanItemContainer = styled.div`
  width: 250px;
  min-height: 400px;
  background: ${COLORS_TRUCK.WHITE_TRUCK};
  border-radius: 10px; 
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

export const PlansItemsContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
`;

// export const PaddingTextContainer = styled.div`
// // padding-left: 100px;
// // padding-right: 100px;
// @media (max-width: 420px) {
//     padding-left: 10px;
//     padding-right: 10px;
//   }
// `;

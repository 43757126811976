import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const UserCardContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
`;

export const UserCardImageContainer = styled.div`
position: relative;
`;

export const UserCardImage= styled.img`
width: 50px;
height: 50px;
border-radius: 40px;
`;

export const UserCardImageAddIcon= styled.img`
width: 20px;
height: 20px;
border-radius: 10px;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
position: absolute;
right: 0;
bottom: 0;
padding: 2px;
cursor: pointer;
`;

export const UserCardContentContainer= styled.div`
display: flex;
flex-direction: column;
padding: 10px;
`;

export const UserCardTitle= styled.p`
font-weight: bold;
margin: 0;
`;

export const UserCardPosition= styled.p`
margin: 0;
margin-top: 10px;
`;
import React from "react";
import {
  TextInputContainer,
  InputIcon,
  CustomTextInput,
  InputTitle,
  FormTextInputMainContainer,
  CopyIcon
} from "./form-text-area-styles";
import copyIcon from "../../../../assets/tasks/copy-icon-red.svg"
import { COLORS_TRUCK } from "../../../../utils/colors";

const FormTextArea = (props) => {

  return (
    <FormTextInputMainContainer style={props.mainContainerStyle}>
      {props.title ? <InputTitle>
        {props.title}{props.required ? <span style={{ fontSize: 20 }}>{` *`}</span> : ""}
      </InputTitle>
        : null}
      {props.leftComponent ?
        props.leftComponent
        : null}
      <TextInputContainer style={Object.assign({ border: `1px solid ${props.showError ? COLORS_TRUCK.ORANGE_TRUCK : "rgba(0, 0, 0, 0.23)"}` }, props.containerStyle)}>
        {props.icon && <InputIcon src={props.icon} />}
        <CustomTextInput
          {...props}
          autoComplete="off"
          onFocus={(event) => {
            event.target.setAttribute('autocomplete', 'off');
          }}
        />
        {props.rightIcon && <InputIcon
          onClick={props.iconRightClick}
          style={{ cursor: 'pointer' }}
          src={props.rightIcon}
        />}
        {props.rightComponent ? props.rightComponent : null}
        {props.canCopy
          ? <CopyIcon src={copyIcon} />
          : null}
      </TextInputContainer>
    </FormTextInputMainContainer>
  )
}

export default FormTextArea
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormRadioButtonsInput from "../../components/inputs/forms/form-radio-buttons-input/FormRadioButtonsInput";
import Loader from "../../components/loader/Loader";
import i18n from "../../i18n/i18n";
import { JOURNEYS_ROUTE } from "../../routes/routes";
import { COLORS_TRUCK } from "../../utils/colors";
import {
  VehicleDetailsContainer,
  VehicleDetailsContentContainer,
  VehicleDetailsFormsContainer,
  VehicleDetailsFormInputsContainer,
  TableInfoContainer,
  TableTitle,
  ProfileLastButtonsContainer,
} from "./checklist-details-styles";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { getJourneysAction } from "../journeys/journeys-actions";

const ChecklistJourneyScreen = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [checklist, setChecklist] = useState("")
  const [luminary, setLuminary] = useState("")
  const [windshield, setWindshield] = useState("")
  const [rearViewMirrors, setRearViewMirrors] = useState("")
  const [windshieldWasherFluid, setWindshieldWasherFluid] = useState("")
  const [tirePressure, setTirePressure] = useState("")
  const [batteries, setBatteries] = useState("")
  const [fireExtinguishers, setFireExtinguishers] = useState("")
  const [securityShoes, setSecurityShoes] = useState("")
  const [helmet, setHelmet] = useState("")
  const [gloves, setGloves] = useState("")
  const [protetiveScreen, setProtetiveScreen] = useState("")
  const [masks, setMasks] = useState("")
  const [highVisibilityGarment, setHighVisibilityGarment] = useState("")
  const [others, setOthers] = useState("")
  const [checklistStatus, setChecklistStatus] = useState("")
  const { journeyId } = useParams()

  useEffect(() => {
    if (journeyId) {
      getJourney()
    }

  }, [journeyId])

  const getJourney = async () => {
    try {
      // setLoading(true)
      const data = {
        _id: journeyId,
        pagination: {
          limit: 30,
          offset: 0
        },
        creation_datetime: null,
        finish_datetime: null,
        sort: {
          field: "createdAt",
          type: "DESC"
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const journeys = await getJourneysAction("", data)
      const journeysFiltered = journeys?.data?.journeys.filter(item => item._id === journeyId)
      if (journeysFiltered.length > 0) {
        const journey = journeysFiltered[0]
        setChecklist(journey.checklist)
        setChecklistStatus(journey.checklist.status)
        setLuminary(journey.checklist?.vehicle.luminary ? "1" : "2")
        setWindshield(journey.checklist?.vehicle.windshield ? "1" : "2")
        setTirePressure(journey.checklist?.vehicle.tire_pressure ? "1" : "2")
        setBatteries(journey.checklist?.vehicle.batteries ? "1" : "2")
        setFireExtinguishers(journey.checklist?.vehicle.fire_extinguishers ? "1" : "2")
        setHelmet(journey.checklist?.epis.helmet ? "1" : "2")
        setSecurityShoes(journey.checklist?.epis.safety_footwear ? "1" : "2")
        setGloves(journey.checklist?.epis.gloves ? "1" : "2")
        setProtetiveScreen(journey.checklist?.epis.protective_screen ? "1" : "2")
        setWindshieldWasherFluid(journey.checklist?.vehicle.windshield_washer ? "1" : "2")
        setMasks(journey.checklist?.epis.masks ? "1" : "2")
        setHighVisibilityGarment(journey.checklist?.epis.high_visibility_garment ? "1" : "2")
        setOthers(journey.checklist?.epis.others ? "1" : "2")
        setRearViewMirrors(journey.checklist?.vehicle.mirrors ? "1" : "2")
      }

      // setLoading(false)

      // setDrivers(drivers?.data?.myDrivers)
    } catch (e) {
      // setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      // navigate(ISSUES_ROUTE)
    }
  }

  return (
    <VehicleDetailsContainer>
      <VehicleDetailsContentContainer>
        <TableInfoContainer>
          <TableTitle>
            {i18n.t('ISSUES.forms.checklistDetails.title')}
          </TableTitle>
        </TableInfoContainer>
        <TableInfoContainer>
          <TableTitle>
            {`${i18n.t('JOURNEYS.checklistStatus')}: ${checklistStatus ? i18n.t(`JOURNEYS.${checklistStatus.toLowerCase()}`) : "..."}`}
          </TableTitle>
        </TableInfoContainer>
        {!checklist
          ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Loader color={COLORS_TRUCK.ORANGE_TRUCK} />
          </div>
          : <VehicleDetailsFormsContainer>
            <VehicleDetailsFormInputsContainer>
              <TableInfoContainer>
                <TableTitle>
                  {i18n.t('ISSUES.forms.checklistDetails.vehicle')}
                </TableTitle>
              </TableInfoContainer>
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.luminary')}
                defaultValue="1"
                value={luminary}
                onChange={({ target: { value } }) => setLuminary(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.windshield')}
                defaultValue="1"
                value={windshield}
                onChange={({ target: { value } }) => setWindshield(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.rearViewMirrors')}
                value={rearViewMirrors}
                defaultValue="1"
                onChange={({ target: { value } }) => setRearViewMirrors(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.windshieldWasherFluid')}
                defaultValue="1"
                onChange={({ target: { value } }) => setWindshieldWasherFluid(value)}
                value={windshieldWasherFluid}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.tirePressure')}
                defaultValue="1"
                onChange={({ target: { value } }) => setTirePressure(value)}
                value={tirePressure}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.batteries')}
                defaultValue="1"
                value={batteries}
                onChange={({ target: { value } }) => setBatteries(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.fireExtinguishers')}
                defaultValue="1"
                value={fireExtinguishers}
                onChange={({ target: { value } }) => setFireExtinguishers(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
            </VehicleDetailsFormInputsContainer>
            <VehicleDetailsFormInputsContainer>
              <TableInfoContainer>
                <TableTitle>
                  {i18n.t('ISSUES.forms.checklistDetails.epis')}
                </TableTitle>
              </TableInfoContainer>
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.securityShoes')}
                defaultValue="1"
                value={securityShoes}
                onChange={({ target: { value } }) => setSecurityShoes(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.helmet')}
                defaultValue="1"
                value={helmet}
                onChange={({ target: { value } }) => setHelmet(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.gloves')}
                defaultValue="1"
                value={gloves}
                onChange={({ target: { value } }) => setGloves(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.protetiveScreen')}
                defaultValue="1"
                value={protetiveScreen}
                onChange={({ target: { value } }) => setProtetiveScreen(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.masks')}
                defaultValue="1"
                value={masks}
                onChange={({ target: { value } }) => setMasks(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.highVisibilityGarment')}
                defaultValue="1"
                value={highVisibilityGarment}
                onChange={({ target: { value } }) => setHighVisibilityGarment(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.others')}
                defaultValue="1"
                value={others}
                onChange={({ target: { value } }) => setOthers(value)}
                items={[
                  { value: '1', label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: '2', label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={true}
              />
              <ProfileLastButtonsContainer>
                <CategoryButton
                  onClick={() => navigate(`${JOURNEYS_ROUTE}`)}
                  active
                  icon=""
                  title={i18n.t('ISSUES.forms.checklistDetails.buttons.back')}
                  width={120}
                />
              </ProfileLastButtonsContainer>
            </VehicleDetailsFormInputsContainer>
          </VehicleDetailsFormsContainer>}
      </VehicleDetailsContentContainer>
    </VehicleDetailsContainer>
  )
}

export default ChecklistJourneyScreen
import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../utils/colors';

export const FormMainContainer = styled.div`
width: 150px;
border: 1px solid ${COLORS_TRUCK.BLACK_TRUCK};
background-color: ${COLORS_TRUCK.BLACK_TRUCK};
color: ${COLORS_TRUCK.WHITE_TRUCK};
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
padding: 10px 4px;
font-weight: 600;
cursor: pointer;
`;

export const FormLoadContainer = styled.div`
display: flex;
flex-direction: column;
position: absolute;
top: 250px;
z-index: 5;
width: 400px;
background-color: ${COLORS_TRUCK.WHITE_TRUCK};
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 3px;
`;
export const FormLoadHeader = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 9px 15px;
background-color: ${COLORS_TRUCK.ORANGE_TRUCK};
color: ${COLORS_TRUCK.WHITE_TRUCK};
font-weight: 600;
`

export const FormLoadContent = styled.div`
padding: 10px 25px;
`;

export const FormLoadText = styled.p`
display: flex;
font-size: 11px;
font-weight: 500;
`;

export const ButtonProcessImport = styled.button`
background-color: ${COLORS_TRUCK.BLACK_TRUCK};
border: 1px solid ${COLORS_TRUCK.BLACK_TRUCK};
color: ${COLORS_TRUCK.WHITE_TRUCK};
display: flex;
justify-content: center;
border-radius: 3px;
width: 96%;
margin-top: 10px;
padding: 10px 0px;
font-size: 17px;
font-weight: 600;
cursor: pointer;
`;

export const DownloadContainer = styled.div`
display: flex;
padding: 10px 0x;
justify-content: center;
background-color: ${COLORS_TRUCK.WHITE_TRUCK};
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 3px;
width: 100%;
`

export const ButtonDownloadTemplate = styled.a`
cursor: pointer;
font-size: 17px;
display: flex;
justify-content: center;
align-items: center;
width: 400px;
height: 40px;
text-align: center;
font-weight: 700;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
`;
export const Img = styled.img`
width: 30px;
height: 30px;
z-index: 1;
padding-right: 5px;
`;

export const FormTitle = styled.p`
font-size: 11px;
margin-bottom: 0;
margin-left: 5px;
`;

export const FormFileInputContainer = styled.div`
width: 100%;
height: 100px;
border: 1px solid rgba(0,0,0,0.23);
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
`;
export const FormFileContainer = styled.div`
display: flex;
flex-direction: column;
`;

export const AddFileIcon = styled.img`
width: 30px;
height: 30px;
z-index: 1;
`;

export const BackgroundPicture = styled.img`
width: 100%;
height: 100%;
position: absolute;
z-index: 0;
`;

export const ContainerCenter = styled.div`
display: flex;
justify-content: center;
`;
import React from "react";
import { BackArrowIcon, BackText, BackButtonContainer } from "./back-button-styles";
import SafeLINK from "../../utils/safe-link";
import backIcon from "../../assets/general/back-arrow-black.svg";
import i18n from '../../i18n/i18n';

const BackButton = ({ route, onClick }) => {

  return (
    route
      ? <SafeLINK
        dangerousLINK={route}
        text={
          <BackButtonContainer>
            <BackArrowIcon src={backIcon} />
            <BackText>
              {i18n.t('GENERAL.back')}
            </BackText>
          </BackButtonContainer>
        }
      />
      : <BackButtonContainer onClick={onClick}>
        <BackArrowIcon src={backIcon} />
        <BackText>
          {i18n.t('GENERAL.back')}
        </BackText>
      </BackButtonContainer >
  )
}

export default BackButton
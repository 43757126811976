import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../../utils/colors';

export const FormTextInputMainContainer = styled.div`

`;

export const TextInputContainer = styled.div`
width: 300px;
max-width: 300px;
height: 60px;
box-sizing: border-box;
border-radius: 5px;
display: flex;
margin: 10px;
margin-left: 0px;
justify-content: center;
align-items: center;

@media (max-width: 420px) {
width: 250px;
  }
`;

export const InputIcon = styled.img`
width: 20px;
height: 20px;
margin: 20px;
`;

export const CustomTextInput = styled.input`
background-color: transparent;
width: 100%;
height: 100%;
border: none;
padding: 20px;
font-size: 15px;
color: ${COLORS_TRUCK.BLACK_TRUCK}
`;

export const InputTitle = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
font-size: 13px;
`;
import React, { useState } from "react";
import { EnterNewPasswordContainer, EnterNewPasswordButtonsContainer, EnterNewPasswordTitle, EnterNewPasswordDescription, MessageIcon, PaddingTextContainer, CircleIcon, PasswordPointsContainer, EnterNewPasswordDescriptionPoint, BackButtonContainer } from "./enter-new-password-styles";
import TextInput from "../../components/inputs/text-input/TextInput";
import passwordIcon from "../../assets/inputs/lock.svg";
import circleIcon from "../../assets/general/circle-icon.svg";
import offEye from "../../assets/inputs/eye-off.svg";
import redEye from "../../assets/general/eye-icon-red.svg";
import { Button, ButtonText } from "../home/home-styles";
// import { TextAsButton } from "../header/header-styles";
import i18n from '../../i18n/i18n';
// import BackButton from "../../components/back-button/BackButton";
import messageIcon from "../../assets/restore-password/message-icon.svg";
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE, RESTORE_PASSWORD_ROUTE } from "../../routes/routes";
import { changePasswordAction } from "../restore-password/forgot-password-actions";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import BackButton from "../../components/back-button/BackButton";

const EnterNewPasswordScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [firstPasswordShow, setFirstPassShow] = useState(false);
  const [secondPasswordShow, setSecondPassShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("")
  const [secondPassword, setSecondPassword] = useState("")

  const changePassword = async () => {
    try {
      if (password !== secondPassword) {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true,
          alertMessage: i18n.t("GENERAL.passwordsNotMatch")
        }))
        return
      }
      if (password.length < 8 || secondPassword.length < 8) {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true,
          alertMessage: i18n.t("GENERAL.passwordLength")
        }))
        return
      }

      setLoading(true)
      const code = localStorage.getItem("confirm-email-code")

      await changePasswordAction(password, code)
      navigate(LOGIN_ROUTE)
      localStorage.removeItem("confirm-email-code")
      dispatch(openAlert({
        alertType: "success",
        isAlertOpen: true,
        alertMessage: i18n.t("ENTER_NEW_PASSWORD.successMessage")
      }))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")

      if (errorCode === "999") {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true,
          alertMessage: i18n.t("ENTER_NEW_PASSWORD.codeExpired")
        }))
        navigate(RESTORE_PASSWORD_ROUTE)
      } else {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true
        }))
      }
    }
  }

  return (
    <EnterNewPasswordContainer>
      <EnterNewPasswordButtonsContainer>
        <BackButtonContainer>
          <BackButton route={RESTORE_PASSWORD_ROUTE} />
        </BackButtonContainer>
        {/* <BackButton style={{ alignSelf: "flex-start", marginLeft: 100 }} route={LOGIN_ROUTE} /> */}
        <MessageIcon src={messageIcon} />
        <PaddingTextContainer>
          <EnterNewPasswordTitle>
            {i18n.t('ENTER_NEW_PASSWORD.title')}
          </EnterNewPasswordTitle>
          <EnterNewPasswordDescription>
            {i18n.t('ENTER_NEW_PASSWORD.description')}
          </EnterNewPasswordDescription>
        </PaddingTextContainer>
        <TextInput
          iconRightClick={() => setFirstPassShow(!firstPasswordShow)}
          icon={passwordIcon}
          rightIcon={firstPasswordShow ? offEye : redEye}
          placeholder={i18n.t('ENTER_NEW_PASSWORD.new-password')}
          type={firstPasswordShow ? "text" : "password"}
          onChange={({ target: { value } }) => setPassword(value)}
          disabled={loading}
          value={password}
        />
        <TextInput
          iconRightClick={() => setSecondPassShow(!secondPasswordShow)}
          icon={passwordIcon}
          rightIcon={secondPasswordShow ? offEye : redEye} placeholder={i18n.t('ENTER_NEW_PASSWORD.new-password-again')}
          type={secondPasswordShow ? "text" : "password"}
          onChange={({ target: { value } }) => setSecondPassword(value)}
          disabled={loading}
          value={secondPassword}
        />
        <PaddingTextContainer style={{ marginTop: 30, marginBottom: 20 }}>
          <PasswordPointsContainer>
            <CircleIcon src={circleIcon} />
            <EnterNewPasswordDescriptionPoint>
              {i18n.t('ENTER_NEW_PASSWORD.password-description-1')}
            </EnterNewPasswordDescriptionPoint>
          </PasswordPointsContainer>
          <PasswordPointsContainer>
            <CircleIcon src={circleIcon} />
            <EnterNewPasswordDescriptionPoint>
              {i18n.t('ENTER_NEW_PASSWORD.password-description-2')}
            </EnterNewPasswordDescriptionPoint>
          </PasswordPointsContainer>
          <PasswordPointsContainer>
            <CircleIcon src={circleIcon} />
            <EnterNewPasswordDescriptionPoint>
              {i18n.t('ENTER_NEW_PASSWORD.password-description-3')}
            </EnterNewPasswordDescriptionPoint>
          </PasswordPointsContainer>
          <PasswordPointsContainer>
            <CircleIcon src={circleIcon} />
            <EnterNewPasswordDescriptionPoint>
              {i18n.t('ENTER_NEW_PASSWORD.password-description-4')}
            </EnterNewPasswordDescriptionPoint>
          </PasswordPointsContainer>
        </PaddingTextContainer>
        <Button disabled={loading} onClick={() => changePassword()} style={{ width: 300, marginTop: 20, marginBottom: 20 }}>
          <ButtonText>
            {i18n.t('ENTER_NEW_PASSWORD.restore-password-button')}
          </ButtonText>
        </Button>
      </EnterNewPasswordButtonsContainer>
    </EnterNewPasswordContainer>
  )
}

export default EnterNewPasswordScreen
import { gql } from '@apollo/client';

export const GET_COMPANIES_BY_PLANS_MUTATION = gql`
query companiesByPlans {
  companiesByPlans {
    _id
    companys {
      _id
      company
      latitude
      longitude
    }
  }
}
`;

export const GET_USERS_BY_PLANS_MUTATION = gql`
query usersByPlans($group_country: Boolean!) {
  usersByPlans(group_country: $group_country) {
    _id
    plans {
      _id
      total
    }
  }
}
`;

export const PLANS_GROUPTED_SUBSCRIPTION = gql`
subscription plansGrouped {
  plansGrouped {
        _id
        total
        plans {
          _id
          total
        }
  }
}
`;

export const PLANS_GROUPED_BY_COUNTRY_SUBSCRIPTION = gql`
subscription plansGroupedByCountry {
  plansGroupedByCountry {
        _id
        total
        plans {
          _id
          total
        }
  }
}
`;

import { gql } from '@apollo/client';

export const CREATE_VEHICLE_MUTATION = gql`
mutation createVehicle($input: CreateVehicleInput!) {
  createVehicle(input: $input){
   _id
   brand
   itv_date
   model
   plate
   vehicle_type
   year
  }
}
`;

export const REMOVE_VEHICLE_MUTATION = gql`
mutation deleteVehicle($vehicle_id: ObjectId!) {
  deleteVehicle(vehicle_id: $vehicle_id){
        _id
  }
}
`;

export const UPDATE_VEHICLE_MUTATION = gql`
mutation updateVehicle($input: UpdateVehicleInput!, $vehicle_id: ObjectId!) {
  updateVehicle(input: $input, vehicle_id: $vehicle_id){
   _id
   brand
   itv_date
   model
   plate
   vehicle_type
   year
  }
}
`

export const GET_VEHICLES_MUTATION = gql`
query myVehicles($input: VehicleFiltersInput!) {
  myVehicles(input: $input){
    _id
   brand
   itv_date
   model
   plate
   vehicle_type
   year
  }
}
`;

export const EXPORT_VEHICLES_MUTATION = gql`
query exportMyVehicles($input: VehicleFiltersInput!) {
  exportMyVehicles(input: $input){
        fullpath
        path
        status
  }
}
`;


import React, { useEffect, useState } from "react";
import {
  UserHomeContainer,
  UserHomeContentContainer,
  InfoItemsContainer,
  ProfileTableContainer,
  SuperAdminHomeMapStatsContainer,
  StatContainer,
  Insignia,
  StatTitle,
  StatAmount,
  StatSeparator,
  TaskDetailsContainer,
  // TaskDetailsTitle,
  // TaskDetailsContent,
  TaskDetailsContentLink,
  TaskDetailsItemContainer,
  TaskDetailsItemsContainer
} from "./super-admin-home-styles";
import i18n from '../../../i18n/i18n';
import goldCircle from "../../../assets/super-admin-home/gold-circle.svg";
import silverCircle from "../../../assets/super-admin-home/silver-circle.svg";
import bronzeCircle from "../../../assets/super-admin-home/bronze-circle.svg";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup
} from 'react-leaflet';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import TruckTable from "../../../components/truck-table/TruckTable";
import goldInsignia from "../../../assets/super-admin-home/gold-insignia.svg";
import silverInsignia from "../../../assets/super-admin-home/silver-insignia.svg";
import bronzeInsignia from "../../../assets/super-admin-home/bronze-insignia.svg";
import { getCompaniesByPlansAction, getUsersByPlansAction } from "./super-admin-home-actions";
import { getCountriesAction, getPlans } from "../../signup/signup-actions";
import { useAppDispatch } from "../../../redux/store.ts";
import { openAlert } from "../../../redux/reducers/app.ts";
import { PLANS_GROUPED_BY_COUNTRY_SUBSCRIPTION, PLANS_GROUPTED_SUBSCRIPTION } from "./super-admin-home-queries";
import { useSubscription } from "@apollo/client";
import config from "../../../config";

const SuperAdminHomeScreen = () => {

  const dispatch = useAppDispatch()
  const [companiesByPlans, setCompaniesByPlans] = useState([])
  const [plans, setPlans] = useState([])
  const [usersByCountry, setUsersByCountry] = useState([])
  const [countries, setCountries] = useState([])

  useEffect(async () => {
    await getPlansFunc()
    await getData()
    await getCountries()
  }, [])

  useSubscription(PLANS_GROUPED_BY_COUNTRY_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(PLANS_GROUPTED_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const handleCountries = (countries) => {
    // console.log("countries: ", countries)
    // console.log("countries: ", usersByCountry)
    return countries.length > 0 ? countries.map(country => {
      return {
        label: country.translate[0].langs[localStorage.getItem("language") || "es"],
        iso2: country.iso2,
        gold: usersByCountry.filter(user => String(user._id) === String(country._id) && String(user.plans[0]._id) === String(config.GOLD_PLAN_ID)).length,
        silver: usersByCountry.filter(user => String(user._id) === String(country._id) && String(user.plans[0]._id) === String(config.SILVER_PLAN_ID)).length,
        bronze: usersByCountry.filter(user => String(user._id) === String(country._id) && String(user.plans[0]._id) === String(config.BRONZE_PLAN_ID)).length,
        total: usersByCountry.filter(user => String(user._id) === String(country._id)).length,
      }
    })
      .filter(item => Number(item.gold) > 0 || Number(item.silver) > 0 || Number(item.bronze) > 0)
      : []
  }

  const getCountries = async () => {
    try {
      const response = await getCountriesAction()
      // if (response && response.data) {
      setCountries(
        response
          .data
          .countries
      )

      // return countriesArray
      // }

    } catch (e) {
      // dispatch(openAlert({
      //   isAlertOpen: true
      // }))
    }
  }

  const getData = async () => {
    try {
      const res = await getCompaniesByPlansAction()
      if (res?.data?.companiesByPlans) {
        setCompaniesByPlans(res?.data?.companiesByPlans)
      }

      const res2 = await getUsersByPlansAction(true)
      if (res2?.data?.usersByPlans) {
        setUsersByCountry(res2?.data?.usersByPlans)
      }
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getPlansFunc = async () => {
    try {
      const response = await getPlans()
      if (response && response.data && response.data.plans) {
        setPlans(response.data.plans)
      }
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  // const markers = [
  //   {
  //     position: [40.4115, -2.7076],
  //     icon: divIcon({
  //       html: renderToStaticMarkup(<img alt="" src={goldCircle} />),
  //     })
  //   },
  //   {
  //     position: [41.4115, -2.7076],
  //     icon: divIcon({
  //       html: renderToStaticMarkup(<img alt="" src={silverCircle} />),
  //     })
  //   },
  //   {
  //     position: [43.4115, -2.7076],
  //     icon: divIcon({
  //       html: renderToStaticMarkup(<img alt="" src={bronzeCircle} />),
  //     })
  //   },
  // ]
  const position = [40.4115, -2.7076];

  const fixCompanies = () => {
    let bag = []

    companiesByPlans.forEach((item) => {
      if (item.companys.length > 0) {
        item.companys.forEach(company => {
          bag.push({
            ...company,
            plan: company._id,
            icon: divIcon({
              html: renderToStaticMarkup(<img
                alt=""
                src={String(item._id) === config.BRONZE_PLAN_ID
                  ? bronzeCircle
                  : String(item._id) === config.SILVER_PLAN_ID
                    ? silverCircle
                    : String(item._id) === config.GOLD_PLAN_ID
                      ? goldCircle
                      : bronzeCircle}
              />),
            })
          })
        })
      }
    })

    return bag
  }

  const bronzeCompanies = companiesByPlans?.filter(item => String(item._id) === config.BRONZE_PLAN_ID)[0]?.companys?.length
  const silverCompanies = companiesByPlans?.filter(item => String(item._id) === config.SILVER_PLAN_ID)[0]?.companys?.length
  const goldCompanies = companiesByPlans?.filter(item => String(item._id) === config.GOLD_PLAN_ID)[0]?.companys?.length

  return (
    <UserHomeContainer>
      <UserHomeContentContainer>
        <MapContainer
          style={{ width: '100%', height: '500px', marginTop: 20, borderRadius: 15 }}
          center={position}
          zoom={6}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {fixCompanies().map((item, i) =>
            <Marker
              key={i}
              icon={item.icon}
              color="red"
              position={[Number(item.latitude), Number(item.longitude)]
              }>
              <Popup>
                <TaskDetailsContainer>
                  <TaskDetailsItemsContainer>
                    <TaskDetailsItemContainer>
                      <TaskDetailsContentLink
                      // onClick={() => navigate(`${TASK_DETAILS_ROUTE}/${item._id}`)}
                      >
                        {item.company}
                      </TaskDetailsContentLink>
                    </TaskDetailsItemContainer>
                    {/* <TaskDetailsItemContainer>
                      <TaskDetailsTitle>
                        {i18n.t('HOME.taskdetails.driver')}:
                      </TaskDetailsTitle>
                      <TaskDetailsContent>
                        {item.details.driver}
                      </TaskDetailsContent>
                    </TaskDetailsItemContainer> */}
                  </TaskDetailsItemsContainer>
                </TaskDetailsContainer>
              </Popup>
            </Marker>
          )}
        </MapContainer>

        <InfoItemsContainer>
          <SuperAdminHomeMapStatsContainer>
            <StatContainer>
              <Insignia src={goldInsignia} />
              <StatTitle>
                {i18n.t('SUPER_ADMIN_HOME.gold')}
              </StatTitle>
              <StatAmount>
                {goldCompanies}
              </StatAmount>
            </StatContainer>
            <StatContainer>
              <Insignia src={silverInsignia} />
              <StatTitle>
                {i18n.t('SUPER_ADMIN_HOME.silver')}
              </StatTitle>
              <StatAmount>
                {silverCompanies}
              </StatAmount>
            </StatContainer>
            <StatContainer>
              <Insignia src={bronzeInsignia} />
              <StatTitle>
                {i18n.t('SUPER_ADMIN_HOME.bronze')}
              </StatTitle>
              <StatAmount>
                {bronzeCompanies}
              </StatAmount>
            </StatContainer>
            <StatSeparator />
            <StatContainer>
              <StatTitle>
                {i18n.t('SUPER_ADMIN_HOME.total')}
              </StatTitle>
              <StatAmount>
                {Number(goldCompanies || 0) + Number(silverCompanies || 0) + Number(bronzeCompanies || 0)}
              </StatAmount>
            </StatContainer>
          </SuperAdminHomeMapStatsContainer>
          <ProfileTableContainer
            style={{
              maxHeight: 300,
              maxWidth: 500,
              overflow: "auto",
              marginTop: 10
            }}
          >
            <TruckTable
              type="superAdminHomeTable"
              titles={[
                { label: i18n.t('SUPER_ADMIN_HOME.table.flag') },
                { label: i18n.t('SUPER_ADMIN_HOME.table.country') },
                { label: i18n.t('SUPER_ADMIN_HOME.table.gold') },
                { label: i18n.t('SUPER_ADMIN_HOME.table.silver') },
                { label: i18n.t('SUPER_ADMIN_HOME.table.bronze') },
                { label: i18n.t('SUPER_ADMIN_HOME.table.total') },
              ]}
              data={handleCountries(countries)}
            />
          </ProfileTableContainer>
        </InfoItemsContainer>
      </UserHomeContentContainer>
    </UserHomeContainer>
  )
}

export default SuperAdminHomeScreen
// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import { 
  GET_RUNNING_TASKS_MUTATION, 
  GET_PENDING_TASKS_MUTATION, 
  GET_OPEN_INCIDENTS_MUTATION, 
  GET_UNREAD_MESSAGES_MUTATION,
  GET_MAP_COORDS_MUTATION
 } from './user-home-queries';
// import {handleAlert} from '../../general/functions';



/**
 * getRunningTasksAction - action for get in process tasks
 *
 * @returns {any}
 */
 export const getRunningTasksAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_RUNNING_TASKS_MUTATION,
        variables: {},
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getPendingTasksAction - action for get pending tasks
 *
 * @returns {any}
 */
 export const getPendingTasksAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_PENDING_TASKS_MUTATION,
        variables: {},
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * getOpenIncidentsAction - action for get open incidents
 *
 * @returns {any}
 */
 export const getOpenIncidentsAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_OPEN_INCIDENTS_MUTATION,
        variables: {},
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * getUnreadMessagesAction - action for get unread messages
 *
 * @returns {any}
 */
 export const getUnreadMessagesAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_UNREAD_MESSAGES_MUTATION,
        variables: {},
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * getMapCoordsAction - action for get unread messages
 *
 * @returns {any}
 */
 export const getMapCoordsAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_MAP_COORDS_MUTATION,
        variables: {},
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // console.log("getMapCoordsAction / response: ", response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getMapCoordsAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

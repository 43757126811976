import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../../utils/colors';

export const FormTextInputMainContainer = styled.div`
position: relative;
`;

export const TextInputContainer = styled.div`
width: 300px;
max-width: 300px;
height: 150px;
box-sizing: border-box;
border-radius: 5px;
display: flex;
margin: 10px;
margin-left: 0px;
justify-content: center;
align-items: center;
padding-top: 10px;

@media (max-width: 320px) {
width: 250px;
  }
`;

export const InputIcon = styled.img`
width: 20px;
height: 20px;
margin: 20px;
`;

export const CopyIcon = styled.img`
width: 25px;
height: 25px;
position: absolute;
right: 20px;
cursor: pointer;
`;

export const CustomTextInput = styled.textarea`
background-color: transparent;
width: 100%;
resize: none;
height: 100%;
border: none;
padding: 20px;
font-size: 15px;
color: ${COLORS_TRUCK.BLACK_TRUCK};
margin: 0;
padding: 0;
padding-left: 15px;
`;

export const InputTitle = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
font-size: 13px;
`;
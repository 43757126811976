import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../utils/colors';

export const CategoryButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100px;
border-radius: 5px;
cursor: pointer;
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 10px;
`;

export const CategoryButtonIcon = styled.img`
width: 20px;
height: 20px;
`;

export const CategoryButtonContent = styled.p`
font-weight: bold;
`;
import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';
import truckImage from "../../assets/login/orange-truck.png"

export const RestorePasswordContainer = styled.div`
width: 100%;
height: 90vh;
background-image: url(${truckImage});
background-repeat: no-repeat;
background-size: cover;
`;

export const RestorePasswordButtonsContainer = styled.div`
max-width: 500px;
height: 100%;
background: ${COLORS_TRUCK.WHITE_TRUCK};
margin-left: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const BackButtonContainer = styled.div`
margin-left: 100px;
align-self: flex-start;
@media (max-width: 420px) {
    margin-left: 10px;
  }
`;

export const TruckLogoWords = styled.img`
width: 100px;
height: 100px;
`;

export const RestorePasswordTitle = styled.h1`
color: ${COLORS_TRUCK.BLACK_TRUCK};
`;

export const RestorePasswordDescription = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
padding-left: 100px;
padding-right: 100px;
@media (max-width: 420px) {
    padding-left: 10px;
padding-right: 10px;
  }
`;
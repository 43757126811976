// import * as navigation from '../../routes/navigation';
import client from '../../../utils/api';
import { 
  GET_COMPANIES_BY_PLANS_MUTATION,
  GET_USERS_BY_PLANS_MUTATION
 } from './super-admin-home-queries';
// import {handleAlert} from '../../general/functions';



/**
 * getCompaniesByPlansAction - action for get companies by plans
 *
 * @returns {any}
 */
 export const getCompaniesByPlansAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_COMPANIES_BY_PLANS_MUTATION,
        variables: {},
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getUsersByPlansAction - action for get users by plans
 *
 * @returns {any}
 */
 export const getUsersByPlansAction = (group_country = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_USERS_BY_PLANS_MUTATION,
        variables: {
          group_country: group_country
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileButtonsContainer,
  ProfileFormContainer,
  ProfileFormInputsContainer,
  ProfileFormsContainer,
  ProfileLastButtonsContainer,
  FixedAmountText,
  ProfileSmallButtonGroup,
  ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  ProfileFormSeparator,
  ProfileBillingContainer,
  ProfileTableContainer,
  TableTitle,
  TableInfoContainer,
  ClientInfoContainer,
  TableInfo,
  GeneralErrorContainer,
  GeneralErrorText
} from "./clients-details-styles";
import i18n from '../../../i18n/i18n';
// import { useNavigate } from "react-router-dom";
import CategoryButton from "../../../components/buttons/category-button/CategoryButton";
import UserCard from "../../../components/user-card/UserCard";
import FormTextInput from "../../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../../components/inputs/forms/form-select/FormSelect";
// import FormRadioButtonsInput from "../../components/inputs/forms/form-radio-buttons-input/FormRadioButtonsInput";
import passwordIcon from "../../../assets/inputs/lock.svg";
import offEye from "../../../assets/inputs/eye-off.svg";
import redEye from "../../../assets/general/eye-icon-red.svg";
import filterByIcon from "../../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../../assets/profile/search-icon.svg";
import TruckTable from "../../../components/truck-table/TruckTable";
// import FormNumberInput from "../../components/inputs/forms/form-number-input/FormNumberInput";
import { COLORS_TRUCK } from "../../../utils/colors";
import { useNavigate, useParams } from "react-router-dom";
import { getUsersAction, getUserDriversAction, updateUserAction, exportUserDriversAction, removeUserAction, createUserAction, uploadUserPicAction, updateUserStatusAction } from "../clients/clients-actions";
import { openAlert } from "../../../redux/reducers/app.ts";
import { useAppDispatch } from "../../../redux/store.ts";
import { getCitiesAction, getCountriesAction, getStatesAction } from "../../signup/signup-actions";
import avatarDefault from "../../../assets/drivers/user.png";
import { emailsRegex } from "../../../utils/regex";
import FormNumberInput from "../../../components/inputs/forms/form-number-input/FormNumberInput";
import TextInput from "../../../components/inputs/text-input/TextInput";
import { CLIENTS_ROUTE } from "../../../routes/routes";
import { updateDriverAction, updateDriverStatusAction } from "../../driver-details/driver-details-actions";

const ClientDetailsScreen = () => {
  // const navigate = useNavigate()
  const { clientEmail } = useParams()
  const [billingFilterBy, setBillingFilterBy] = useState("1")
  const [user, setUser] = useState("")
  const [currentForm, setCurrentForm] = useState("company")
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [countryIso2, setCountryIso2] = useState(false)
  const [userDrivers, setUserDrivers] = useState([])
  const [avatar, setAvatar] = useState("")
  const [generalError, setGeneralError] = useState("")
  const [userId, setUserId] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState("")
  const [emailError, setEmailError] = useState("")
  // const [plans, setPlans] = useState("")
  const navigate = useNavigate();
  //USER DRIVERS
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [search, setSearch] = useState("")
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [type, setType] = useState("")
  const [paymentType, setPaymentType] = useState("")
  const [status, setStatus] = useState("")
  //company
  const [socialReason, setSocialReason] = useState("")
  const [nif, setNif] = useState("")
  const [type2, setType2] = useState("")
  const [phone, setPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [clientState, setClientState] = useState(true)
  const [country, setCountry] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [address, setAddress] = useState("")
  // const [companyType, setCompanyType] = useState("1")
  //access
  const [representativeName, setRepresentativeName] = useState("")
  const [representativeLastName, setRepresentativeLastName] = useState("")
  const [dni, setDni] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [secondPasswordShow, setSecondPasswordShow] = useState("")
  //variables
  const [fixedRemuneration, setFixedRemuneration] = useState("")
  const [nationalComplete, setNationalComplete] = useState("")
  const [internationalComplete, setInternationalComplete] = useState("")
  const [payPerKm, setPayPerKm] = useState("")
  const [lunch, setLunch] = useState("")
  const [lunch2, setLunch2] = useState("")
  const [dinner, setDinner] = useState("")
  const [dinner2, setDinner2] = useState("")
  const [nocturnal, setNocturnal] = useState("")
  const [nocturnal2, setNocturnal2] = useState("")
  const [lunchNational, setLunchNational] = useState("")
  const [lunchInternational, setLunchInternational] = useState("")
  const [dinnerNational, setDinnerNational] = useState("")
  const [dinnerInternational, setDinnerInternational] = useState("")
  const [nocturnalNational, setNocturnalNational] = useState("")
  const [nocturnalInternational, setNocturnalInternational] = useState("")
  const [sunday, setSunday] = useState("")

  useEffect(async () => {
    const countries = await getCountries()
    const iso2 = countries.filter(item => item.value === user?.country)[0]?.iso2
    await getStates(iso2)
    await getCities(iso2, user?.state)
    // await getPlansFunc()
    if (clientEmail) {
      await getUser()
    }
  }, [user, status, type, paymentType])

  // useEffect(() => {

  //   // if (isMounted.current) {
  //   getUserDrivers()
  //   // }


  // }, [status, type, paymentType])

  const getCountries = async () => {
    try {
      const response = await getCountriesAction()
      if (response && response.data) {
        const countriesArray = response.data.countries.map(country => { return { label: country.translate[0].langs[localStorage.getItem("language") || "es"], value: country._id, iso2: country.iso2 } })
        setCountries(countriesArray)
        return countriesArray
      }

    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }


  // const getPlansFunc = async () => {
  //   try {
  //     const response = await getPlans()
  //     if (response && response.data && response.data.plans) {
  //       setPlans(response.data.plans)
  //     }
  //   } catch (e) {
  //     // dispatch(openAlert({
  //     //   isAlertOpen: true
  //     // }))
  //   }
  // }

  const getUserDrivers = async (id = false, reset = false) => {
    try {
      if (reset) {
        setUserDrivers([])
      }

      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: search || null,
        status: status || null,
        type: type || null,
        payment_type: paymentType || null
      }

      const uId = id || userId
      const response = await getUserDriversAction(uId, data)
      if (response?.data?.drivers?.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let driversArray = userDrivers.concat(response?.data?.drivers)
      if (!search && !status && !type && !paymentType) {
        setUserDrivers(driversArray?.filter((item, index, self) => index === self.findIndex((t) => t.email === item.email)))
      } else {
        setUserDrivers(response?.data?.drivers)
      }

    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getCities = async (country, province) => {
    if (country && province) {
      try {
        const response = await getCitiesAction(country, province)
        const cities = response?.data?.cities?.map(city => { return { label: city.name, value: city._id } })
        setCities(cities)
      } catch (e) {
        dispatch(openAlert({
          isAlertOpen: true
        }))
      }
    }
  }

  const getStates = async (country) => {
    if (country) {
      try {
        const response = await getStatesAction(country)
        setStates(response?.data?.state.map(state => { return { label: state.translate[0].langs[localStorage.getItem("language") || "es"], value: state._id } }))
      } catch (e) {
        dispatch(openAlert({
          isAlertOpen: true
        }))
      }
    }
  }

  const getUser = async () => {
    try {
      setLoading(true)
      const data = {
        email: clientEmail || null
      }
      const res = await getUsersAction(data)
      if (res?.data?.users.length > 0) {
        const user = res?.data?.users[0]
        setUser(user)
        setUserId(user._id)
        setSocialReason(user.company)
        setNif(user.nif)
        setType2(String(user.company_type).toUpperCase())
        setCountry(user.country)
        setProvince(user.state)
        setClientState(user.status)
        setCity(user.city)
        setPostalCode(user.zip)
        // setCompanyNif(user.nif)
        setAddress(user.address)
        // setCompanyType(user.company_type)
        setPhone(user.phone)
        setContactEmail(user.email_contact)
        setRepresentativeName(user.name)
        setRepresentativeLastName(user.surnames)
        setDni(user.dni)
        setPosition(user.position)
        setEmail(user.email)
        setConfirmEmail(user.email)
        setAvatar(user?.avatar?.fullpath)
        setPassword("")
        setPasswordShow("")
        setConfirmPassword("")
        setSecondPasswordShow("")

        setFixedRemuneration(user.payment_variables.fixed_remuneration === 0 ? "" : user.payment_variables.fixed_remuneration)
        setLunchNational(user.payment_variables?.diets?.lunch === 0 ? "" : user.payment_variables?.diets?.lunch)
        setLunchInternational(user.payment_variables?.diets?.lunch_international === 0 ? "" : user.payment_variables?.diets?.lunch_international)
        setDinnerNational(user.payment_variables?.diets?.dinner === 0 ? "" : user.payment_variables?.diets?.dinner)
        setDinnerInternational(user.payment_variables?.diets?.dinner_international === 0 ? "" : user.payment_variables?.diets?.dinner_international)
        // setSunday(user.payment_variables?.diets?.sunday)
        setNocturnalNational(user.payment_variables?.diets?.nocturnal === 0 ? "" : user.payment_variables?.diets?.nocturnal)
        setNocturnalInternational(user.payment_variables?.diets?.nocturnal_international === 0 ? "" : user.payment_variables?.diets?.nocturnal_international)
        setNationalComplete(user.payment_variables.complete_national === 0 ? "" : user.payment_variables.complete_national)
        setInternationalComplete(user.payment_variables.complete_international === 0 ? "" : user.payment_variables.complete_international)
        setPayPerKm(user.payment_variables.payment_per_km === 0 ? "" : user.payment_variables.payment_per_km)

        setLunch(user.payment_variables?.diets?.lunch_timetable?.split(":")[0])
        setLunch2(user.payment_variables?.diets?.lunch_timetable?.split(":")[1])
        setDinner(user.payment_variables?.diets?.dinner_timetable?.split(":")[0])
        setDinner2(user.payment_variables?.diets?.dinner_timetable?.split(":")[1])
        setNocturnal(user.payment_variables?.diets?.nocturnal_timetable?.split(":")[0])
        setNocturnal2(user.payment_variables?.diets?.nocturnal_timetable?.split(":")[1])

        if (country) {
          const iso2 = countries.filter(item => item.value === user?.country)[0]?.iso2
          await getStates(iso2)
          await getCities(iso2, user?.state)
        }

        await getUserDrivers(user?._id)

        // setPlan(user.plan_details?.plan_id)
        // setPaymentType(user.plan_details?.payment_type.toUpperCase())
        // setUserNumber(user.plan_details?.max_users)
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)
      // const errorCode = localStorage.getItem("error-code")
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      // if (errorCode === "998") {
      //   setUsers([])
      // }
      // if (!search && !type) {
      // }
    }
  }


  const hoursArrayForSelectNocturnal = [
    { label: "00:00", value: "" },
    { label: "00:00", value: "00" },
    { label: "01:00", value: "01" },
    { label: "02:00", value: "02" },
    { label: "03:00", value: "03" },
    { label: "04:00", value: "04" },
    { label: "05:00", value: "05" },
    { label: "06:00", value: "06" },
    { label: "07:00", value: "07" },
    { label: "08:00", value: "08" },
    { label: "09:00", value: "09" },
    { label: "10:00", value: "10" },
    { label: "11:00", value: "11" },
    { label: "12:00", value: "12" },
    { label: "13:00", value: "13" },
    { label: "14:00", value: "14" },
    { label: "15:00", value: "15" },
    { label: "16:00", value: "16" },
    { label: "17:00", value: "17" },
    { label: "18:00", value: "18" },
    { label: "19:00", value: "19" },
    { label: "20:00", value: "20" },
    { label: "21:00", value: "21" },
    { label: "22:00", value: "22" },
    { label: "23:00", value: "23" },
  ]
  const hoursArrayForSelectDinner = [
    { label: "00:00", value: "" },
    { label: "00:00", value: "00" },
    { label: "01:00", value: "01" },
    { label: "02:00", value: "02" },
    { label: "03:00", value: "03" },
    { label: "04:00", value: "04" },
    { label: "05:00", value: "05" },
    { label: "06:00", value: "06" },
    { label: "07:00", value: "07" },
    { label: "08:00", value: "08" },
    { label: "09:00", value: "09" },
    { label: "10:00", value: "10" },
    { label: "11:00", value: "11" },
    { label: "12:00", value: "12" },
    { label: "13:00", value: "13" },
    { label: "14:00", value: "14" },
    { label: "15:00", value: "15" },
    { label: "16:00", value: "16" },
    { label: "17:00", value: "17" },
    { label: "18:00", value: "18" },
    { label: "19:00", value: "19" },
    { label: "20:00", value: "20" },
    { label: "21:00", value: "21" },
    { label: "22:00", value: "22" },
    { label: "23:00", value: "23" },
  ]
  const hoursArrayForSelectLunch = [
    { label: "00:00", value: "" },
    { label: "00:00", value: "00" },
    { label: "01:00", value: "01" },
    { label: "02:00", value: "02" },
    { label: "03:00", value: "03" },
    { label: "04:00", value: "04" },
    { label: "05:00", value: "05" },
    { label: "06:00", value: "06" },
    { label: "07:00", value: "07" },
    { label: "08:00", value: "08" },
    { label: "09:00", value: "09" },
    { label: "10:00", value: "10" },
    { label: "11:00", value: "11" },
    { label: "12:00", value: "12" },
    { label: "13:00", value: "13" },
    { label: "14:00", value: "14" },
    { label: "15:00", value: "15" },
    { label: "16:00", value: "16" },
    { label: "17:00", value: "17" },
    { label: "18:00", value: "18" },
    { label: "19:00", value: "19" },
    { label: "20:00", value: "20" },
    { label: "21:00", value: "21" },
    { label: "22:00", value: "22" },
    { label: "23:00", value: "23" },
  ]

  const saveProfile = async () => {
    const passwordValidation = password && password.length < 8
    const passwordMatchValidation = password && password !== confirmPassword
    const contactEmailValidation = contactEmail && !contactEmail.match(emailsRegex)

    if (!socialReason || !nif || !country) {
      setCurrentForm("company")
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (!representativeName || !representativeLastName || !dni || !email) {
      setCurrentForm("access")
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (passwordValidation) {
      setShowInputsErrors(true)
      setCurrentForm("access")
      setPasswordError(i18n.t("GENERAL.passwordLength"))
      return
    }

    if (passwordMatchValidation) {
      setShowInputsErrors(true)
      setCurrentForm("access")
      setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
      return
    }

    if (contactEmailValidation) {
      setShowInputsErrors(true)
      setCurrentForm("company")
      setContactEmailErrorMessage(i18n.t("PROFILE.emailValidation"))
      return
    }

    if (!passwordValidation && !passwordMatchValidation && !contactEmailValidation) {
      setShowInputsErrors(false)
      setContactEmailErrorMessage("")
      setPasswordError("")
    }

    // if (!payPerKm) {
    //   setCurrentForm("variables")
    //   setGeneralError(i18n.t("SIGNUP.completeAllFields"))
    //   setShowInputsErrors(true)
    //   return
    // }

    if (socialReason &&
      nif &&
      // companyType &&
      country &&
      representativeName &&
      representativeLastName &&
      dni &&
      // payPerKm &&
      email) {
      setGeneralError("")
      setShowInputsErrors(false)
    }

    try {
      setLoading(true)
      let data = {
        address: address || null,
        city: city || null,
        company: socialReason || null,
        company_type: type2?.toUpperCase() || null,
        country: country || null,
        dni: dni || null,
        email_contact: contactEmail || null,
        email: email || null,
        language: String(localStorage.getItem("language") || "es").toUpperCase(),
        name: representativeName || null,
        nif: nif || null,
        payment_variables: {
          complete_national: nationalComplete ? Number(nationalComplete) : 0,
          complete_international: internationalComplete ? Number(internationalComplete) : 0,
          diets: {
            dinner: dinnerNational ? Number(dinnerNational) : 0,
            dinner_international: dinnerInternational ? Number(dinnerInternational) : 0,
            dinner_timetable: dinner && dinner2 ? `${dinner}:${dinner2}` : null,
            lunch: lunchNational ? Number(lunchNational) : 0,
            lunch_international: lunchInternational ? Number(lunchInternational) : 0,
            lunch_timetable: lunch && lunch2 ? `${lunch}:${lunch2}` : null,
            nocturnal: nocturnalNational ? Number(nocturnalNational) : 0,
            nocturnal_international: nocturnalInternational ? Number(nocturnalInternational) : 0,
            nocturnal_timetable: nocturnal2 && nocturnal ? `${nocturnal}:${nocturnal2}` : null,
            sunday: sunday ? Number(sunday) : 0
          },
          fixed_remuneration: fixedRemuneration ? Number(fixedRemuneration) : 0,
          payment_per_km: payPerKm && payPerKm >= 0 ? Number(payPerKm) : 0
          // payment_type: paymentType || null
        },
        phone: phone ? String(phone) : null,
        position: position || null,
        state: province || null,
        surnames: representativeLastName || null,
        zip: postalCode || null
      }

      if (password && password === confirmPassword) {
        data.password = password
      }

      //   if(!clientEmail){
      //  data.payment_method = "CREDIT_CARD"
      //  data.payment_type = "MONTHLY"
      //  data.plan_id = plans[0]._id
      //  data.users = 4
      //   }

      if (clientEmail) {
        await updateUserAction(userId, data)
        await getUser()
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("PROFILE.updatedSuccessMessage"),
          isAlertOpen: true
        }))
      } else {
        await createUserAction(data)
        navigate(CLIENTS_ROUTE)
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("PROFILE.createdSuccessMessage"),
          isAlertOpen: true
        }))
      }

      setLoading(false)

    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getUserDrivers()
    }
  };

  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('DRIVERS.forms.drivers.title')}
      </TableTitle>
      <TableInfo>
        {`${i18n.t('DRIVERS.forms.drivers.actives')} ${userDrivers?.filter(item => item.status.toLowerCase() === "active").length} ${i18n.t('DRIVERS.forms.drivers.of')} ${userDrivers?.length}`}
      </TableInfo>
    </TableInfoContainer>
    <FormSelect
      value={billingFilterBy}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        setBillingFilterBy(value)
        if (value === "ACTIVE" || value === "INACTIVE") {
          setUserDrivers([])
          setStatus(value)
          setType("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
          // getDriversFunc()
        }
        if (value === "INTERNAL" || value === "EXTERNAL") {
          setUserDrivers([])
          setType(value)
          setStatus("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
          //  getDriversFunc()
        }
        if (value === "DIETS" || value === "MILEAGE") {
          setUserDrivers([])
          setPaymentType(value)
          setStatus("")
          setType("")
          setLimit(30)
          setOffset(0)
        }
        if (value === "" || value === "1") {
          setUserDrivers([])
          setStatus("")
          setType("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
          // getDriversFunc()
        }
      }}

      defaultValue="1"
      containerStyle={{ width: 220 }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('DRIVERS.forms.drivers.buttons.filterBy'), icon: filterByIcon },
        { value: 'ACTIVE', label: i18n.t('DRIVERS.forms.drivers.buttons.active'), icon: filterByIcon },
        { value: 'INACTIVE', label: i18n.t('DRIVERS.forms.drivers.buttons.inactive'), icon: filterByIcon },
        { value: 'INTERNAL', label: i18n.t('DRIVERS.forms.drivers.buttons.internal'), icon: filterByIcon },
        { value: 'EXTERNAL', label: i18n.t('DRIVERS.forms.drivers.buttons.external'), icon: filterByIcon },
        { value: 'DIETS', label: i18n.t('DRIVERS.forms.drivers.buttons.diets'), icon: filterByIcon },
        { value: 'MILEAGE', label: i18n.t('DRIVERS.forms.drivers.buttons.mileage'), icon: filterByIcon },
      ]}
      required
    />
    {/* <FormSelect
  value={billingFilterBy}
  onChange={({ target: { value } }) => setBillingFilterBy(value)}
  containerStyle={{ width: 220 }}
  items={[
    { value: '1', label: i18n.t('PROFILE.forms.billing.buttons.search'), icon: searchIcon },
  ]}
  required
/> */}
    <TextInput
      icon={searchIcon}
      value={search}
      type="text"
      placeholder={i18n.t('DRIVERS.forms.drivers.searchPlaceHolder')}
      containerStyle={{ width: 250 }}
      onChange={(e) => {
        // if (e.target.value === "") {
        // }
        getUserDrivers()

        setSearch(e.target.value)
      }}
      onKeyPress={handleKeyPress}
    // disabled={loading}
    />
    <CategoryButton
      onClick={() => exportUserDriversFunc()}
      active={false}
      icon=""
      title={i18n.t('DRIVERS.forms.drivers.buttons.export')}
    />
  </ProfileBillingContainer>

  const exportUserDriversFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: search || null,
        status: status || null,
        type: type || null,
        payment_type: paymentType || null
      }
      const res = await exportUserDriversAction(userId, data)
      var win = window.open(res.data.exportDrivers.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const removeUser = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("CLIENT_DETAILS.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await removeUserAction(userId)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("CLIENT_DETAILS.removedSuccessMessage"),
            isAlertOpen: true
          }))
          setLoading(false)
          navigate(CLIENTS_ROUTE)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const onEditPicture = async (img) => {
    try {
      const res = await uploadUserPicAction(img, userId)
      getUser()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const changeUserStatus = async (value) => {
    try {
      await updateUserStatusAction(userId, value)
      getUser()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const changeDriverStatus = async (driverId, value) => {
    try {
      await updateDriverStatusAction(driverId, value)
      setUserDrivers(userDrivers.map(item => item._id === driverId ? { ...item, status: value } : item))
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const updateDriver = async (driverId, value, name) => {
    try {
      let data = {
        address: null,
        birthday: null,
        city: null,
        company_phone: null,
        country: null,
        dni: null,
        documentation: {
          CAP: {
            card: null,
            date: null
          },
          driving_cards: {
            AM: null,
            A1: null,
            A2: null,
            A: null,
            B: null,
            C1: null,
            C: null,
            D1: null,
            D: null,
            BE: null,
            C1E: null,
            CE: null,
            D1E: null,
            DE: null
          },
          sanitary_card: { card: null, date: null },
          sanitary_card_EU: { card: null, date: null },
          tachograph_card: { card: null, date: null }
        },
        email: null,
        name: null,
        password: null,
        payment_variables: {
          complete_international: null,
          complete_national: null,
          diets: {
            dinner: null,
            dinner_international: null,
            lunch: null,
            lunch_international: null,
            nocturnal: null,
            nocturnal_international: null,
            sunday: null
          },
          fixed_remuneration: null,
          payment_per_km: null,
          payment_type: null,
          values: null
        },
        personal_email: null,
        phone: null,
        state: null,
        surnames: null,
        type: null,
        zip: null
      }
      if (name === "type") {
        data.type = value
      }
      if (name === "payment_type") {
        data.payment_variables.payment_type = value
      }

      await updateDriverAction(driverId, data, null)

      if (name === "type") {
        setUserDrivers(userDrivers.map(item => item._id === driverId ? { ...item, type: value } : item))
      }
      if (name === "payment_type") {
        setUserDrivers(userDrivers.map(item => item._id === driverId ? { ...item, payment_variables: { ...item.payment_variables, payment_type: value } } : item))
      }
      // getUser()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileButtonsContainer>
          <CategoryButton
            onClick={() => setCurrentForm("company")}
            active={currentForm === "company"}
            icon="company"
            title={i18n.t('CLIENT_DETAILS.buttons.companyButton')}
          />
          <CategoryButton
            onClick={() => setCurrentForm("access")}
            active={currentForm === "access"}
            icon="access"
            title={i18n.t('CLIENT_DETAILS.buttons.accessButton')}
          />
          <CategoryButton
            onClick={() => setCurrentForm("variables")}
            active={currentForm === "variables"}
            icon="variables"
            title={i18n.t('CLIENT_DETAILS.buttons.variablesButton')}
          />
          {clientEmail && <CategoryButton
            onClick={() => setCurrentForm("users")}
            active={currentForm === "users"}
            icon="people"
            title={i18n.t('CLIENT_DETAILS.buttons.users')}
          />}
        </ProfileButtonsContainer>
        <ProfileFormSeparator />
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <ProfileFormContainer>
          {currentForm === "company"
            ? <>
              {clientEmail && <ClientInfoContainer>
                <UserCard
                  user={{
                    name: `${representativeName} ${representativeLastName}`,
                    position: socialReason,
                    picture: avatar || avatarDefault
                  }}
                  onEditPicture={(img) => onEditPicture(img)}
                />
                <FormSelect
                  value={clientState}
                  containerStyle={{ width: 150, margin: 'auto' }}
                  defaultValue={''}
                  onChange={({ target: { value } }) => {
                    setClientState(value)
                    changeUserStatus(value)
                  }}
                  disabled={loading}
                  showError={!clientState && showInputsErrors}
                  items={[
                    {
                      value: "ACTIVE",
                      label: <p
                        style={{
                          color: COLORS_TRUCK.GREEN_TRUCK,
                          margin: 0
                        }}
                      >
                        {i18n.t("GENERAL.active")}
                      </p>,
                    },
                    {
                      value: "INACTIVE",
                      label: <p
                        style={{
                          color: COLORS_TRUCK.ORANGE_TRUCK,
                          margin: 0
                        }}
                      >
                        {i18n.t("GENERAL.inactive")}
                      </p>,
                    },
                  ]}
                  required
                />
              </ClientInfoContainer>}
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.company.socialReason')}
                    type={"text"}
                    value={socialReason}
                    onChange={({ target: { value } }) => setSocialReason(value)}
                    showError={!socialReason && showInputsErrors}
                    required
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.company.nif')}
                    type={"text"}
                    value={nif}
                    onChange={({ target: { value } }) => setNif(value)}
                    showError={!nif && showInputsErrors}
                    required
                    disabled={loading}
                  />
                  <FormSelect
                    title={i18n.t('PROFILE.forms.company.type')}
                    value={type2}
                    onChange={({ target: { value } }) => setType2(value)}
                    items={[
                      { value: '', label: "", },
                      { value: 'COMPANY', label: i18n.t('SIGNUP.companyInfoTable.companyType1') },
                      { value: 'AGENCY', label: i18n.t('SIGNUP.companyInfoTable.companyType2') },
                    ]}
                    disabled={loading}
                    required
                    showError={!type2 && showInputsErrors}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.company.phone')}
                    type={"text"}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.company.contactEmail')}
                    type={"email"}
                    value={contactEmail}
                    onChange={({ target: { value } }) => setContactEmail(value)}
                    disabled={loading}
                    errorMessage={contactEmailErrorMessage}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormSelect
                    disabled={loading}
                    title={i18n.t('CLIENT_DETAILS.forms.company.country')}
                    value={country}
                    onChange={({ target: { value } }) => {
                      const iso2 = countries.filter(item => item.value === value)[0]?.iso2
                      setCountry(value)
                      setCountryIso2(iso2)
                      getStates(iso2)
                    }}
                    items={countries}
                    required
                    showError={!country && showInputsErrors}
                  />
                  {(country && states) && <FormSelect
                    disabled={loading}
                    title={i18n.t('CLIENT_DETAILS.forms.company.province')}
                    value={province}
                    onChange={({ target: { value } }) => {
                      setProvince(value)
                      getCities(countryIso2, value)
                    }}
                    items={states}
                    required
                    showError={!province && showInputsErrors}
                  />}
                  {(country && province && cities) && <FormSelect
                    disabled={loading}
                    title={i18n.t('CLIENT_DETAILS.forms.company.city')}
                    value={city}
                    onChange={({ target: { value } }) => setCity(value)}
                    items={cities}

                  />}
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.company.postalCode')}
                    type={"text"}
                    value={postalCode}
                    onChange={({ target: { value } }) => setPostalCode(value)}
                    disabled={loading}
                    required
                    showError={!postalCode && showInputsErrors}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.company.address')}
                    type={"text"}
                    value={address}
                    onChange={({ target: { value } }) => setAddress(value)}
                    disabled={loading}
                    required
                    showError={!address && showInputsErrors}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator
                />
                <ProfileLastButtonsContainer>
                  {clientEmail && <CategoryButton
                    onClick={() => removeUser()}
                    active={false}
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                    loading={loading}
                    disabled={loading}
                  />}
                  <CategoryButton
                    onClick={() => {
                      if (clientEmail) {
                        saveProfile()
                      } else {
                        if (socialReason && country && nif && type2 && address && province && postalCode) {
                          setCurrentForm("access")
                          setShowInputsErrors(false)
                        } else {
                          setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                          setShowInputsErrors(true)
                        }

                        const contactEmailValidation = contactEmail && !contactEmail.match(emailsRegex)
                        if (contactEmailValidation) {
                          setShowInputsErrors(true)
                          setCurrentForm("company")
                          setContactEmailErrorMessage(i18n.t("PROFILE.emailValidation"))
                        }

                      }
                    }}
                    active
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.save')}
                    loading={loading}
                    disabled={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "access"
            ? <>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.representativeName')}
                    type={"text"}
                    value={representativeName}
                    onChange={({ target: { value } }) => setRepresentativeName(value)}
                    allowJustLetters
                    required
                    showError={!representativeName && showInputsErrors}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.representativeLastName')}
                    type={"text"}
                    value={representativeLastName}
                    onChange={({ target: { value } }) => setRepresentativeLastName(value)}
                    allowJustLetters
                    required
                    showError={!representativeLastName && showInputsErrors}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.dni')}
                    type={"text"}
                    value={dni}
                    onChange={({ target: { value } }) => setDni(value)}
                    required
                    showError={!dni && showInputsErrors}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.position')}
                    type={"text"}
                    value={position}
                    onChange={({ target: { value } }) => setPosition(value)}
                    disabled={loading}
                    required
                    showError={!position && showInputsErrors}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.email')}
                    type={"email"}
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    disabled={loading}
                    showError={!email && showInputsErrors}
                    required
                    errorMessage={emailError}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.confirmEmail')}
                    type={"email"}
                    value={confirmEmail}
                    onChange={({ target: { value } }) => setConfirmEmail(value)}
                    disabled={loading}
                    showError={!confirmEmail && showInputsErrors}
                    required
                    errorMessage={emailError}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.password')}
                    iconRightClick={() => setPasswordShow(!passwordShow)}
                    rightIcon={passwordShow ? offEye : redEye}
                    icon={passwordIcon}
                    type={passwordShow ? "text" : "password"}
                    value={password}
                    errorMessage={passwordError}
                    onChange={({ target: { value } }) => setPassword(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('CLIENT_DETAILS.forms.access.confirmPassword')}
                    iconRightClick={() => setSecondPasswordShow(!secondPasswordShow)}
                    rightIcon={secondPasswordShow ? offEye : redEye}
                    icon={passwordIcon}
                    type={secondPasswordShow ? "text" : "password"}
                    value={confirmPassword}
                    errorMessage={passwordError}
                    onChange={({ target: { value } }) => setConfirmPassword(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator
                />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => removeUser()}
                    active={false}
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                    loading={loading}
                  />
                  <CategoryButton
                    onClick={() => {
                      if (clientEmail) {
                        saveProfile()
                        return
                      }

                      if (representativeName && representativeLastName && dni && email && password && confirmPassword && confirmEmail && position) {
                        const passwordValidation = password && password.length < 8
                        const passwordMatchValidation = (confirmPassword && password) && password !== confirmPassword
                        const emailsMatchValidation = (email && confirmEmail) && email !== confirmEmail
                        if (passwordValidation) {
                          setShowInputsErrors(true)
                          setCurrentForm("access")
                          setPasswordError(i18n.t("GENERAL.passwordLength"))
                          setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                          return
                        } else {
                          setPasswordError("")
                        }

                        if (passwordMatchValidation) {
                          setShowInputsErrors(true)
                          setCurrentForm("access")
                          setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
                          setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                          return
                        } else {
                          setPasswordError("")
                        }

                        if (emailsMatchValidation) {
                          setShowInputsErrors(true)
                          setCurrentForm("access")
                          setEmailError(i18n.t("GENERAL.emailsNotMatch"))
                          setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                          return
                        } else {
                          setEmailError("")
                        }

                        setCurrentForm("variables")
                        setShowInputsErrors(false)
                      } else {
                        setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                        setShowInputsErrors(true)
                      }


                    }}
                    active
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "variables"
            ? <>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer style={{ width: 400 }}>
                  <FormNumberInput
                    title={i18n.t('CLIENT_DETAILS.forms.variables.fixedRemuneration')}
                    type={"number"}
                    value={fixedRemuneration}
                    onValueChange={(value) => setFixedRemuneration(value)}
                    rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                    placeholder={"0.00"}
                    // required
                    // showError={!fixedRemuneration && showInputsErrors}
                    allowDecimals
                    disabled={loading}
                  />
                  <FormNumberInput
                    title={i18n.t('CLIENT_DETAILS.forms.variables.nationalComplete')}
                    type={"text"}
                    value={nationalComplete}
                    onValueChange={(value) => setNationalComplete(value)}
                    rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                    placeholder={"0.00"}
                    allowDecimals
                    disabled={loading}
                  />
                  <FormNumberInput
                    title={i18n.t('CLIENT_DETAILS.forms.variables.internationalComplete')}
                    type={"text"}
                    value={internationalComplete}
                    onValueChange={(value) => setInternationalComplete(value)}
                    rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                    placeholder={"0.00"}
                    allowDecimals
                    disabled={loading}
                  />
                  <FormNumberInput
                    title={i18n.t('CLIENT_DETAILS.forms.variables.payPerKm')}
                    type={"text"}
                    value={payPerKm}
                    onValueChange={(value) => setPayPerKm(value)}
                    rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                    placeholder={"0.00"}
                    // required
                    // showError={!payPerKm && showInputsErrors}
                    allowDecimals
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer style={{ width: 600 }}>
                  <ProfileSmallButtonsGroup>
                    <ProfileSmallButtonGroup>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.variables.lunch')}
                        value={lunch}
                        containerStyle={{ width: 150 }}
                        onChange={({ target: { value } }) => setLunch(value)}
                        items={hoursArrayForSelectLunch}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                      <FormSelect
                        value={lunch2}
                        containerStyle={{ width: 150, marginTop: 40 }}
                        onChange={({ target: { value } }) => setLunch2(value)}
                        items={lunch ? hoursArrayForSelectLunch.filter(item => lunch && Number(item.value) > Number(lunch)) : hoursArrayForSelectLunch}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.variables.dinner')}
                        value={dinner}
                        containerStyle={{ width: 150 }}
                        onChange={({ target: { value } }) => setDinner(value)}
                        items={lunch2 ? hoursArrayForSelectDinner.filter(item => Number(item.value) >= Number(lunch2)) : hoursArrayForSelectDinner}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                      <FormSelect
                        value={dinner2}
                        containerStyle={{ width: 150, marginTop: 40 }}
                        onChange={({ target: { value } }) => setDinner2(value)}
                        items={dinner ? hoursArrayForSelectDinner.filter(item => Number(item.value) > Number(dinner)) : hoursArrayForSelectDinner}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.variables.nocturnal')}
                        value={nocturnal}
                        containerStyle={{ width: 150 }}
                        onChange={({ target: { value } }) => setNocturnal(value)}
                        // items={dinner2 ? hoursArrayForSelectNocturnal.filter(item => Number(item.value) >= Number(dinner2)) : hoursArrayForSelectNocturnal}
                        items={hoursArrayForSelectNocturnal}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                      <FormSelect
                        value={nocturnal2}
                        containerStyle={{ width: 150, marginTop: 40 }}
                        onChange={({ target: { value } }) => setNocturnal2(value)}
                        items={hoursArrayForSelectNocturnal}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                    </ProfileSmallButtonGroup>
                  </ProfileSmallButtonsGroup>
                  <ProfileSmallButtonsGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('CLIENT_DETAILS.forms.variables.national')}
                        type={"text"}
                        containerStyle={{ width: 100 }}
                        value={lunchNational}
                        onValueChange={(value) => setLunchNational(value)}
                        placeholder={"0.00€"}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('CLIENT_DETAILS.forms.variables.international')}
                        type={"text"}
                        containerStyle={{ width: 100 }}
                        value={lunchInternational}
                        onValueChange={(value) => setLunchInternational(value)}
                        placeholder={"0.00€"}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('CLIENT_DETAILS.forms.variables.national')}
                        type={"text"}
                        containerStyle={{ width: 100 }}
                        value={dinnerNational}
                        onValueChange={(value) => setDinnerNational(value)}
                        placeholder={"0.00€"}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('CLIENT_DETAILS.forms.variables.international')}
                        type={"text"}
                        containerStyle={{ width: 100 }}
                        value={dinnerInternational}
                        onValueChange={(value) => setDinnerInternational(value)}
                        placeholder={"0.00€"}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('CLIENT_DETAILS.forms.variables.national')}
                        type={"text"}
                        containerStyle={{ width: 100 }}
                        value={nocturnalNational}
                        onValueChange={(value) => setNocturnalNational(value)}
                        placeholder={"0.00€"}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('CLIENT_DETAILS.forms.variables.international')}
                        type={"text"}
                        containerStyle={{ width: 100 }}
                        value={nocturnalInternational}
                        onValueChange={(value) => setNocturnalInternational(value)}
                        placeholder={"0.00€"}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                  </ProfileSmallButtonsGroup>
                  <ProfileSmallButtonsGroup style={{
                    width: "90%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start"
                  }}>
                    <FormNumberInput
                      title={i18n.t('PROFILE.forms.variables.sunday')}
                      type={"text"}
                      value={sunday}
                      placeholder={"0.00"}
                      onValueChange={(value) => setSunday(value)}
                      rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                      allowDecimals
                      disabled={loading}
                    />
                  </ProfileSmallButtonsGroup>
                </ProfileFormInputsContainer>
                <ProfileFormSeparator
                />
                <ProfileLastButtonsContainer>
                  {/* <CategoryButton
                    onClick={() => null}
                    active={false}
                    width={300}
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.forms.variables.buttons.editVariablesPerProvince')}
                  disabled={loading}
                  /> */}
                  {clientEmail && <CategoryButton
                    onClick={() => removeUser()}
                    active={false}
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                    loading={loading}
                  />}
                  <CategoryButton
                    onClick={() => {
                      // if (payPerKm) {
                      saveProfile()
                      setShowInputsErrors(false)
                      // } else {
                      //   setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                      //   setShowInputsErrors(true)
                      // }
                    }}
                    active
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "users" && clientEmail
            ? <>
              <ProfileTableContainer>
                <TruckTable
                  type="client-users"
                  header={tableHeader}
                  titles={[
                    { label: i18n.t('CLIENT_DETAILS.forms.users.picture') },
                    { label: i18n.t('CLIENT_DETAILS.forms.users.name') },
                    { label: i18n.t('CLIENT_DETAILS.forms.users.lastname') },
                    { label: i18n.t('CLIENT_DETAILS.forms.users.email') },
                    { label: i18n.t('CLIENT_DETAILS.forms.users.type') },
                    { label: i18n.t('CLIENT_DETAILS.forms.users.payments') },
                    { label: i18n.t('CLIENT_DETAILS.forms.users.state') }
                  ]}
                  onEndReach={() => {
                    if (keepIncreasing) {
                      setOffset(offset + increaseLimit)
                      setLimit(limit + increaseLimit)
                    }

                    getUserDrivers()
                  }}
                  changeDriverStatusSuperAdmin={changeDriverStatus}
                  updateDriver={updateDriver}
                  updateTable={(updatedDriver) => {
                    setUserDrivers([])
                    setOffset(0)
                    setLimit(30)

                    if (updatedDriver) {
                      setUserDrivers(userDrivers?.map(driver => driver._id === updatedDriver._id ? updatedDriver : driver))
                    }

                    // getDriversFunc(reset)
                  }}
                  style={{
                    maxHeight: 700,
                    overflow: "auto"
                  }}
                  data={userDrivers}
                />
              </ProfileTableContainer>
            </> : null}
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default ClientDetailsScreen
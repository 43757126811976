import React, { useState } from 'react';
// import WrongIcon from '../../assets/contact-us/alert/wrong_icon.svg';
// import SuccessIcon from '../../assets/contact-us/alert/success_icon.svg';
import AlertModal from '../modal/Modal';
// import { FEDERAL_BLUE } from '../color-palatte';

import './Alert.css';
// import Loader from '../loader/Loader';
import i18n from '../../i18n/i18n';
import { useAppDispatch } from "../../redux/store.ts";
import { closeAlert } from "../../redux/reducers/app.ts";
import { useSelector } from 'react-redux';
import CategoryButton from '../buttons/category-button/CategoryButton';
import { useNavigate } from "react-router-dom";
import { PROFILE_ROUTE } from '../../routes/routes';
// import FormTextInput from '../inputs/forms/form-text-input/FormTextInput';
import FormNumberInput from '../inputs/forms/form-number-input/FormNumberInput';
import axios from 'axios'
import fileDownload from 'js-file-download'
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import redPinIcon from "../../assets/home/red-pin.svg";
import FormTextInput from '../inputs/forms/form-text-input/FormTextInput';

const Alert = (props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isAlertOpen = useSelector((state) => state).appSlice.isAlertOpen
  const alertType = useSelector((state) => state).appSlice.alertType
  const alertOnYes = useSelector((state) => state).appSlice.alertOnYes
  const alertInputPlaceholder = useSelector((state) => state).appSlice.alertInputPlaceholder
  const alertOnYesTitle = useSelector((state) => state).appSlice.alertOnYesButtonTitle
  const alertMessage = useSelector((state) => state).appSlice.alertMessage
  const alertImage = useSelector((state) => state).appSlice.alertImage
  const coords = useSelector((state) => state).appSlice.coords
  const isPlanCanceled = alertType === "plan-canceled"
  const isPlanWaiting = alertType === "plan-waiting"
  const isQuestion = alertType === "question"
  const isQuestionInput = alertType === "question-input"
  const isCoordsAlert = alertType === "coords"
  const isImagePreview = alertType === "image-preview"
  const [inputValue, setInputValue] = useState("")
  const redIcon = divIcon({
    html: renderToStaticMarkup(<img alt="" src={redPinIcon} />),
  })

  const downloadFile = (fileURL) => {
    var filename = fileURL.replace(/^.*[\\\/]/, '').split('?v=')[0]
    axios.get(fileURL, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }
  return (
    <AlertModal
      isOpen={isAlertOpen}
      overlayClassName='Alert__overlay'
      modalClassName='Alert__Modal'
    >
      <div className='Alert__Container'>
        <div className='Alert__Message-content'>
          {isCoordsAlert
            ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <FormTextInput
                // disabled={loading || taskId}
                titleStyle={{ textAlign: "left" }}
                disabled={true}
                title={i18n.t('JOURNEYS.place')}
                type={"text"}
                value={coords.name}
                />
              <FormTextInput
                // disabled={loading || taskId}
                titleStyle={{ textAlign: "left" }}
                disabled={true}
                title={i18n.t('JOURNEYS.coords')}
                type={"text"}
                value={`${coords.latitude},${coords.longitude}`}
                canCopy
              />
              <MapContainer
                style={{ width: 300, height: '300px', marginTop: 20, borderRadius: 15 }}
                center={[Number(coords.latitude), Number(coords.longitude)]}
                zoom={5}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <Marker icon={redIcon} color="red" position={[Number(coords.latitude), Number(coords.longitude)]}>

                </Marker>
              </MapContainer>
              <CategoryButton
                loading={false}
                onClick={() => dispatch(closeAlert())}
                active
                disabled={false}
                icon=""
                title={i18n.t('ALERT.continue')}
              />
            </div>
            : isImagePreview
              ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={alertImage} style={{ width: 250, height: 250, borderRadius: 10 }} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <CategoryButton
                    loading={false}
                    onClick={() => dispatch(closeAlert())}
                    active
                    disabled={false}
                    icon=""
                    title={i18n.t('ALERT.continue')}
                  />
                  <CategoryButton
                    loading={false}
                    onClick={() => downloadFile(alertImage)}
                    active
                    disabled={false}
                    icon=""
                    title={i18n.t('ALERT.download')}
                  />
                </div>
              </div>
              : <>
                {(!isPlanCanceled && !isPlanWaiting) && <p>{alertMessage || i18n.t('MESSAGES.internalError')}</p>}
                {(isPlanCanceled || isPlanWaiting) && <p>{isPlanWaiting ? i18n.t('ALERT.renovatePlanWaiting') : i18n.t('ALERT.renavatePlan')}</p>}
                {(alertInputPlaceholder && isQuestionInput) && <FormNumberInput
                  type={"text"}
                  placeholder={alertInputPlaceholder}
                  value={inputValue}
                  onValueChange={(value) => setInputValue(value)}
                  containerStyle={{ width: 250 }}
                />}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  {(!isPlanCanceled && !isPlanWaiting) && <CategoryButton
                    loading={false}
                    onClick={() => dispatch(closeAlert())}
                    active
                    disabled={false}
                    icon=""
                    title={props.singleButtonText || (isQuestion || isQuestionInput) ? i18n.t('ALERT.cancel') : i18n.t('ALERT.continue')}
                  />}
                  {(isPlanCanceled || isPlanWaiting) && <CategoryButton
                    loading={false}
                    onClick={() => dispatch(closeAlert())}
                    active={false}
                    disabled={false}
                    icon=""
                    title={i18n.t('ALERT.cancel')}
                  />}
                  {(isPlanCanceled || isPlanWaiting) && <CategoryButton
                    loading={false}
                    onClick={() => {
                      navigate(`${PROFILE_ROUTE}/?section=plans`)
                      dispatch(closeAlert())
                    }}
                    active
                    disabled={false}
                    icon=""
                    title={i18n.t('ALERT.renovate')}
                  />}
                  {(isQuestion || isQuestionInput) && <CategoryButton
                    loading={false}
                    onClick={() => {
                      if (alertOnYes) {
                        dispatch(closeAlert())
                        alertOnYes(inputValue)
                      }
                    }}
                    active
                    disabled={false}
                    icon=""
                    title={alertOnYesTitle}
                  />}

                </div>
              </>}
        </div>
      </div>
    </AlertModal>
  );
};

export default Alert;

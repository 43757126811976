import { gql } from '@apollo/client';


export const UPDATE_PROFILE_PIC_MUTATION = gql`
mutation uploadPhotoUser($file: Upload!) {
  uploadPhotoUser(file: $file){
        fullpath
        path
        status
  }
}
`;

export const UPDATE_PROFILE_MUTATION = gql`
mutation updateProfile($input: UpdateUserInput!) {
  updateProfile(input: $input){
        _id
  }
}
`;

export const EXPORT_INVOICES_MUTATION = gql`
query exportMyInvoices($input: InvoiceFiltersInput) {
  exportMyInvoices(input: $input){
    fullpath
    path
    status
  }
}
`;

export const DOWNLOAD_INVOICE_MUTATION = gql`
query downloadInvoice($invoice_id: ObjectId!) {
  downloadInvoice(invoice_id: $invoice_id){
    fullpath
    path
    status
  }
}
`;

export const CANCEL_USER_SUBSCRIPTION_MUTATION = gql`
mutation cancelSubscription{
  cancelSubscription{
        message
  }
}
`;

export const UPDATE_USER_PLAN_MUTATION = gql`
mutation updateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input){
        message
        payment_intent
        status
  }
}
`;

export const GET_PAYMENT_INTENT_MUTATION = gql`
mutation getPaymentIntent($payment_intent: String!) {
  getPaymentIntent(payment_intent: $payment_intent){
        data
        message
        payment_intent
        status
  }
}
`;

export const GET_COIN_MUTATION = gql`
query findAllCoins {
  findAllCoins {
    _id
    code
    name
    symbol
  }
}
`

export const UPDATE_USER_PAYMENT_METHOD_MUTATION = gql`
mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
  updatePaymentMethod(input: $input){
        message
  }
}
`;

export const GET_USER_PROFILE_MUTATION = gql`
query profile {
  profile {
    _id
      address
      avatar {
        fullpath
        path
      }
      coin
      city
      company
      company_type
      confirmed
      country
      dni
      email
      email_contact
      language
      name
      nif
      payment_variables {
        complete_national
        complete_international
        diets {
          dinner
          dinner_international
          dinner_timetable
          lunch
          lunch_international
          lunch_timetable
          nocturnal
          nocturnal_international
          nocturnal_timetable
          sunday
        }
        fixed_remuneration
        payment_per_km
        user_id
      }
      phone
      plan_details {
        amount
        discount
        end_date
        final_amount
        max_users
        name {
          es
          pt
          fr
          de
        }
        payment_type
        plan_id
        status
        subscription_id
        tax
        }
        position
        rol
        state
        status
        stripe_details {
          account_connect
          bank_account {
            default
            last_digit
            name
            routing_number
            token
          }
          card {
            brand
            default
            expiry
            last_digit
            token
          }
          customer
          method
          payment_intent
          payment_method
        }
      surnames
      zip
    }
  }

`;

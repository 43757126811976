// import * as navigation from '../../routes/navigation';
import client from '../../../utils/api';
import { 
  GET_USERS_INVOICES_MUTATION,
  EXPORT_USERS_INVOICES_MUTATION,
  DOWNLOAD_USER_INVOICE_MUTATION
 } from './billing-queries';
// import {handleAlert} from '../../general/functions';



/**
 * getUsersAction - action for get companies by plans
 *
 * @param {String} user_id - company id
 * @param {Object} data - updateUserAction data
 * @returns {any}
 */
 export const getUsersInvoicesAction = (data, user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_USERS_INVOICES_MUTATION,
        variables: {
          user_id: user_id,
          input: { ...data }
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportUsersInvocesAction - action for get drivers
 *
 * @param {string} company_id - company id
 * @param {object} formData - exportUsersInvocesAction data
 * @returns {any}
 */
 export const exportUsersInvocesAction = (
   formData,
   company_id,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_USERS_INVOICES_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * downloadUserInvoceAction - action for get drivers
 *
 * @param {string} invoice_id - invoice id
 * @returns {any}
 */
 export const downloadUserInvoceAction = (
  invoice_id,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: DOWNLOAD_USER_INVOICE_MUTATION,
        variables: {
          invoice_id: invoice_id
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

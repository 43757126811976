import styled from 'styled-components';
import homeBgImage from "../../assets/home/home-bg-image.png";
import { COLORS_TRUCK } from '../../utils/colors';

export const HomeContainer = styled.div`
width: 100%;
background-image: url(${homeBgImage});
background-repeat: no-repeat;
background-size: cover;

@media (min-width: 800px) {
  height: 90vh;
  }
`;

export const OpacityContainer = styled.div`
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.7);
`;

export const PlanIcon = styled.img`
width: 100px;
height: 100px;
`;

export const PlanUserIcon = styled.img`
width: 20px;
height: 20px;
`;

export const PlansButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
`;

export const PlanItemDescriptionContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

export const PlansContentContainer = styled.div`
// max-width: 800px;
// height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: auto;
// padding-left: 100px;

// @media (min-width: 765px) {
  padding-top: 50px;
  // }
`;

export const PlanTitle = styled.h1`
color: ${COLORS_TRUCK.BLACK_TRUCK};
font-weight: bold;
// font-size: 50px;
margin-bottom: 0;
`;

export const HomeDescription = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
@media (max-width: 500px) {
  text-align: center;
  }
`;

export const ButtonText = styled.p`
font-weight: bold;
color: ${COLORS_TRUCK.WHITE_TRUCK};
padding: 0;
margin: 0;
`;

export const Button = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  background: ${COLORS_TRUCK.ORANGE_TRUCK};
  padding: 12px;
  min-width: 150px;
  margin: 10px;
`;

export const PlanItemContainer = styled.div`
  width: 250px;
  min-height: 400px;
  background: ${COLORS_TRUCK.WHITE_TRUCK};
  border-radius: 10px; 
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
align-items: center;
`;

export const PlansItemsContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
`;

export const PaddingTextContainer = styled.div`
// padding-left: 100px;
// padding-right: 100px;
@media (max-width: 420px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
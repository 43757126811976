// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  FORGOT_PASSWORD_MUTATION,
  CHANGE_PASSWORD_MUTATION,
  VERIFY_TOKEN_MUTATION
} from './forgot-password-queries';
// import {handleAlert} from '../../general/functions';

/**
 * forgotPasswordAction - action for signup
 *
 * @param {string} email - email from account
 * @returns {any}
 */
export const forgotPasswordAction = (
  email,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: FORGOT_PASSWORD_MUTATION,
        variables: {
          type: "ADMIN",
          email
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * changePasswordActions - action for signup
 *
 * @param {string} password - new password
 * @returns {any}
 */
export const changePasswordAction = (
  password,
  code
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CHANGE_PASSWORD_MUTATION,
        variables: {
          password,
          code
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * verifyTokenAction - action for verifying token
 *
 * @returns {any}
 */
export const verifyTokenAction = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: VERIFY_TOKEN_MUTATION,
        variables: {
          code: code,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

import React, { useEffect, useState, useRef } from "react";
import {
  TableTitle,
  TableInfoContainer,
  ChatFilterButtonsContainer,
  ProfileFormSeparator,
  SendIcon,
  MessageInputContainer,
  MessageText,
  Message,
  MessaeTimeText,
  ChatContainer,
  ChatFirstChild,
  ChatSecondChild
} from "./chats-styles";
import i18n from '../../i18n/i18n';
import searchIcon from "../../assets/profile/search-icon.svg";
import sendIcon from "../../assets/chats/right-arrow-white.svg";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import { COLORS_TRUCK } from "../../utils/colors";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import { getDrivers } from "../driver-details/driver-details-actions";
import { useSubscription } from "@apollo/client";
import { GET_MESSAGES_SUBSCRIPTION } from "./chats-queries";
import { getChat, sendMessageAction, readMessagesAction } from "./chats-actions";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ScrollableDivNested from "../../components/scrollable-div/scrollableDivNested";
import ChatsScreen from "./ChatsScreen";
import { CHAT_ROUTE } from "../../routes/routes";
import { FormTextInputMainContainer } from "../../components/inputs/forms/form-text-input/form-text-input-styles";
import { TextInputContainer } from "../../components/inputs/forms/form-select/form-select-styles";

const ChatScreen = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([])
  const [searchMessages, setSearchMessages] = useState("")
  const [message, setMessage] = useState("")
  const dispatch = useAppDispatch()
  const userLogged = useSelector((state) => state).userSlice
  const [loading, setLoading] = useState(false)
  const [limit, setlimit] = useState(10)
  const [messagesConfig, setMessagesConfig] = useState({ limit: limit, offset: 0 })
  const [keepIncreasingMessage, setKeepIncreasingMessage] = useState(true)
  const [driver, setDriver] = useState([])
  const isMounted = React.useRef(true)
  const { driverId } = useParams()
  const messageInputRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(56)
  const inputRef = useRef(null)

  useEffect(() => {
    localStorage.removeItem("numberOfUnreadMessages")
    if (userLogged?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (userLogged?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    (async () => {
      await getDriverFunc()
    })()

    if (driverId) {
      getChatMessages()
    }


    const observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      setBoxHeight(boxElem.contentRect.height)
    });
    observer.observe(messageInputRef.current);

    return () => {
      isMounted.current = false;
    };
  }, [messagesConfig, searchMessages, keepIncreasingMessage])

  useEffect(() => {
    localStorage.removeItem("numberOfUnreadMessages")
    if (userLogged?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (userLogged?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    (async () => {
      await getDriverFunc()
    })()

    if (driverId) {
      setMessages([])
      setMessagesConfig({ limit: limit, offset: 0 })
    }


    const observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      setBoxHeight(boxElem.contentRect.height)
    });
    observer.observe(messageInputRef.current);

    return () => {
      isMounted.current = false;
    };
  }, [driverId])

  useSubscription(GET_MESSAGES_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {

      // console.log("Chat / GET_MESSAGES_SUBSCRIPTION / data: ", data)
      if (error) {
        // console.log("Chat / GET_MESSAGES_SUBSCRIPTION / error: ", error)
      }

      if (driverId &&
        data?.newMessage &&
        data?.newMessage?.from_user_id === driverId) {
        // setMessages([])
        // setMessages([])
        setMessagesConfig({ limit: limit, offset: 0 })
        // getChatMessages(false, data?.newMessage)
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const getDriverFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: -1,
          offset: 0
        },
        driver_id: driverId || null,
        email: null
      }

      const res = await getDrivers(data)

      const driver = res?.data?.myDrivers[0]

      if (!driverId) {
        navigate(`${CHAT_ROUTE}/${driver._id}`)
      }

      setDriver(driver)
      setLoading(false)
      return driver

    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const getChatMessages = async (reset = false, newMessage = null) => {
    if (driverId) {
      // console.log("getChatMessages / searchMessages: ", searchMessages)
      // console.log("getChatMessages / reset: ", reset)
      // console.log("getChatMessages / newMessage: ", newMessage)
      // console.log("getChatMessages / driverId: ", driverId)
      try {
        if (reset) {
          setSearchMessages("")
        }

        const data = {
          message: reset ? null : searchMessages,
          pagination: {
            limit: messagesConfig.limit,
            offset: messagesConfig.offset
          },
          sort: {
            field: "createdAt",
            type: "DESC"
          }
        }
        if (driverId) {
          readMessagesFunc(driverId)
        }

        const res = await getChat(driverId, data)
        if (res?.data?.getChat.length <= 0) {
          setKeepIncreasingMessage(false)
        } else {
          setKeepIncreasingMessage(true)
        }

        if (res?.data?.getChat.length > 0) {
          if (!searchMessages) {
            let messagesArray = reset ? res?.data?.getChat : [...messages].concat(res?.data?.getChat)

            if (newMessage) {
              messagesArray = messagesArray.concat(newMessage)
            }

            const finalMessages = messagesArray
              // .map(item => ({ ...item, from_user_name: driver.name }))
              .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
              // .filter(message => (message.to_user_id === userLogged._id && message.from_user_id === driverId) || (message.from_user_id === userLogged._id && message.to_user_id === driverId))
              .sort((a, b) => moment(b.datetime) - moment(a.datetime))

            setMessages(finalMessages)

            // scrollChatToBottom()
          } else {
            let messagesArray = [...res?.data?.getChat]

            if (newMessage) {
              messagesArray = messagesArray.concat(newMessage)
            }

            const finalMessages = messagesArray
              // .map(item => ({ ...item, from_user_name: driver?.name }))
              .sort((a, b) => moment(b.datetime) - moment(a.datetime))

            setMessages(finalMessages)
            // scrollChatToBottom()
          }
        }


      } catch (e) {
        // console.log("get messages error: ", e)
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true
        }))
      }
    }
  }

  const readMessagesFunc = async (driverId) => {
    try {

      await readMessagesAction(driverId)

    } catch (e) {
      // console.log("readMessagesFunc / error: ", e)
      // dispatch(openAlert({
      //   alertType: "error",
      //   isAlertOpen: true
      // }))
    }
  }

  const sendMessage = async () => {
    try {
      setLoading(true)
      const data = {
        to_user_id: driverId,
        message: message
      }

      await sendMessageAction(data)
      setMessage("")
      // console.log("inputRef.current: ", inputRef)
      // await getChatMessages()
      setLoading(false)
      setTimeout(() => {
        inputRef.current.focus()
        setMessagesConfig({ limit: limit, offset: 0 })
      }, 1000)
    } catch (e) {
      // console.log("sendMessage / error: ", e)
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && driverId && message) {
      event.preventDefault();
      sendMessage()
    }
  };

  const handleKeyPressMessageFilter = (event) => {
    if (event.key === 'Enter' && driverId) {
      event.preventDefault();
      getChatMessages()
    }
  };

  const onEndReachMessages = () => {
    // console.log("onEndReachMessages / messagesConfig: ", messagesConfig)
    // console.log("onEndReachMessages / keepIncreasingMessage: ", keepIncreasingMessage)
    if (keepIncreasingMessage) {
      // console.log("entrose")
      setMessagesConfig((prevState) => {
        return {
          limit: prevState.limit + limit,
          offset: prevState.offset + limit
        }
      })
    }

  }

  // console.log("driver: ", driver)
  // console.log("messages: ", messages)
  // console.log("keepIncreasingMessage: ", keepIncreasingMessage)
  // console.log("render / messagesConfig: ", messagesConfig)
  return (
    <ChatContainer>
      <ChatFirstChild>
        <ChatFilterButtonsContainer>
          <TableInfoContainer>
            <TableTitle>
              {`${i18n.t('CHATS.title')} ${driver?.name || "..."}`}
            </TableTitle>
          </TableInfoContainer>
          <FormTextInput
            placeholder={i18n.t('CHATS.searchInputPlaceholder')}
            type={"text"}
            onKeyPress={handleKeyPressMessageFilter}
            value={searchMessages}
            onChange={({ target: { value } }) => {
              setMessagesConfig({ limit: limit, offset: 0 })
              setSearchMessages(value)
            }}
            required
            icon={searchIcon}
          />
        </ChatFilterButtonsContainer>
        <ProfileFormSeparator />
        <ScrollableDivNested
          reversed
          style={{
            height: `calc(83vh - ${100 + boxHeight}px)`,
            position: 'relative',
          }}
          onEndReach={() => onEndReachMessages()}
        >
          {messages.map((message, index) => {
            const isOwnMessage = message.from_user_id === userLogged._id
            return (
              <Message
                style={{
                  border: isOwnMessage ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : `1px solid rgb(48, 37, 33, 0.1)`,
                  alignSelf: isOwnMessage ? "flex-end" : "flex-start"
                }}
                key={index}
              >
                <MessageText>{message.message}</MessageText>
                <MessaeTimeText>{moment(message.datetime).startOf('minute').fromNow()}</MessaeTimeText>
              </Message>
            )
          })}
        </ScrollableDivNested>
        <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 10px', paddingTop: '10px', position: 'relative' }}>
          <MessageInputContainer ref={messageInputRef}>
            <FormTextInputMainContainer style={{ width: "80%" }}>
              <TextInputContainer style={Object.assign({ border: ` 1px solid rgba(0, 0, 0, 0.23)` }, { maxWidth: '100%', width: "100%" })}>
                <input
                  ref={inputRef}
                  placeholder={i18n.t('CHATS.chatInputPlaceholder')}
                  type={"text"}
                  value={message}
                  onKeyPress={handleKeyPress}
                  // disabled={loading}
                  required
                  onChange={({ target: { value } }) => {
                    setMessage(value)
                  }}
                  autocomplete="false"
                  autoComplete="off"
                  onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                  }}
                  style={{
                    backgroundColor: "transparent",
                    width: "90%",
                    height: "90%",
                    border: "none",
                    fontSize: 15,
                    color: COLORS_TRUCK.BLACK_TRUCK,
                    margin: 0,
                    padding: 0,
                    paddingLeft: 15
                  }}
                />
              </TextInputContainer>
            </FormTextInputMainContainer>
            <SendIcon
              onClick={() => driverId && message && !loading ? sendMessage() : null}
              src={sendIcon}
            />
          </MessageInputContainer>
        </div>
      </ChatFirstChild>
      <ChatSecondChild>
        <ChatsScreen
          chatDriverId={driverId}
        />
      </ChatSecondChild>
    </ChatContainer>
  )
}

export default ChatScreen
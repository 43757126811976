import React from "react";
import {
  TextInputContainer,
  InputIcon,
  InputTitle,
  FormTextInputMainContainer
} from "./form-date-input-styles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { COLORS_TRUCK } from "../../../../utils/colors";
import {es, fr, it, pt, de, enUS} from 'date-fns/locale'

const FormDateInput = (props) => {

  const selectLang = (lang) => {
    switch(lang) {
      case "es":
       return es
        break;
      case "en":
       return enUS
        break;
      case "de":
       return de
        break;
      case "fr":
       return fr
        break;
      case "it":
       return it
        break;
      case "pt":
       return pt
        break;
      default:
        // code block
    }
  }
  return (
    <FormTextInputMainContainer style={props.fatherContainer}>
      {props.title ? <InputTitle>
        {props.title}{props.required ? <span style={{ fontSize: 20 }}>{` *`}</span> : ""}
      </InputTitle>
        : null}
      {props.leftComponent ? props.leftComponent : null}
      <TextInputContainer style={Object.assign({ border: `1px solid ${props.showError ? COLORS_TRUCK.ORANGE_TRUCK : `rgba(0, 0, 0, 0.23)`}` }, props.containerStyle)}>
        {props.icon && <InputIcon src={props.icon} />}
        <LocalizationProvider locale={selectLang(localStorage.getItem("language"))} dateAdapter={AdapterDateFns}>
          <DatePicker
            {...props}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy"
          />
        </LocalizationProvider>
        {props.rightIcon && <InputIcon
          onClick={props.iconRightClick}
          style={{ cursor: 'pointer' }}
          src={props.rightIcon}
        />}
        {props.rightComponent ? props.rightComponent : null}
      </TextInputContainer>
    </FormTextInputMainContainer>
  )
}

export default FormDateInput
import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const BackText = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
font-wight: bold;
`;

export const BackArrowIcon = styled.img`
width: 20px;
height: 20px;
margin-right: 5px;
`;

export const BackButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`;
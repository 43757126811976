import { gql } from '@apollo/client';

export const FORGOT_PASSWORD_MUTATION = gql`
mutation forgotPassword($type: RolesEnum!, $email: String!) {
  forgotPassword(type: $type, email: $email){
    message
    status
  }
}
`;

export const CHANGE_PASSWORD_MUTATION = gql`
mutation changePassword($code: String!, $password: String!) {
  changePassword(code: $code, password: $password){
    message
    status
  }
}
`;

export const VERIFY_TOKEN_MUTATION = gql`
mutation verifyPasswordCode($code: String!) {
  verifyPasswordCode(code: $code){
   result
  }
}
`;

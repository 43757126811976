import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileFormContainer,
  ProfileFormInputsContainer,
  ProfileFormsContainer,
  ProfileLastButtonsContainer,
  ProfileFormSeparator,
  GeneralErrorContainer,
  GeneralErrorText
} from "./admin-details-styles";
import i18n from '../../../i18n/i18n';
import CategoryButton from "../../../components/buttons/category-button/CategoryButton";
import userPic from "../../../assets/driver-details/driver-pic.png";
import UserCard from "../../../components/user-card/UserCard";
import FormTextInput from "../../../components/inputs/forms/form-text-input/FormTextInput";
import passwordIcon from "../../../assets/inputs/lock.svg";
import offEye from "../../../assets/inputs/eye-off.svg";
import redEye from "../../../assets/general/eye-icon-red.svg";
import { getUserProfile, updateProfileAction, updateUserPicAction } from "../../profile/profile-actions";
import { useAppDispatch } from "../../../redux/store.ts";
import { openAlert } from "../../../redux/reducers/app.ts";
import { setUser } from "../../../redux/reducers/user.ts";
import { useSelector } from "react-redux";
import { emailsRegex } from "../../../utils/regex";
import avatarDefault from "../../../assets/drivers/user.png";

const AdminDetailsScreen = () => {

  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [name, setName] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [secondPasswordShow, setSecondPasswordShow] = useState("")
  const [loading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState("")
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [passwordError, setPasswordError] = useState("")
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState("")

  useEffect(() => {
    getUserData()
  }, [])

  const saveProfile = async () => {
    const passwordValidation = password && password.length < 8
    const passwordMatchValidation = password && password !== confirmPassword
    const emailValidation = email && !email.match(emailsRegex)

    if (!name || !lastname || !email) {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (passwordValidation) {
      setShowInputsErrors(true)
      setPasswordError(i18n.t("GENERAL.passwordLength"))
      return
    }

    if (passwordMatchValidation) {
      setShowInputsErrors(true)
      setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
      return
    }

    if (emailValidation) {
      setShowInputsErrors(true)
      setContactEmailErrorMessage(i18n.t("PROFILE.emailValidation"))
      return
    }

    if (!passwordValidation && !passwordMatchValidation && !emailValidation) {
      setShowInputsErrors(false)
      setContactEmailErrorMessage("")
      setPasswordError("")
    }


    if (name &&
      lastname &&
      email) {
      setGeneralError("")
      setShowInputsErrors(false)
    }

    try {
      setLoading(true)
      let data = {
        name: name || null,
        email_contact: email || null,
        surnames: lastname || null,
      }

      if (password && password === confirmPassword) {
        data.password = password
      }

      await updateProfileAction(data)
      await getUserData()
      setLoading(false)

      dispatch(openAlert({
        alertType: "success",
        alertMessage: i18n.t("PROFILE.updatedSuccessMessage"),
        isAlertOpen: true
      }))
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getUserData = async () => {
    try {

      const res = await getUserProfile()
      const user = res.data.profile
      setEmail(user.email)
      setName(user.name)
      setLastname(user.surnames)
      dispatch(setUser({
        ...user
      }))
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const onEditPicture = async (img) => {
    try {
      const res = await updateUserPicAction(img)
      getUserData()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <UserCard
            user={{
              name: name,
              position: "Truck Driver - SUPER ADMINISTRADOR",
              picture: user?.avatar?.fullpath || avatarDefault
            }}
            onEditPicture={onEditPicture}
            borders={false}
          />
          <ProfileFormsContainer>
            <ProfileFormInputsContainer>
              <FormTextInput
                title={i18n.t('SUPER_ADMIN_ADMINISTRATION.name')}
                type={"text"}
                value={name}
                onChange={({ target: { value } }) => setName(value)}
                allowJustLetters
                disabled={loading}
                required
                showError={!name && showInputsErrors}
              />
              <FormTextInput
                title={i18n.t('SUPER_ADMIN_ADMINISTRATION.lastnames')}
                type={"text"}
                value={lastname}
                onChange={({ target: { value } }) => setLastname(value)}
                allowJustLetters
                disabled={loading}
                required
                showError={!lastname && showInputsErrors}
              />
              <FormTextInput
                title={i18n.t('SUPER_ADMIN_ADMINISTRATION.email')}
                type={"email"}
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
                disabled={loading}
                required
                errorMessage={contactEmailErrorMessage}
                showError={!email && showInputsErrors}
              />
              
            </ProfileFormInputsContainer>
            <ProfileFormInputsContainer>
              <FormTextInput
                title={i18n.t('SUPER_ADMIN_ADMINISTRATION.password')}
                iconRightClick={() => setPasswordShow(!passwordShow)}
                rightIcon={passwordShow ? offEye : redEye}
                icon={passwordIcon}
                type={passwordShow ? "text" : "password"}
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                disabled={loading}
                // required
                showError={showInputsErrors}
                errorMessage={passwordError}
              />
              <FormTextInput
                title={i18n.t('SUPER_ADMIN_ADMINISTRATION.confirmPassword')}
                iconRightClick={() => setSecondPasswordShow(!secondPasswordShow)}
                rightIcon={secondPasswordShow ? offEye : redEye}
                icon={passwordIcon}
                type={secondPasswordShow ? "text" : "password"}
                value={confirmPassword}
                onChange={({ target: { value } }) => setConfirmPassword(value)}
                disabled={loading}
                showError={showInputsErrors}
                errorMessage={passwordError}
              />
            </ProfileFormInputsContainer>
            {generalError && <GeneralErrorContainer>
              <GeneralErrorText>{generalError}</GeneralErrorText>
            </GeneralErrorContainer>}
            <ProfileFormSeparator />
            <ProfileLastButtonsContainer>
              <CategoryButton
                onClick={() => saveProfile()}
                active
                icon=""
                title={i18n.t('SUPER_ADMIN_ADMINISTRATION.save')}
                loading={loading}
              />
            </ProfileLastButtonsContainer>
          </ProfileFormsContainer>
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default AdminDetailsScreen
import React from "react";
import { Oval } from 'react-loader-spinner'
import { COLORS_TRUCK } from '../../utils/colors';

const Loader = ({ color, height, width }) =>
  <Oval
    secondaryColor="transparent"
    color={color || COLORS_TRUCK.WHITE_TRUCK}
    height={height || 30}
    width={width || 30}
  />

export default Loader;

// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  CREATE_ISSUE_MUTATION,
  GET_ISSUES_MUTATION,
  UPDATE_ISSUE_MUTATION,
  REMOVE_ISSUE_MUTATION,
  EXPORT_ISSUES_MUTATION,
  UPDATE_ISSUE_PICTURE_MUTATION
} from './issues-queries';
// import {handleAlert} from '../../general/functions';

/**
 * createIssueAction - action for create an issue
 *
 * @param {array} files - createIssueAction files array
 * @param {object} formData - createIssueAction data
 * @returns {any}
 */
export const createIssueAction = (
  files,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CREATE_ISSUE_MUTATION,
        variables: {
          files: files,
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getIssuesAction - action for get drivers
 *
 * @param {object} formData - getIssuesAction data
 * @returns {any}
 */
 export const getIssuesAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_ISSUES_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeIssueAction - action for remove an issue
 *
 * @param {string} issueId - issue id
 * @returns {any}
 */
export const removeIssueAction = (
  issueId,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);

      const response = await client.mutate({
        mutation: REMOVE_ISSUE_MUTATION,
        variables: {
          incident_id: issueId,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateIssueAction - action for update a driver
 *
 * @param {String} issue_id - issue id
 * @param {object} formData - updateIssueAction data
 * @returns {any}
 */
export const updateIssueAction = (
  issue_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_ISSUE_MUTATION,
        variables: {
          input: {
            ...formData
          },
          incident_id: issue_id,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateIssuePicture - action for update a driver
 *
 * @param {String} pictureId - pciture id
 * @param {object} picture - picture obj
 * @returns {any}
 */
export const updateIssuePicture = (
  pictureId,
  picture,
  issueId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_ISSUE_PICTURE_MUTATION,
        variables: {
          file: picture,
          input: {
           object_id: pictureId,
           parent_id: issueId
          }
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateIssueAction - action for update a driver
 *
 * @param {String} issue_id - issue id
 * @param {boolean} value - status value data
 * @returns {any}
 */
export const updateIssueStatusAction = (
  issue_id,
  value,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_ISSUE_MUTATION,
        variables: {
          input: {
            status: value
          },
          incident_id: issue_id,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};


/**
 * exportTasks - action for get drivers
 *
 * @param {object} formData - exportTasks data
 * @returns {any}
 */
 export const exportIssues = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_ISSUES_MUTATION,
        variables: {
          input: {
            ...formData
          },
          // company_id: adminId,
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
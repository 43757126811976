// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  UPDATE_PROFILE_PIC_MUTATION,
  GET_USER_PROFILE_MUTATION,
  UPDATE_PROFILE_MUTATION,
  UPDATE_USER_PLAN_MUTATION,
  CANCEL_USER_SUBSCRIPTION_MUTATION,
  EXPORT_INVOICES_MUTATION,
  DOWNLOAD_INVOICE_MUTATION,
  UPDATE_USER_PAYMENT_METHOD_MUTATION,
  GET_PAYMENT_INTENT_MUTATION,
  GET_COIN_MUTATION
} from './profile-queries';
// import {handleAlert} from '../../general/functions';

/**
 * updateUserPicAction - action for create a driver
 *
 * @param {File} file - updateUserPicAction file
 * @returns {any}
 */
export const updateUserPicAction = (
  file,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_PROFILE_PIC_MUTATION,
        variables: {
          file: file,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * updateUserPlanAction - action for create a driver
 *
 * @param {Object} data - updateUserPlanAction data
 * @returns {any}
 */
export const updateUserPlanAction = (
  data,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_USER_PLAN_MUTATION,
        variables: {
          input: data,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("updateUserPlanAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

export const getCoinAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: GET_COIN_MUTATION,
        variables: {},
      });
      resolve(response)
      
    } catch (error) {
      reject(error)
    }
  })
} 
/**
 * getPaymentIntentAction - action for create a driver
 *
 * @param {String} payment_intent - payment intent id
 * @returns {any}
 */
export const getPaymentIntentAction = (
  payment_intent,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getPaymentIntentAction / payment_intent: ", payment_intent)
      const response = await client.mutate({
        mutation: GET_PAYMENT_INTENT_MUTATION,
        variables: {
          payment_intent: payment_intent,
        },
      });
      resolve(response)
      // console.log("getPaymentIntentAction / response: ", response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("getPaymentIntentAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * exportInvoices - action for create a driver
 *
 * @param {Object} data - exportInvoices data
 * @returns {any}
 */
export const exportInvoices = (
  data,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_INVOICES_MUTATION,
        variables: {
          input: data,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * downloadInvoice - action for create a driver
 *
 * @param {String} invoice_id - invoice id
 * @returns {any}
 */
export const downloadInvoice = (
  invoice_id,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: DOWNLOAD_INVOICE_MUTATION,
        variables: {
          invoice_id: invoice_id,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * cancelSubscriptionAction - action for create a driver
 *
 * @param {Object} data - cancelSubscriptionAction data
 * @returns {any}
 */
export const cancelSubscriptionAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CANCEL_USER_SUBSCRIPTION_MUTATION,
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateProfileAction - action for create a driver
 *
 * @param {Object} formData - updateProfileAction data
 * @returns {any}
 */
export const updateProfileAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          input: formData,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateUserPaymentMethodAction - action for create a driver
 *
 * @param {Object} formData - updateUserPaymentMethodAction data
 * @returns {any}
 */
export const updateUserPaymentMethodAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_USER_PAYMENT_METHOD_MUTATION,
        variables: {
          input: formData,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};


/**
 * getUserProfile - action for get drivers
 *
 * @returns {any}
 */
 export const getUserProfile = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_USER_PROFILE_MUTATION,
        // variables: {
        //   input: {
        //     ...formData
        //   },
        // },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
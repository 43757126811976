// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  CREATE_TASK_MUTATION,
  REMOVE_TASK_MUTATION,
  UPDATE_TASK_MUTATION,
  GET_TASKS_MUTATION,
  EXPORT_TASKS_CSV_MUTATION,
  EXPORT_TASKS_EXCEL_MUTATION,
  IMPORT_TASKS_MUTATION,
  REMOVE_TASK_LOAD_MUTATION,
  CREATE_TASK_LOAD_MUTATION,
  REMOVE_TASK_DOWNLOAD_MUTATION,
  CREATE_TASK_DOWNLOAD_MUTATION,
  UPDATE_TASK_LOAD_MUTATION,
  UPDATE_TASK_DOWNLOAD_MUTATION,
  GET_TASKS_STATUSES_MUTATION,
  GET_TASK_IMAGES_MUTATION
} from './tasks-queries';

/**
 * createTaskAction - action for create a driver
 *
 * @param {object} formData - createTaskAction data
 * @returns {any}
 */
export const createTaskAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("createTaskAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CREATE_TASK_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      // console.log("createTaskAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("createTaskAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * addLoadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {object} formData - addLoadAction data
 * @returns {any}
 */
export const addLoadAction = (
  taskId,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("addLoadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CREATE_TASK_LOAD_MUTATION,
        variables: {
          input: [{
            ...formData
          }],
          task_id: taskId,
        },
      });
      // console.log("addLoadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("addLoadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * addDownloadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {object} formData - addDownloadAction data
 * @returns {any}
 */
export const addDownloadAction = (
  taskId,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("addDownloadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CREATE_TASK_DOWNLOAD_MUTATION,
        variables: {
          input: [{
            ...formData
          }],
          task_id: taskId,
        },
      });
      // console.log("addDownloadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
        // console.log("addDownloadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeTaskAction - action for create a driver
 *
 * @param {string} vehicleId - vehicle id
 * @returns {any}
 */
export const removeTaskAction = (
  taskId,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_TASK_MUTATION,
        variables: {
          task_id: taskId,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeLoadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {string} loadId - load id
 * @returns {any}
 */
export const removeLoadAction = (
  taskId,
  loadId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_TASK_LOAD_MUTATION,
        variables: {
          input: {
            object_id: loadId,
            parent_id: taskId,
          }
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeDownloadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {string} downloadId - download id
 * @returns {any}
 */
export const removeDownloadAction = (
  taskId,
  downloadId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("removeDownloadAction / taskId: ", taskId)
      // console.log("removeDownloadAction / downloadId: ", downloadId)
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_TASK_DOWNLOAD_MUTATION,
        variables: {
          input: {
            object_id: downloadId,
            parent_id: taskId,
          }
        },
      });
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("removeDownloadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateTaskAction - action for update a driver
 *
 * @param {String} task_id - vehicle id
 * @param {object} formData - updateTaskAction data
 * @returns {any}
 */
export const updateTaskAction = (
  task_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("updateTaskLoadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: UPDATE_TASK_MUTATION,
        variables: {
          input: {
            ...formData
          },
          task_id: task_id,
        },
      });
      // console.log("updateTaskLoadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("updateTaskLoadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateTaskLoadAction - action for update a driver
 *
 * @param {object} formData - updateTaskLoadAction data
 * @returns {any}
 */
export const updateTaskLoadAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("updateTaskLoadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: UPDATE_TASK_LOAD_MUTATION,
        variables: {
          input: {
            ...formData
          }
        },
      });
      // console.log("updateTaskLoadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("updateTaskLoadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateTaskdownloadAction - action for update a driver
 *
 * @param {object} formData - updateTaskdownloadAction data
 * @returns {any}
 */
export const updateTaskdownloadAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("updateTaskdownloadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: UPDATE_TASK_DOWNLOAD_MUTATION,
        variables: {
          input: {
            ...formData
          }
        },
      });
      // console.log("updateTaskdownloadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("updateTaskdownloadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getTasksAction - action for get drivers
 *
 * @param {object} formData - getTasksAction data
 * @returns {any}
 */
export const getTasksAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getTasksAction / formData: ", formData)
      const response = await client.query({
        query: GET_TASKS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getTasksAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getTasksAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * getTaskImagesAction - action for get drivers
 *
 * @param {object} formData - getTaskImagesAction data
 * @returns {any}
 */
export const getTaskImagesAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getTaskImagesAction / formData: ", formData)
      const response = await client.query({
        query: GET_TASK_IMAGES_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getTaskImagesAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getTaskImagesAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getTasksStatusesAction - action for get tasks statuses
 *
 * @returns {any}
 */
export const getTasksStatusesAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_TASKS_STATUSES_MUTATION,
        variables: {
          name: ""
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getTasksStatusesAction / response: ", response)
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportTasks - action for get drivers
 *
 * @param {object} formData - exportTasks data
 * @returns {any}
 */
export const exportTasks = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("exportTasks / formData: ", formData)
      const response = await client.query({
        query: EXPORT_TASKS_CSV_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("exportTasks / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

export const exportTasksExcel = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.query({ 
        query: EXPORT_TASKS_EXCEL_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};

/**
 * importTasks - Import task for platform
 *
 * @param {File} file - importTasks data
 * @returns {any}
 */
export const importTasks = (
  file,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: IMPORT_TASKS_MUTATION,
        variables: {
          file: file,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileFormContainer,
  ProfileBillingContainer,
  TableTitle,
  TableInfoContainer
} from "./clients-styles";
import i18n from '../../../i18n/i18n';
import CategoryButton from "../../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../../assets/profile/search-icon.svg";
import TruckTable from "../../../components/truck-table/TruckTable";
import { exportUsersAction, getUsersAction } from "./clients-actions";
import TextInput from "../../../components/inputs/text-input/TextInput";
import { openAlert } from "../../../redux/reducers/app.ts";
import { useAppDispatch } from "../../../redux/store.ts";
import { useNavigate } from "react-router-dom";
import { CLIENT_DETAILS_ROUTE } from "../../../routes/routes";

const ClientsScreen = () => {
  const navigate = useNavigate()
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [search, setSearch] = useState("")
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [filterBy, setFilterBy] = useState("1")
  const [status, setStatus] = useState("")
  const dispatch = useAppDispatch()

  useEffect(() => {
    getUsersFunc()
  }, [search, filterBy])

  const getUsersFunc = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        status: status || null,
        email: search || null
      }
      const res = await getUsersAction(data)
      if (res?.data?.users.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let usersArray = users.concat(res?.data?.users)
      if (!search && !reset) {
        setUsers(usersArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        setUsers(res?.data?.users)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") {
        setUsers([])
      }
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setUsers([])
      getUsersFunc()
    }
  };

  const exportUsersFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        status: status || null,
        email: search || null
      }
      const res = await exportUsersAction(data)
      var win = window.open(res.data.exportUsers.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const header = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('CLIENTS.forms.clients.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={filterBy}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        if (value === "ACTIVE" || value === "INACTIVE") {
          setUsers([])
          setStatus(value)
          setFilterBy(value)
          // getVehiclesFunc()
          setSearch("")
        }
        if (value === "" || value === "1") {
          setUsers([])
          setStatus("")
          setFilterBy("1")
          setSearch("")
          // getVehiclesFunc()
        }
      }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('CLIENTS.forms.clients.buttons.filterBy'), icon: filterByIcon },
        { value: 'ACTIVE', label: i18n.t('CLIENTS.forms.clients.buttons.active'), icon: filterByIcon },
        { value: 'INACTIVE', label: i18n.t('CLIENTS.forms.clients.buttons.inactive'), icon: filterByIcon },
      ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('CLIENTS.forms.clients.buttons.search')}
      onChange={async (e) => {
        if (e.target.value === "") {
          getUsersFunc(true)
        }

        setSearch(e.target.value)

      }}
      onKeyPress={handleKeyPress}
      // disabled={loading}
    />
    {/* <FormSelect
      value={billingFilterBy}
      containerStyle={{ width: 220 }}
      onChange={({ target: { value } }) => setBillingFilterBy(value)}
      icon={searchIcon}
      items={[
        { value: '1', label: i18n.t('CLIENTS.forms.clients.buttons.search'), icon: searchIcon },
      ]}
      required
    /> */}
    <CategoryButton
      onClick={() => exportUsersFunc()}
      active={false}
      icon=""
      title={i18n.t('CLIENTS.forms.clients.buttons.export')}
    />
    <CategoryButton
      onClick={() => navigate(CLIENT_DETAILS_ROUTE)}
      active
      icon=""
      title={i18n.t('CLIENTS.forms.clients.buttons.add')}
    />
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="clients"
            header={header}
            titles={[
              { label: i18n.t('CLIENTS.forms.clients.picture') },
              { label: i18n.t('CLIENTS.forms.clients.company') },
              { label: i18n.t('CLIENTS.forms.clients.name') },
              { label: i18n.t('CLIENTS.forms.clients.lastname') },
              { label: i18n.t('CLIENTS.forms.clients.email') },
              { label: i18n.t('CLIENTS.forms.clients.plan') },
              { label: i18n.t('CLIENTS.forms.clients.state') },
              { label: i18n.t('CLIENTS.forms.clients.actions') }
            ]}
            data={users}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(offset + increaseLimit)
                setLimit(limit + increaseLimit)
              }

              getUsersFunc()
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default ClientsScreen
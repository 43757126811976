import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileButtonsContainer,
  ProfileFormContainer,
  ProfileFormInputsContainer,
  ProfileFormsContainer,
  ProfileLastButtonsContainer,
  ProfileFormSeparator,
  TableTitle,
  TableInfoContainer,
  DatesTaskInfoContainer,
  DropdownContainer,
  DropdownHeader,
  MenuIcon,
  DownArrowIcon,
  RemoveIcon,
  GeneralErrorContainer,
  GeneralErrorText,
  InputTitle
} from "./tasks-details-styles";
import i18n from '../../i18n/i18n';
import { useNavigate, useParams } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import redPinIcon from "../../assets/home/red-pin.svg";
import MenuButton from "../../assets/home-header/menu-icon-white.svg";
import DownArrowWhite from "../../assets/general/down-arrow.svg";
import removeIcon from "../../assets/general/remove-icon.svg";
import { getDrivers } from "../driver-details/driver-details-actions";
import { getVehiclesAction } from "../vehicles/vehicles-actions";
import {
  createTaskAction,
  getTasksAction,
  updateTaskAction,
  removeLoadAction,
  addLoadAction,
  removeDownloadAction,
  addDownloadAction,
  updateTaskLoadAction,
  updateTaskdownloadAction,
  getTasksStatusesAction,
} from "./tasks-actions";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { TASKS_ROUTE, TASK_DETAILS_ROUTE } from "../../routes/routes";
import moment from "moment-timezone";
import IssuesScreen from "../issues/IssuesScreen";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import FormMultiSelect from "../../components/inputs/forms/form-multi-select/FormMultiSelect";
import config from "../../config";
import ReactSelect from "react-select";
import { hoursArray } from "./taskHours.js";
import { colourStyles } from "./selectTagsStyles.js";

const DropdownLayer = ({ children, index, onRemoveForm, loading, isOpen, toggleLayer }) => {

  return (
    <DropdownContainer>
      <DropdownHeader onClick={toggleLayer}>
        <MenuIcon src={MenuButton} />
        <DownArrowIcon src={DownArrowWhite} />
      </DropdownHeader>
      {(onRemoveForm && !loading) && <RemoveIcon onClick={onRemoveForm} src={removeIcon} />}
      {isOpen ? children : null}
    </DropdownContainer>
  )
}

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const TasksDetailsScreen = () => {
  const [currentForm, setCurrentForm] = useState("info")
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [isInProcess, setIsInProcess] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [taskStatuses, setTaskStatuses] = useState([])
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { taskId } = useParams()
  const [generalError, setGeneralError] = useState("")
  const [loading, setLoading] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [taskImages, setTaskImages] = useState([])
  const [inputValue, setInputValue] = useState('')

  const redIcon = divIcon({
    html: renderToStaticMarkup(<img alt="" src={redPinIcon} />),
  })

  //info
  const [taskInfoState, setTaskInfoState] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [enrollmentVehicle, setEnrollmentVehicle] = useState("")
  const [enrollmentTrailer, setEnrollmentTrailer] = useState("")
  const [taskInfoName, setTaskInfoName] = useState("")
  const [driver, setDriver] = useState("")
  //load
  const [loads, setLoads] = useState([
    {
      place: "",
      phone: "",
      date: "",
      timetable: "",
      ref: "",
      address: "",
      lat: "",
      long: "",
      comments: "",
      coords: "",
      object_id: "",
      isOpen: false,
      unloads_ids: [],
      email: []
    }
  ])
  //download
  const [downloads, setDownloads] = useState([
    {
      place: "",
      phone: "",
      date: "",
      timetable: "",
      ref: "",
      address: "",
      lat: "",
      long: "",
      comments: "",
      coords: "",
      object_id: "",
      isOpen: false,
      loads_ids: []
    }
  ])

  useEffect(() => {
    (async () => {
      await getFormAuxDataFunc()
    })()

    if (taskId) {
      getTaskInfo()
    } else {
      setIsCreate(true)
    }

    getTaskStatusesFunc()
  }, [])

  const getTaskInfo = async () => {
    try {
      setLoading(true)
      const data = {
        task_id: taskId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const res = await getTasksAction(data)

      const task = res?.data?.myTasks.filter(task => String(task._id) === String(taskId))[0]

      if (task) {
        if (task?.images?.length > 0)  setTaskImages(task?.images)

        setStartDate(task?.start_date)
        setEndDate(task?.end_date)
        setTaskInfoState(task?.status.toUpperCase())
        setIsFinished(task?.status.toUpperCase() === "FINISHED")
        setIsInProcess(task?.status.toUpperCase() === "PROCESS")
        setIsDraft(task?.status.toUpperCase() === "DRAFT")
        setDriver(task?.driver_id)
        setEnrollmentVehicle(task?.vehicle_id)
        setEnrollmentTrailer(task?.trailer_id)
        setTaskInfoName(task?.name)
        setLoads(task?.loads?.length > 0 ? task?.loads.map((load, index) => {
          return { ...load, email: load.email.map(item => { return { label: item, value: item } }), isOpen: index === 0 ? true : false }
        }) : [
          {
            place: "",
            phone: "",
            date: "",
            timetable: "",
            ref: "",
            address: "",
            lat: "",
            long: "",
            comments: "",
            coords: "",
            object_id: "",
            unloads_ids: [],
            isOpen: true,
            email: []
          }
        ])
        setDownloads(task?.unloads?.length > 0 ? task?.unloads.map((unload, index) => ({
          ...unload,
          email: unload.email.map(item => { return { label: item, value: item } }),
          loads_ids: unload.loads_ids?.map((loadId, index) => {
            if (task?.loads.filter(load => load._id === loadId).length > 0) {
              const selectedLoad = task?.loads.filter(load => load._id === loadId)[0]
              return {
                value: loadId,
                label: `${selectedLoad.place}`
              }
            }
          }),
          isOpen: index === 0 ? true : false
        })) : [
          {
            place: "",
            phone: "",
            date: "",
            timetable: "",
            ref: "",
            address: "",
            lat: "",
            long: "",
            comments: "",
            coords: "",
            object_id: "",
            isOpen: true,
            loads_ids: [],
            email: []
          }
        ])

        if (task?.loads.length <= 0) {
          setCurrentForm("load")
          return
        }
        if (task?.unloads.length <= 0) setCurrentForm("download")
      }
      else {
        navigate(TASKS_ROUTE)
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      navigate(TASKS_ROUTE)
    }
  }

  const setLoadForm = (value, name, index) => {
    setLoads((prevState) => {
      let newLoads = [...prevState]
      let objectCopy = { ...newLoads[index] }
      objectCopy[name] = value
      newLoads[index] = objectCopy
      return newLoads
    })
  }

  const setLoadFormValues = (values, index) => {
    setLoads((prevState) => {
      let newLoads = [...prevState]
      let objectCopy = { ...newLoads[index] }
      values.forEach(value => {
        objectCopy[value.name] = value.value
      })
      newLoads[index] = objectCopy

      return newLoads
    });
  }

  const setDownloadForm = (value, name, index) => {
    setDownloads((prevState) => {
      let newDownloads = [...prevState]
      let objectCopy = { ...newDownloads[index] }
      objectCopy[name] = value
      newDownloads[index] = objectCopy

      return newDownloads
    })
  }

  const setDownloadFormValues = (values, index) => {
    let newDownloads = [...downloads]
    let objectCopy = { ...newDownloads[index] }

    values.forEach(value => {
      objectCopy[value.name] = value.value
    })

    newDownloads[index] = objectCopy
    setDownloads(newDownloads)
  }

  const removeLoad = async (index, loadId) => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("TASKS.areYouSureToRemoveLoad"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          if (loadId && taskId) {
            await removeLoadAction(taskId, loadId)
            dispatch(openAlert({
              alertType: "success",
              alertMessage: i18n.t("TASKS.removedLoadSuccessMessage"),
              isAlertOpen: true
            }))
          }

          const newLoads = [...loads]
          newLoads.splice(index, 1)
          setLoads(newLoads)
          if (taskId) {
            getTaskInfo()
          }
        } catch (e) {
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const removeDownLoad = async (index, downloadId) => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("TASKS.areYouSureToRemoveDownload"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          if (downloadId) {
            await removeDownloadAction(taskId, downloadId)
            dispatch(openAlert({
              alertType: "success",
              alertMessage: i18n.t("TASKS.removedDownloadSuccessMessage"),
              isAlertOpen: true
            }))
          }

          let newDownloadloads = [...downloads]
          newDownloadloads.splice(index, 1)
          setDownloads(newDownloadloads)
          if (taskId) {
            getTaskInfo()
          }
        } catch (e) {
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const getTaskStatusesFunc = async () => {
    try {
      const res = await getTasksStatusesAction()
      setTaskStatuses(res.data.taskStatuses)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const addNewFormToLoads = () => {
    let newLoads = loads?.map(item => ({ ...item, isOpen: false })) || {}
    setLoads([...newLoads, {
      place: "",
      phone: "",
      date: "",
      timetable: "",
      ref: "",
      address: "",
      lat: "",
      long: "",
      comments: "",
      isOpen: true,
      unloads_ids: [],
      email: []
    }])
  }

  const saveNewLoad = (load) => {
    return new Promise(async (resolve, reject) => {
      try {
        let obj = load
        delete obj.object_id
        const res = await addLoadAction(taskId, obj)
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  }

  const saveNewDownload = (download) => {
    return new Promise(async (resolve, reject) => {
      try {
        let obj = download
        delete obj.object_id
        const res = await addDownloadAction(taskId, obj)
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  }

  const updateLoad = (load) => {
    return new Promise(async (resolve, reject) => {
      console.log('load', load)
      try {
        let data = {
          place: load.place || null,
          phone: load.phone || null,
          date: load.date ? moment(load.date).tz(config.TIME_ZONE).valueOf() : null,
          timetable: load.timetable || null,
          ref: load.ref || null,
          address: load.address || null,
          comments: load.comments || null,
          coords: load.coords || null,
          task_id: taskId || null,
          object_id: load.object_id || null,
          unloads_ids: load.unloads_ids || null,
          email: load.email|| null
        }
        const res = await updateTaskLoadAction(data)
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  }

  const updateDownload = (download) => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = {
          place: download.place || null,
          phone: download.phone || null,
          date: download.date ? moment(download.date).tz(config.TIME_ZONE).valueOf() : null,
          timetable: download.timetable || null,
          ref: download.ref || null,
          address: download.address || null,
          comments: download.comments || null,
          coords: download.coords || null,
          task_id: taskId || null,
          object_id: download.object_id || null,
          loads_ids: download.loads_ids || null,
          email: download.email || null
        }
        const res = await updateTaskdownloadAction(data)
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  }

  const updateAllDownloads = () => {
    return new Promise(async (resolve, reject) => {
      if (downloads?.filter(download => download?._id && download?._id !== undefined).length > 0) {
        try {
          const newDownloads = downloads?.filter(download => download?._id && download?._id !== undefined)
          let promisesArray = []
          fixLoadsAndUnloads(newDownloads).forEach((load) => {
            promisesArray.push(updateDownload(load))
          })

          await Promise.all(promisesArray)
          resolve()
        } catch (e) {
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      } else {
        resolve()
      }
    })
  }

  const updateAllLoads = () => {
    return new Promise(async (resolve, reject) => {
      if (loads?.filter(load => load?._id && load?._id !== undefined).length > 0) {
        try {
          const newLoads = loads?.filter(load => load?._id && load?._id !== undefined)
          let promisesArray = []
          fixLoadsAndUnloads(newLoads).forEach((load) => {
            promisesArray.push(updateLoad(load))
          })

          await Promise.all(promisesArray)
          resolve()
        } catch (e) {
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      } else {
        resolve()
      }
    })
  }

  const saveNewFormsToLoads = () => {
    return new Promise(async (resolve, reject) => {
      if (loads?.filter(load => !load._id).length > 0) {
        try {
          const newLoads = loads?.filter(load => !load._id)
          let promisesArray = []
          fixLoadsAndUnloads(newLoads).forEach((load) => {
            promisesArray.push(saveNewLoad(load))
          })

          await Promise.all(promisesArray)
          resolve()
        } catch (e) {
          reject(e)
          dispatch(openAlert({  alertType: "error",  isAlertOpen: true  }))
        }
      } else {
        resolve()
      }
    })
  }

  const saveNewFormsToDownloads = () => {
    return new Promise(async (resolve, reject) => {
      if (downloads?.filter(load => !load._id).length > 0) {
        try {
          const newDownloads = downloads?.filter(load => !load._id)
          let promisesArray = []
          fixLoadsAndUnloads(newDownloads).forEach((download) => {
            promisesArray.push(saveNewDownload(download))
          })

          await Promise.all(promisesArray)
          resolve()
        } catch (e) {
          reject(e)
          dispatch(openAlert({  alertType: "error", isAlertOpen: true  }))
        }
      } else {
        resolve()
      }
    })
  }

  const handleKeyDown = (event, form, type, index) => {
    if (!inputValue) return;

    let load = form.length >= 1 ? form[index].email : form.email
    console.log('load', load)

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        type === 'load' ? setLoadForm([ ...load, createOption(inputValue) ], "email", index) : setDownloadForm([ ...load, createOption(inputValue) ], "email", index)
        setInputValue('');
        event.preventDefault();
    }
}


  const addNewFormToDownloads = () => {
    let newDownloads = downloads?.map(item => ({ ...item, isOpen: false })) || {}
    setDownloads([...newDownloads, {
      place: "",
      phone: "",
      date: "",
      timetable: "",
      ref: "",
      address: "",
      lat: "",
      long: "",
      comments: "",
      loads_ids: [],
      isOpen: true,
      email: []
    }])
  }

  const isInfoFormCompleted = () => {
    if ( driver && enrollmentVehicle && startDate && endDate ) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } 

    setGeneralError(i18n.t("SIGNUP.completeAllFields"))
    setShowInputsErrors(true)
    setCurrentForm("info")

    return false
  }

  const isLoadsFormCompleted = () => {
    if (loads.length > 0 &&  loads.every(load => load.place && load.date && load.address)) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } 

    setGeneralError(i18n.t("SIGNUP.completeAllFields"))
    setShowInputsErrors(true)
    setCurrentForm("load")

    return false
  }

  const isLoadsFormCompletedMainButtons = () => {
    if (loads.length > 0 && loads.every(load => load.place && load.date && load.address && load._id)) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } 

    setGeneralError(i18n.t("SIGNUP.completeAllFields"))
    setShowInputsErrors(true)
    setCurrentForm("load")

    return false
  }

  const isDownloadsFormCompleted = () => {
    if (
      downloads.length > 0 &&
      downloads.every(download => download.place &&
        download.date &&
        download.address &&
        download.loads_ids?.length > 0
      )
    ) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } else {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      setCurrentForm("download")

      return false
    }
  }

  const fixLoadsAndUnloads = (array) => {
    const newArray = array.map(item => {
      let obj = {
        place: item.place || null,
        phone: item.phone || null,
        date: item.date ? moment(item.date).tz(config.TIME_ZONE).valueOf() : null,
        timetable: item.timetable || null,
        ref: item.ref || null,
        address: item.address || null,
        comments: item.comments || null,
        coords: item.lat && item.long ? `${item.lat},${item.long}` : null,
        object_id: item._id || null,
        email: item.email.map(item => item.label) || null
      }

      if (item.loads_ids) {
        obj.loads_ids = item.loads_ids ? item.loads_ids.map(item => item.value) : []
      }

      if (item.unloads_ids) {
        obj.unloads_ids = item.unloads_ids ? item.unloads_ids.map(item => item.value) : []
      }

      if (!taskId) {
        delete obj.loads_ids
        delete obj.unloads_ids
        delete obj.object_id
      }

      return obj
    })
    return newArray
  }

  const createTask = async () => {
    if (isInfoFormCompleted()) {
      try {
        setLoading(true)
        let data = {
          start_date: startDate ? moment(startDate).tz(config.TIME_ZONE).set("hour", 0).set("minute", 0).valueOf() : null,
          end_date: endDate ? moment(endDate).tz(config.TIME_ZONE).set("hour", 23).set("minute", 59).valueOf() : null,
          driver_id: driver || null,
          trailer_id: enrollmentTrailer || null,
          vehicle_id: enrollmentVehicle || null,
        }


        if (!taskId) {
          data.loads = []
          data.unloads = []
        }

        if (taskId) {
          data.status = taskInfoState || null
        }

        if (!taskId && loads.filter(load => load.coords && load.place && load.address).length > 0) {
          data.loads = fixLoadsAndUnloads(loads)
        }

        if (!taskId && downloads.filter(download => download.coords && download.place && download.address).length > 0) {
          data.unloads = fixLoadsAndUnloads(downloads)
        }

        if (!taskId) {
          const res = await createTaskAction(data)
          const id = res.data.createTask._id
          navigate(`${TASK_DETAILS_ROUTE}/${id}`)
          setCurrentForm("load")
        } else {
          //SAVE NEW LOADS
          if (loads.filter(load => !load._id && load.lat && load.long && load.address && load.place && load.date).length > 0) {
            await saveNewFormsToLoads();
            getTaskInfo()
          }

          //VALIDATE IF THERE IS UNASSIGNED LOADS
          if (loads.filter(load => load.coords && load.place && load.address).length > 0) {
            const unusedLoads = loads.filter(load => {
              const isLoadUsedAlready = downloads.filter(download => {
                if (download.loads_ids.filter(item => item && item.value === load._id).length > 0)  return true
                
                return false
              }).length > 0

              if (isLoadUsedAlready)  return false

              return true
            })
            if (unusedLoads.length > 0) {
              setGeneralError(i18n.t("TASKS.thereIsUnusedLoads"))
              setShowInputsErrors(true)
              setCurrentForm("download")
              setLoading(false)
              return
            }
          }

          //SAVE NEW DOWNLOADS
          if (downloads.filter(download => !download._id && download.lat && download.long && download.address && download.place && download.date).length > 0)  await saveNewFormsToDownloads()

          //UPDATE LOADS
          if (loads.filter(load => load?._id && load?._id !== undefined).length > 0)  await updateAllLoads()

          //UPDATE DOWNLOADS
          if (downloads.filter(download => download?._id && download?._id !== undefined).length > 0)  await updateAllDownloads();

          await updateTaskAction(taskId, data)

          if (downloads.filter(item => item._id).length <= 0)  setCurrentForm("download")

          getTaskInfo()

          if (!isCreate) {
            dispatch(openAlert({  alertType: "success",  alertMessage: i18n.t("TASKS.updatedSuccessMessage"),  isAlertOpen: true  }))
          }
          if (isCreate &&  loads.filter(load => load.coords && load.place && load.address).length > 0 &&  downloads.filter(download => (download.coords || (download.lat && download.long)) && download.place && download.address).length > 0) {
            dispatch(openAlert({  alertType: "success",  alertMessage: i18n.t("TASKS.successMessage"),  isAlertOpen: true  }))
            navigate(TASKS_ROUTE)
          }
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true
        }))
      }
    }
  }

  const getFormAuxDataFunc = async () => {
    try {
      setLoading(true)
      const driversData = {
        pagination: {
          limit: 30,
          offset: 0
        },
        email: null,
        status: null,
        type: null,
        payment_type: null,
        driver_id: null
      }
      const driversRes = await getDrivers(driversData)

      setDrivers(driversRes?.data?.myDrivers)


      const vehiclesData = {
        pagination: {
          limit: 30,
          offset: 0
        },
        plate: null,
        vehicle_type: null
      }
      const vehiclesRes = await getVehiclesAction(vehiclesData)

      setVehicles(vehiclesRes?.data?.myVehicles)

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const hoursArrayForSelect = hoursArray

  const EndDateComp = (props) => <FormDateInput
    {...props}
  />

  const MemoizedEndDate = React.memo(EndDateComp);
  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileButtonsContainer>
          <CategoryButton
            onClick={() => setCurrentForm("info")}
            active={currentForm === "info"}
            width={200}
            icon="info"
            disabled={loading}
            title={i18n.t('TASKS.buttons.info')}
          />
          <CategoryButton
            onClick={() => isInfoFormCompleted() ? setCurrentForm("load") : setCurrentForm("info")}
            active={currentForm === "load"}
            width={200}
            icon="load"
            disabled={loading || !taskId}
            title={i18n.t('TASKS.buttons.load')}
          />
          <CategoryButton
            onClick={() => isInfoFormCompleted() && isLoadsFormCompletedMainButtons() ? setCurrentForm("download") : null}
            active={currentForm === "download"}
            width={200}
            icon="download"
            disabled={loading || !taskId}
            title={i18n.t('TASKS.buttons.download')}
          />
          {taskId && <CategoryButton
            onClick={() => setCurrentForm("issues")}
            active={currentForm === "issues"}
            width={200}
            icon="issues"
            disabled={loading}
            title={i18n.t('TASKS.buttons.issues')}
          />}
        </ProfileButtonsContainer>
        <ProfileFormSeparator />
        <ProfileFormContainer>
          {currentForm === "info"
            ? <>
              <TableInfoContainer>
                <TableTitle>
                  {`${taskId && taskInfoName ? `${taskInfoName}` : i18n.t('TASKS.forms.info.title')}`}
                </TableTitle>
              </TableInfoContainer>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  {taskId && <FormSelect
                    disabled={loading || isFinished || isInProcess || isDraft}
                    title={i18n.t('TASKS.forms.info.state')}
                    value={taskInfoState}
                    onChange={({ target: { value } }) => setTaskInfoState(value)}
                    items={taskStatuses.map(status => ({ value: status.name.toUpperCase(), label: status.translate[0]?.langs[localStorage.getItem("language") || "es"] }))}
                    required
                    showError={!taskInfoState && showInputsErrors}
                  />}
                  <DatesTaskInfoContainer>
                    <FormDateInput
                      disabled={loading || isFinished || isInProcess}
                      containerStyle={{ width: 150 }}
                      title={i18n.t('TASKS.forms.info.startDate')}
                      value={startDate}
                      minDate={new Date()}
                      onChange={(newValue) => {
                        setStartDate(newValue)
                        setEndDate("")
                      }}
                      required
                      showError={!startDate && showInputsErrors}
                    />
                    <MemoizedEndDate
                      disabled={loading || !startDate || isFinished || isInProcess}
                      containerStyle={{ width: 150 }}
                      title={i18n.t('TASKS.forms.info.endDate')}
                      value={endDate}
                      onChange={(newValue) => {  setEndDate(newValue)  }}
                      minDate={startDate}
                      required
                      showError={!endDate && showInputsErrors}
                    />
                  </DatesTaskInfoContainer>
                  <FormSelect
                    disabled={loading || isFinished || isInProcess}
                    title={i18n.t('TASKS.forms.info.enrollmentVehicle')}
                    value={enrollmentVehicle}
                    onChange={({ target: { value } }) => setEnrollmentVehicle(value)}
                    items={vehicles.filter(item => item.vehicle_type.toUpperCase() !== "TRAILER").map((vehicle) => ({ label: vehicle.plate, value: vehicle._id }))}
                    required
                    showError={!enrollmentVehicle && showInputsErrors}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormSelect
                    disabled={loading || isFinished || isInProcess}
                    title={i18n.t('TASKS.forms.info.enrollmentTrailer')}
                    value={enrollmentTrailer}
                    onChange={({ target: { value } }) => setEnrollmentTrailer(value)}
                    items={vehicles.filter(item => item.vehicle_type.toUpperCase() === "TRAILER").map((vehicle) => ({ label: vehicle.plate, value: vehicle._id }))}
                  />
                  <FormSelect
                    disabled={loading || isFinished || isInProcess}
                    title={i18n.t('TASKS.forms.info.driver')}
                    value={driver}
                    onChange={({ target: { value } }) => setDriver(value)}
                    items={drivers.map((driver) => ({ label: `${driver.name} ${driver.surnames}`, value: driver._id }))}
                    required
                    showError={!driver && showInputsErrors}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                {!isFinished && <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => isInfoFormCompleted() ? createTask() : null}
                    active
                    icon=""
                    title={taskId && !isCreate ? i18n.t('TASKS.forms.info.buttons.update') : i18n.t('TASKS.forms.info.buttons.save')}
                    loading={loading}
                    disabled={loading || isFinished || isInProcess}
                  />
                </ProfileLastButtonsContainer>}
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "load"
            ? <>
              <TableInfoContainer>
                <TableTitle>
                  {i18n.t('TASKS.forms.load.title')}
                </TableTitle>
              </TableInfoContainer>
              {loads?.map((item, index) => {

                const Map = ({ lat, long }) => {
                  const center = !isNaN(lat) && !isNaN(long) ? [Number(lat), Number(long)] : [item.coords?.split(',')[0] || 40.4115, item.coords?.split(',')[1] || -2.7076]
                  return (
                    <MapContainer
                      style={{ width: 300, height: '300px', marginTop: 20, borderRadius: 15 }}
                      center={center}
                      zoom={5}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      <Marker icon={redIcon} color="red" position={center}>

                      </Marker>
                    </MapContainer>
                  )
                }

                const MemoizedMap = React.memo(Map);

                return (
                  <DropdownLayer
                    index={index}
                    key={index}
                    onRemoveForm={() => !loading ? removeLoad(index, item?._id) : null}
                    toggleLayer={() => !loading ? setLoadForm(!item.isOpen, "isOpen", index) : null}
                    isOpen={item.isOpen}
                    loading={loading}
                  >
                    <ProfileFormsContainer>
                      <ProfileFormInputsContainer>
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.loadPlace')}
                          type={"text"}
                          value={item.place}
                          onChange={({ target: { value } }) => {  setLoadForm(value, "place", index)  }}
                          required
                          showError={!item.place && showInputsErrors}
                        />
                        <FormTextInput
                          title={i18n.t('TASKS.forms.load.phone')}
                          value={item.phone}
                          onChange={({ target: { value } }) => setLoadForm(String(value), "phone", index)}
                          disabled={loading || isFinished || item.delivered || isInProcess}
                        />
                        <DatesTaskInfoContainer>
                          <FormDateInput
                            disabled={loading || isFinished || item.delivered || isInProcess}
                            containerStyle={{ width: 150 }}
                            title={i18n.t('TASKS.forms.load.date')}
                            value={item.date}
                            onChange={(newValue) => {
                              setLoadForm(newValue, "date", index)
                            }}
                            minDate={startDate}
                            maxDate={endDate || ""}
                            required
                            showError={!item.date && showInputsErrors}
                          />
                          <FormSelect
                            title={i18n.t('TASKS.forms.load.calendar')}
                            value={item.timetable}
                            containerStyle={{ width: 150 }}
                            fatherContainer={{ marginTop: 8 }}
                            onChange={({ target: { value } }) => setLoadForm(value, "timetable", index)}
                            items={hoursArrayForSelect}
                            placeholder={"00:00"}
                            disabled={loading || isFinished || item.delivered || isInProcess}
                          />
                        </DatesTaskInfoContainer>
                        <div style={{ display: 'flex', justifyContent: 'start', alignContent: 'start', flexDirection: 'column' }}>
                          <div>
                            <InputTitle >
                              {i18n.t('SUPER_ADMIN_ADMINISTRATION.email')}
                            </InputTitle>
                          </div>
                          <ReactSelect 
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            styles={colourStyles}
                            isMulti
                            menuIsOpen={false}
                            onInputChange={(newValue) => setInputValue(newValue)}
                            onKeyDown={(e) => handleKeyDown(e, loads, 'load', index)}
                            placeholder="Presiona la tecla Enter o Tab para agregar..."
                            value={ item.email }
                            isDisabled={ loading || isFinished || item.delivered || isInProcess }
                            onChange={(e) => setLoadForm(e, "email", index)}
                        />
                        </div>
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.reference')}
                          value={item.ref}
                          onChange={({ target: { value } }) => setLoadForm(value, "ref", index)}
                        />
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.comments')}
                          value={item.comments}
                          onChange={({ target: { value } }) => setLoadForm(value, "comments", index)}
                        />
                        {taskId && <TableInfoContainer>
                          <TableTitle>
                            {`${i18n.t('TASKS.forms.load.status')}: ${item.delivered ? i18n.t('TASKS.forms.load.delivered') : i18n.t('TASKS.forms.load.notDelivered')}`}
                          </TableTitle>
                        </TableInfoContainer>}
                      </ProfileFormInputsContainer>
                      <ProfileFormInputsContainer>
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.address')}
                          type={"text"}
                          value={item.address}
                          onChange={({ target: { value } }) => {
                            setLoadForm(value, "address", index)
                            geocodeByAddress(value)
                              .then(results => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setLoadFormValues(  [  { name: "lat", value: String(lat) },  { name: "long", value: String(lng) }  ], index)  
                              })
                              .catch(e => {
                              })

                          }}
                          required
                          showError={!item.address && showInputsErrors}
                          isAddressAutocomplete
                        />
                        <FormTextInput
                          disabled={true}
                          title={i18n.t('TASKS.forms.load.lat')}
                          type={"text"}
                          value={item.lat ? item.lat : taskId ? item.coords?.split(',')[0] : item.lat}
                          onChange={({ target: { value } }) => {  setLoadForm(value, "lat", index)  }}
                          canCopy
                        />
                        <FormTextInput
                          disabled={true}
                          title={i18n.t('TASKS.forms.load.long')}
                          type={"text"}
                          value={item.long ? item.long : taskId ? item.coords?.split(',')[1] : item.long}
                          onChange={({ target: { value } }) => {  setLoadForm(value, "long", index)  }}
                          canCopy
                        />
                        <MemoizedMap
                          lat={item.lat}
                          long={item.long}
                        />
                        {(taskImages && taskImages.length > 0) && <div style={{ flexDirection: 'column', justifyContent: "flex-start", maxWidth: 300 }}>
                          <InputTitle>
                            {i18n.t('TASKS.forms.load.documents')}
                          </InputTitle>
                          <div style={{ flexWrap: "wrap", flexDirection: "row", width: 300 }}>
                            {taskImages && taskImages.filter(image => image.object_id === item._id).map(image =>
                              <img
                                onClick={() => {  dispatch(openAlert({  alertType: "image-preview",  alertImage: image.fullpath,  isAlertOpen: true}))  }}
                                style={{ width: 70, height: 70, margin: 5, borderRadius: 10, cursor: "pointer" }}
                                src={image.fullpath}
                              />
                            )}
                          </div>
                        </div>}
                      </ProfileFormInputsContainer>
                    </ProfileFormsContainer>
                  </DropdownLayer>
                )
              })}
              <ProfileFormSeparator />
              {!isFinished && <ProfileLastButtonsContainer>
                <CategoryButton
                  onClick={() => addNewFormToLoads()}
                  active={false}
                  icon="add-circle"
                  title={""}
                  style={{ width: 50, height: 50 }}
                  iconStyles={{ width: 40, height: 40 }}
                  loading={loading}
                  disabled={loading || isFinished || isInProcess}
                />
                <CategoryButton
                  onClick={() => isLoadsFormCompleted() ? createTask() : null}
                  active
                  icon=""
                  title={taskId && !isCreate ? i18n.t('TASKS.forms.info.buttons.update') : i18n.t('TASKS.forms.info.buttons.save')}
                  loading={loading}
                  disabled={loading || isFinished || isInProcess}
                />
              </ProfileLastButtonsContainer>}
            </> : null}
          {currentForm === "download"
            ? <>
              <TableInfoContainer>
                <TableTitle>
                  {i18n.t('TASKS.forms.load.title')}
                </TableTitle>
              </TableInfoContainer>
              {downloads.map((item, index) => {
                const Map = ({ lat, long }) => {
                  const center = !isNaN(lat) && !isNaN(long) ? [Number(lat), Number(long)] : [item.coords?.split(',')[0] || 40.4115, item.coords?.split(',')[1] || -2.7076]
                  return (
                    <MapContainer
                      style={{ width: 300, height: '300px', marginTop: 20, borderRadius: 15 }}
                      center={center}
                      zoom={5}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      <Marker icon={redIcon} color="red" position={center}></Marker>
                    </MapContainer>
                  )
                }

                const MemoizedMap = React.memo(Map);

                const downloadLoads = loads
                  .map((item) => ({  value: item._id,  label: `${item.place}`  }))
                return (
                  <DropdownLayer
                    index={index}
                    key={index}
                    onRemoveForm={() => !loading ? removeDownLoad(index, item?._id) : null}
                    toggleLayer={() => !loading ? setDownloadForm(!item.isOpen, "isOpen", index) : null}
                    isOpen={item.isOpen}
                    loading={loading}
                  >
                    <ProfileFormsContainer>
                      <ProfileFormInputsContainer>
                        {taskId && <FormMultiSelect
                          title={i18n.t('TASKS.forms.load.selectLoad')}
                          value={item.loads_ids}
                          containerStyle={{ width: 200 }}
                          fatherContainer={{ marginTop: 8 }}
                          onChange={({ target: { actionMeta } }) => {
                            if (actionMeta.action === "clear") {
                              const valueToRemove = actionMeta.removedValues
                              let newItems = (item.loads_ids ? [...item.loads_ids] : []).filter(item => valueToRemove.filter(itemRemoved => itemRemoved.value === item.value).length <= 0)

                              setDownloadForm(newItems, "loads_ids", index)
                              return
                            }

                            if (actionMeta.action === "remove-value") {
                              const valueToRemove = actionMeta.removedValue
                              let newItems = (item.loads_ids ? [...item.loads_ids] : []).filter(item => item.value !== valueToRemove.value)

                              setDownloadForm(newItems, "loads_ids", index)
                              return
                            }

                            if (actionMeta.action === "select-option") {
                              let newItems = (item.loads_ids ? [...item.loads_ids] : []).concat(actionMeta.option)

                              if (newItems.length > 0) {
                                newItems = newItems
                                  .filter((item, index, self) =>
                                    index === self.findIndex((t) => (
                                      t.value === item.value
                                    ))
                                  )
                              }

                              setDownloadForm(newItems, "loads_ids", index)
                              return
                            }

                          }}
                          items={downloadLoads}
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          required
                          showError={item.loads_ids?.length <= 0 && showInputsErrors}
                        />}
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.place')}
                          type={"text"}
                          value={item.place}
                          onChange={({ target: { value } }) => {  setDownloadForm(value, "place", index)  }}
                          required
                          showError={!item.place && showInputsErrors}
                        />
                        <FormTextInput
                          title={i18n.t('TASKS.forms.load.phone')}
                          value={item.phone}
                          onChange={({ target: { value } }) => setDownloadForm(String(value), "phone", index)}
                          disabled={loading || isFinished || item.delivered || isInProcess}
                        />
                        <DatesTaskInfoContainer>
                          <FormDateInput
                            disabled={loading || isFinished || item.delivered || isInProcess}
                            containerStyle={{ width: 150 }}
                            title={i18n.t('TASKS.forms.load.date')}
                            value={item.date}
                            onChange={(newValue) => { setDownloadForm(newValue, "date", index)  }}
                            minDate={startDate}
                            maxDate={endDate || ""}
                            required
                            showError={!item.date && showInputsErrors}
                          />
                          <FormSelect
                            title={i18n.t('TASKS.forms.load.calendar')}
                            value={item.timetable}
                            containerStyle={{ width: 150 }}
                            fatherContainer={{ marginTop: 8 }}
                            onChange={({ target: { value } }) => setDownloadForm(value, "timetable", index)}
                            items={hoursArrayForSelect}
                            placeholder={"00:00"}
                            disabled={loading || isFinished || item.delivered || isInProcess}
                          />
                        </DatesTaskInfoContainer>
                        <div style={{ display: 'flex', justifyContent: 'start', alignContent: 'start', flexDirection: 'column' }}>
                          <div>
                            <InputTitle >
                              {i18n.t('SUPER_ADMIN_ADMINISTRATION.email')}
                            </InputTitle>
                          </div>
                          <ReactSelect 
                              components={components}
                              inputValue={inputValue}
                              isClearable
                              styles={colourStyles}
                              isMulti
                              menuIsOpen={false}
                              onInputChange={(newValue) => setInputValue(newValue)}
                              onKeyDown={(e) => handleKeyDown(e, downloads, 'download', index)}
                              placeholder="Presiona la tecla Enter o Tab para agregar..."
                              value={ item.email }
                              isDisabled={ loading || isFinished || item.delivered || isInProcess }
                              onChange={(e) => setDownloadForm(e, "email", index)}
                          />
                        </div>
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.reference')}
                          value={item.ref}
                          onChange={({ target: { value } }) => setDownloadForm(value, "ref", index)}
                        />
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.comments')}
                          value={item.comments}
                          onChange={({ target: { value } }) => setDownloadForm(value, "comments", index)}
                        />
                        {taskId && <TableInfoContainer>
                          <TableTitle>
                            {`${i18n.t('TASKS.forms.load.status')}: ${item.delivered ? i18n.t('TASKS.forms.load.delivered') : i18n.t('TASKS.forms.load.notDelivered')}`}
                          </TableTitle>
                        </TableInfoContainer>}
                      </ProfileFormInputsContainer>
                      <ProfileFormInputsContainer>
                        <FormTextInput
                          disabled={loading || isFinished || item.delivered || isInProcess}
                          title={i18n.t('TASKS.forms.load.address')}
                          type={"text"}
                          value={item.address}
                          onChange={({ target: { value } }) => {
                            setDownloadForm(value, "address", index)
                            geocodeByAddress(value)
                              .then(results => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setDownloadFormValues(  [  { name: "lat", value: String(lat) },  { name: "address", value: String(value) },  { name: "long", value: String(lng) }  ], index)
                              })
                              .catch(e => {
                              })

                          }}
                          required
                          showError={!item.address && showInputsErrors}
                          isAddressAutocomplete
                        />
                        <FormTextInput
                          disabled={true}
                          title={i18n.t('TASKS.forms.load.lat')}
                          type={"text"}
                          value={item.lat ? item.lat : taskId ? item.coords?.split(',')[0] : item.lat}
                          onChange={({ target: { value } }) => {  setDownloadForm(value, "lat", index)  }}
                          canCopy
                        />
                        <FormTextInput
                          disabled={true}
                          title={i18n.t('TASKS.forms.load.long')}
                          type={"text"}
                          value={item.long ? item.long : taskId ? item.coords?.split(',')[1] : item.long}
                          onChange={({ target: { value } }) => {  setDownloadForm(value, "long", index)  }}
                          canCopy
                        />
                        <MemoizedMap lat={item.lat}  long={item.long}  />
                         {(taskImages && taskImages.length > 0) && <div style={{ flexDirection: 'column', justifyContent: "flex-start", maxWidth: 300 }}>
                          <InputTitle>
                            {i18n.t('TASKS.forms.load.documents')}
                          </InputTitle>
                          <div style={{ flexWrap: "wrap", flexDirection: "row", width: 300 }}>
                            {taskImages && taskImages.filter(image => image.object_id === item._id).map(image =>
                              <img
                                onClick={() => {  dispatch(openAlert({  alertType: "image-preview",  alertImage: image.fullpath,  isAlertOpen: true }))  }}
                                style={{ width: 70, height: 70, margin: 5, borderRadius: 10, cursor: "pointer" }}
                                src={image.fullpath}
                              />
                            )}
                          </div>
                        </div>}
                      </ProfileFormInputsContainer>
                    </ProfileFormsContainer>
                  </DropdownLayer>
                )
              })}
              <ProfileFormSeparator />
              {generalError && <GeneralErrorContainer>
                <GeneralErrorText>{generalError}</GeneralErrorText>
              </GeneralErrorContainer>}
              {!isFinished && <ProfileLastButtonsContainer>
                <CategoryButton
                  onClick={() => addNewFormToDownloads()}
                  active={false}
                  icon="add-circle"
                  loading={loading}
                  disabled={loading || isFinished || isInProcess}
                  title={""}
                  style={{ width: 50, height: 50 }}
                  iconStyles={{ width: 40, height: 40 }}
                />
                <CategoryButton
                  onClick={() => isDownloadsFormCompleted() && isLoadsFormCompleted() && isInfoFormCompleted() ? createTask() : null}
                  loading={loading}
                  disabled={loading || isFinished || isInProcess}
                  active
                  icon=""
                  title={taskId && !isCreate ? i18n.t('TASKS.forms.info.buttons.update') : i18n.t('TASKS.forms.info.buttons.save')}
                />
              </ProfileLastButtonsContainer>}
            </> : null}
          {currentForm === "issues" && taskId
            ? <>
              <IssuesScreen
                taskId={taskId}
              />
            </> : null}
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default TasksDetailsScreen
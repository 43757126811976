import { gql } from '@apollo/client';


export const GET_JOURNEYS_MUTATION = gql`
query journeys($driver_id: ObjectId, $input: JourneyFiltersInput) {
  journeys(driver_id: $driver_id, input: $input){
      _id
      initial_kms 
      observations
      incidents
      timezone_end
      timezone_start
      last_timezone
      final_city {
        name
        latitude
        longitude
      }
      initial_city {
        name
        latitude
        longitude
      }
      gps_info {
        calculations {
          distance
          dinner
          lunch
          dinner_country {
            name
          }
          dinner_state {
            name
          }
          lunch_country {
            name
          }
          lunch_state {
            name
          }
          nocturnity_international
          nocturnity_national
        }
      }
      creation_datetime
      finish_datetime
      driver
      coords {
        country
        x
        y
      }
      status
      vehicle
      trailer
      checklist {
        status
        epis {
          gloves
          helmet
          high_visibility_garment
          masks
          others
          protective_screen
          safety_footwear
        }
        vehicle {
          batteries
          fire_extinguishers
          luminary
          mirrors
          tire_pressure
          windshield
          windshield_washer
        }
      }
    }
}
`;

export const EXPORT_JOURNEYS_MUTATION = gql`
query exportMyJourneysList($driver_id: ObjectId, $input: JourneyFiltersInput) {
  exportMyJourneysList(driver_id: $driver_id, input: $input) {
    fullpath
    path
    status
  }
}
`;
export const ROOT_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const RESTORE_PASSWORD_ROUTE = '/restore-password';
export const ENTER_CODE_ROUTE = '/enter-code';
export const ENTER_NEW_PASSWORD_ROUTE = '/enter-new-password';
export const PLANS_ROUTE = '/plans';
export const PROFILE_ROUTE = '/profile';
export const DRIVERS_ROUTE = '/drivers';
export const VEHICLES_ROUTE = '/vehicles';
export const VEHICLE_DETAILS_ROUTE = '/vehicle-details';
export const CHECKLIST_JOURNEY_ROUTE = '/checklist-journey';
export const DRIVER_DETAILS_ROUTE = '/driver-details';
export const TASKS_ROUTE = '/tasks';
export const TASK_DETAILS_ROUTE = '/task-details';
export const ISSUES_ROUTE = '/issues';
export const ISSUE_DETAILS = '/issue-details';
export const CHECKLIST_DETAILS = '/checklist-details';
export const ACCOUTING_ROUTE = '/accounting';
export const PAYMENT_CONCEPT = '/payment-concept';
export const CHATS_ROUTE = '/chats';
export const CHAT_ROUTE = '/chat';
export const JOURNEYS_ROUTE = '/journeys';
export const ADMIN_DETAILS_ROUTE = '/admin-details';
export const SIGNUP_ROUTE = '/signup';
export const BILLING_ROUTE = '/billing';
export const CLIENTS_ROUTE = '/clients';
export const CLIENT_DETAILS_ROUTE = '/client-details';
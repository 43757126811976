import React from "react";
import {
  TextInputContainer,
  InputIcon,
  CustomTextInput,
  InputTitle,
  FormTextInputMainContainer,
  CopyIcon,
  ErrorMessage
} from "./form-number-input-styles";
import copyIcon from "../../../../assets/tasks/copy-icon-red.svg"
import { COLORS_TRUCK } from "../../../../utils/colors";
import i18n from "../../../../i18n/i18n";
const FormNumberInput = (props) => {

  return (
    <FormTextInputMainContainer style={props.mainContainerStyle}>
      {props.title ? <InputTitle>
        {props.title}{props.required ? <span style={{ fontSize: 20 }}>{` *`}</span> : ""}
      </InputTitle>
        : null}
        {props.leftComponent ?
          props.leftComponent
          : null}
      <TextInputContainer style={Object.assign({ border: props.showError ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : `1px solid rgba(0, 0, 0, 0.23)`}, props.containerStyle)}>
        {props.icon && <InputIcon src={props.icon} />}
        <CustomTextInput
        {...props}
        autoComplete="off"
        onFocus={(event) => {
          event.target.setAttribute('autocomplete', 'off');
        }}
        type={props.allowDecimals ? "text" : "number"}
          onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
          onChange={({ target: { value }}) => {
             if(props.max && (Number(value) > Number(props.max))){
               return
             }

            if(props.allowDecimals ? true : value && Math.sign(value) > 0) {
              let number = value
              if(props.allowDecimals){
                number = number.replace(/[^\d.-]/g, '') // allow only numbers and decimals
              }

              if(number && number.split('.').length <= 1){ //remove leading 0 if there is no decimal
                number = parseInt(number, 10)
              }

              props.onValueChange(number)
            } else {
              if(!props.allowDecimals) {
                props.onValueChange("")
              }
            }
          }}
        />
        {props.rightIcon && <InputIcon
          onClick={props.iconRightClick}
          style={{ cursor: 'pointer' }}
          src={props.rightIcon}
        />}
        {props.rightComponent ? props.rightComponent : null}
        {props.canCopy
        ? <CopyIcon src={copyIcon} />
      : null}
      </TextInputContainer>
      {props.max && <ErrorMessage>{`* ${i18n.t("GENERAL.min")} (${props.min || "0"}), ${i18n.t("GENERAL.max")} (${props.max})`}</ErrorMessage>}
      {props.errorMessage && <ErrorMessage>{`* ${props.errorMessage}`}</ErrorMessage>}
    </FormTextInputMainContainer>
  )
}

export default FormNumberInput
import React, {useState} from "react";
import {
  FormFileInputContainer,
  AddFileIcon,
  FormMainContainer,
  Img,
  FormLoadContainer,
  FormLoadHeader,
  FormLoadText,
  FormLoadContent,
  ButtonDownloadTemplate,
  FormFileContainer,
  ButtonProcessImport,
  DownloadContainer,
  ContainerCenter
} from "./uploader-button-styles";

import config from "../../../config";
import addFileIcon from "../../../assets/file-input/add-circle-icon.svg"
import { COLORS_TRUCK } from '../../../utils/colors';
import CSV from '../../../assets/inputs/csv.png'
import Times from '../../../assets/general/times.svg'
import i18n from '../../../i18n/i18n';
import Loader from "../../loader/Loader";

const UploaderButton = ({title, onEditFile, disabled }) => {
  let [picture, setPicture] = useState([])
  let [formLoad, setFormLoad] = useState(false)
  let [loading, setLoading] = useState(false)

  const uploadFile = (aspect) => {
    const file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = '.xlsx, .csv';
    file.onchange = (e) => getFileInfo(e, aspect);
    file.click();
  };

  const getFileInfo = (e, aspect) => {
    setPicture(e.target.files)
  }

  const handleFileInput = () => {
    setLoading(true)
    if (picture && picture.length > 0) {
      setTimeout(() => {
        setLoading(false)
        onEditFile(picture[0])
        setFormLoad(!formLoad)
      }, 3000);
    }
  }

  return (
    <>
      <FormMainContainer onClick={() => {
        setFormLoad(!formLoad)
        setPicture([])
      }}>
        <Img src={CSV}></Img>
        {i18n.t('TASKS.buttons.import')}
      </FormMainContainer>
      {
        formLoad ?
        <FormLoadContainer>
          <FormLoadHeader>
            {i18n.t('TASKS.forms.import.title')}
            <Img style={{cursor: "pointer"}} onClick={() => {
              setFormLoad(!formLoad)
              setPicture([])
            }} src={Times}></Img>
          </FormLoadHeader>
          <FormLoadContent>
            <FormLoadText>
              {i18n.t('TASKS.forms.import.text')}
            </FormLoadText>
            <FormLoadText style={{color: COLORS_TRUCK.ORANGE_TRUCK}}>{i18n.t('TASKS.forms.import.instructions')}</FormLoadText>
            <DownloadContainer style={{backgroundColor: COLORS_TRUCK.ORANGE_TRUCK, color: COLORS_TRUCK.WHITE_TRUCK}}>
              <ButtonDownloadTemplate href={config.INSTRUCTIONS_XLSX}>
                <ContainerCenter style={{color: COLORS_TRUCK.WHITE_TRUCK, paddingRight: 5}}>
                  {i18n.t('ALERT.download')}
                </ContainerCenter>
                <ContainerCenter style={{color: COLORS_TRUCK.WHITE_TRUCK}}>
                  {i18n.t('TASKS.forms.import.instructions')}
                </ContainerCenter>
              </ButtonDownloadTemplate>
            </DownloadContainer>
            <FormLoadText style={{color: COLORS_TRUCK.ORANGE_TRUCK}}>{i18n.t('TASKS.forms.import.template')}</FormLoadText>
            <DownloadContainer>
              <ButtonDownloadTemplate href={config.TEMPLATE_XLSX}>
                {i18n.t('TASKS.forms.import.buttons.download')}
              </ButtonDownloadTemplate>
            </DownloadContainer>
            <FormLoadText style={{color: COLORS_TRUCK.ORANGE_TRUCK, paddingTop: 10}}>{i18n.t('TASKS.forms.import.type')}</FormLoadText>
            <FormFileContainer>
                <FormFileInputContainer style={{ cursor: disabled ? "default" : "pointer" }} onClick={(e) => !disabled ? uploadFile(e) : null}>
                {
                  picture.length > 0 ? <FormLoadText style={{fontWeight: 700, fontSize: 20, color: COLORS_TRUCK.ORANGE_TRUCK}}> {picture[0].name} </FormLoadText> : <AddFileIcon src={addFileIcon} />
                }
                </FormFileInputContainer>
            </FormFileContainer>
            <ContainerCenter>
              <ButtonProcessImport onClick={() => handleFileInput()}>
                {
                  loading ? <Loader height={19} width={19} color={ COLORS_TRUCK.WHITE_TRUCK } /> : <>{i18n.t('TASKS.forms.import.buttons.load')}</>
                }
              </ButtonProcessImport>
            </ContainerCenter>
          </FormLoadContent>
        </FormLoadContainer>
        :
        <></>
      }
    </>
  )
}

export default UploaderButton
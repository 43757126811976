// import * as navigation from '../../routes/navigation';
import client from '../../../utils/api';
import { 
  GET_USERS_MUTATION,
  EXPORT_USERS_MUTATION,
  GET_USER_DRIVERS_MUTATION,
  UPDATE_USER_MUTATION,
  EXPORT_USER_DRIVERS_MUTATION,
  REMOVE_USER_MUTATION,
  CREATE_USER_MUTATION,
  UPDATE_USER_PIC_MUTATION,
  UPDATE_USER_STATUS_MUTATION
 } from './clients-queries';
// import {handleAlert} from '../../general/functions';



/**
 * getUsersAction - action for get companies by plans
 *
 * @returns {any}
 */
 export const getUsersAction = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_USERS_MUTATION,
        variables: {
          input: { ...data }
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateUserAction - action for create a driver
 *
 * @param {String} user_id - company id
 * @param {Object} data - updateUserAction data
 * @returns {any}
 */
 export const updateUserAction = (
  user_id,
  data
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: {
          user_id: user_id,
          input: data
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * createUserAction - action for create a driver
 *
 * @param {Object} data - user data
 * @returns {any}
 */
 export const createUserAction = (
  data
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CREATE_USER_MUTATION,
        variables: {
          input: data
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getUserDriversAction - action for get company drivers
 *
 * @returns {any}
 */
 export const getUserDriversAction = (company_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_USER_DRIVERS_MUTATION,
        variables: {
          company_id: company_id,
          input: { ...data }
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportUsersAction - action for get drivers
 *
 * @param {object} formData - exportUsersAction data
 * @returns {any}
 */
 export const exportUsersAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_USERS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * exportUserDriversAction - action for get drivers
 *
 * @param {string} company_id - company id
 * @param {object} formData - exportUserDriversAction data
 * @returns {any}
 */
 export const exportUserDriversAction = (
  company_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_USER_DRIVERS_MUTATION,
        variables: {
          company_id: company_id,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeUserAction - action for create a driver
 *
 * @param {string} user_id - company id
 * @returns {any}
 */
 export const removeUserAction = (
  user_id,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_USER_MUTATION,
        variables: {
          user_id: user_id,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * uploadUserPicAction - action for create a driver
 *
 * @param {File} file - uploadUserPicAction file
 * @param {string} file - user id
 * @returns {any}
 */
 export const uploadUserPicAction = (
  file,
  user_id
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_USER_PIC_MUTATION,
        variables: {
          file: file,
          user_id: user_id
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateUserStatusAction - action for update user status
 *
 * @param {String} user_id - driver id
 * @param {String} status - driver status
 * @returns {any}
 */
 export const updateUserStatusAction = (
  user_id,
  status,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_USER_STATUS_MUTATION,
        variables: {
          user_id: user_id,
          status: status,
          confirmed: "CONFIRMED"
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
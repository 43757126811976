import React, { useState } from "react";
import { 
  LoginContainer, 
  LoginButtonsContainer, 
  TruckLogoWords, 
  LoginTitle, 
  PaddingTextContainer,
  GeneralErrorContainer,
  GeneralErrorText
} from "./login-styles";
import truckLogoWords from "../../assets/login/truck-logo-words.svg";
import TextInput from "../../components/inputs/text-input/TextInput";
import userIcon from "../../assets/inputs/user.svg";
import { Button, ButtonText } from "../home/home-styles";
import { TextAsButton } from "../header/header-styles";
import { COLORS_TRUCK } from '../../utils/colors';
import i18n from '../../i18n/i18n';
import SafeLINK from "../../utils/safe-link";
import { RESTORE_PASSWORD_ROUTE, SIGNUP_ROUTE } from "../../routes/routes";
import passwordIcon from "../../assets/inputs/lock.svg";
import offEye from "../../assets/inputs/eye-off.svg";
import redEye from "../../assets/general/eye-icon-red.svg";
import { setUser } from "../../redux/reducers/user.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { loginAction } from "../signup/signup-actions";
import Loader from "../../components/loader/Loader";
import { openAlert } from "../../redux/reducers/app.ts";
import { openMenu } from "../../redux/reducers/app.ts";
// import { getChats } from "../chats/chats-actions";
import { getUnreadMessagesAction } from "../user-home/user-home-actions";

export const Span = ({ children, onClick, style }) => (
  <span
    style={Object.assign(
      { cursor: 'pointer' },
      style
    )}
    onClick={onClick}
  >
    {children}
  </span>
);

const LoginScreen = () => {
  localStorage.removeItem("confirm-email-code")
  const navigate = useNavigate();
  // const user = useAppSelector(userInfoSelector);
  const [secondPasswordShow, setSecondPassShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showInputsError, setShowInputsError] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const dispatch = useAppDispatch()

  // const getChatsFunc = async () => {
  //   try {
  //     const data = {
  //       message: null,
  //       pagination: {
  //         limit: 100,
  //         offset: 0
  //       },
  //       sort: {
  //         field: "createdAt",
  //         type: "ASC"
  //       }
  //     }

  //     const res = await getChats("", data)
  //     const chats = res?.data?.myMessages
  //     return chats

  //   } catch (e) {
  //     dispatch(openAlert({
  //       alertType: "error",
  //       isAlertOpen: true
  //     }))
  //   }
  // }

  const login = async () => {

    if (!email || !password) {
      setShowInputsError(true);
      setGeneralError(i18n.t("LOGIN.completeFields"))
      return
    } else {
      setGeneralError("")
      setShowInputsError(false);
    }
    // if (email === "admin" && password === "admin") {
    //   dispatch(setUser({ name: 'esteban contreras', email: 'esteban@gmail.com', admin: true }))
    // } else {
    //   dispatch(setUser({ name: 'esteban contreras', email: 'esteban@gmail.com', admin: false }))
    // }
    try {
      setLoading(true)
      const response = await loginAction({ email, password })
      if (response && response.data) {
        const user = response.data.login.user
        const userType = response.data.login.type
        const token = response.data.login.access_token
        setLoading(false)
        if (user.plan_details.status === "DISABLED") {
          // localStorage.setItem("token", token)
          // navigate(`${SIGNUP_ROUTE}/${user._id}`)
          navigate(SIGNUP_ROUTE, { state: { ...user, password } })
          dispatch(openAlert({
            isAlertOpen: true,
            alertType: "error",
            alertMessage: i18n.t("LOGIN.completeSignupError"),
          }))
        } else {

          localStorage.setItem("token", token)
          if (user.rol !== "superadmin") {
            const unreadMessages = await getUnreadMessagesAction()
            if (unreadMessages?.data?.unreadMessages?.total) {
              localStorage.setItem("numberOfUnreadMessages", String(unreadMessages?.data?.unreadMessages?.total))
            }
          }

          dispatch(openMenu())
          dispatch(setUser({
            ...user,
            userType: userType
          }))
          // setCountries(response.data.countries.map(country => { return { label: country.name, value: country._id, iso2: country.iso2 } }))
        }
      }
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }
  return (
    <LoginContainer>
      <LoginButtonsContainer>
        <TruckLogoWords src={truckLogoWords} />
        <LoginTitle>
          {i18n.t('LOGIN.welcome')}
        </LoginTitle>
        <TextInput
          icon={userIcon}
          value={email}
          type="email"
          placeholder={i18n.t('LOGIN.emailPlaceholder')}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          showError={!email && showInputsError}
        />
        <TextInput
          iconRightClick={() => loading ? null : setSecondPassShow(!secondPasswordShow)}
          rightIcon={secondPasswordShow ? offEye : redEye}
          icon={passwordIcon}
          value={password}
          placeholder={i18n.t('LOGIN.passwordPlaceholder')}
          type={secondPasswordShow ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          showError={!password && showInputsError}
        />
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <Button
          disabled={loading}
          onClick={() => login()} style={{ width: 300, marginTop: 20, marginBottom: 20 }}>
          {loading ?
            <Loader width={15} height={15} />
            : <ButtonText>
              {i18n.t('LOGIN.signin')}
            </ButtonText>}
        </Button>
        {!loading && <PaddingTextContainer>
          <Trans i18nKey='LOGIN.haven-signuo-question'>
            bla
            <Span style={{ color: COLORS_TRUCK.ORANGE_TRUCK }} onClick={() => {
              localStorage.removeItem("token")
              localStorage.removeItem("confirm-email-code")
              navigate(SIGNUP_ROUTE)
            }}>bla</Span>
            bla
          </Trans>
        </PaddingTextContainer>}
        {!loading && <SafeLINK
          dangerousLINK={RESTORE_PASSWORD_ROUTE}
          text={
            <TextAsButton style={{ color: COLORS_TRUCK.ORANGE_TRUCK, marginTop: 20, marginBottom: 20 }}>
              {i18n.t('LOGIN.forgetPassQuestion')}
            </TextAsButton>
          }
        />}
      </LoginButtonsContainer>
    </LoginContainer>
  )
}

export default LoginScreen
import React, { useEffect } from "react";
import {
  UserHomeContainer,
  UserHomeContentContainer,
  HomeStatContainer,
  HomeStatTitle,
  HomeStatContentContainer,
  HomeStatNumber,
  HomeStatIcon,
  HomeStatsContainerGeneral,
  TaskDetailsContainer,
  TaskDetailsTitle,
  TaskDetailsContent,
  TaskDetailsContentLink,
  TaskDetailsItemContainer,
  TaskDetailsItemsContainer,
  InfoContainer,
  InfoContentParagraph,
  ColorSquare,
  InfoItemsContainer
} from "./user-home-styles";
import i18n from '../../i18n/i18n';
import inProcessIcon from "../../assets/home/in-process-task-icon.svg";
import issuesIcon from "../../assets/home/issues-icon.svg";
import messagesIcon from "../../assets/home/messages-icon.svg";
import pendingTasksIcon from "../../assets/home/pending-tasks-icon.svg";
import redPinIcon from "../../assets/home/red-pin.svg";
import bluePinIcon from "../../assets/home/blue-pin.svg";
import greenPinIcon from "../../assets/home/green-pin.svg";
import blackPinIcon from "../../assets/home/black-pin.svg";
// import { useNavigate } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker
} from 'react-leaflet';
// import SafeLINK from "../../utils/safe-link";
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { COLORS_TRUCK } from "../../utils/colors";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import {
  LISTEN_RUNNING_TASKS_SUBSCRIPTION,
  LISTEN_COORDS_SUBSCRIPTION,
  LISTEN_PENDING_TASKS_SUBSCRIPTION,
  LISTEN_OPEN_INCIDENTS_SUBSCRIPTION,
  LISTEN_UNREAD_MESSAGES_SUBSCRIPTION,
  // NEW_NOTIFICATION_SUBSCRIPTION
} from "./user-home-queries";
import { useSubscription } from "@apollo/client";
import {
  getRunningTasksAction,
  getPendingTasksAction,
  getOpenIncidentsAction,
  getUnreadMessagesAction,
  getMapCoordsAction
} from "./user-home-actions";
import { useState } from "react";
import { CHAT_ROUTE, ISSUES_ROUTE, TASKS_ROUTE, TASK_DETAILS_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { getPaymentIntentAction, getUserProfile } from "../profile/profile-actions";
import { setUser } from "../../redux/reducers/user.ts";

const UserHomeScreen = () => {
  const navigate = useNavigate();
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice

  const [tasksInProcess, setTasks] = useState(0)
  const [pendingTasks, setPendingTasks] = useState(0)
  const [openIncidents, setOpenIncidents] = useState(0)
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [mapCoords, setMapCoords] = useState([])
  // const [lastCoordLong, setLastCoordLong] = useState("")
  // const [lastCoordLat, setLastCoordLat] = useState("")

  useEffect(async () => {
    await getUserData()
   

    getStats()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const payment_intent = params.get('payment_intent');
    // console.log("payment_intent: ", payment_intent)
    if (payment_intent) {
      validatePaymentIntent(payment_intent)
    }
    //validate payment
  }, [])

  const validatePaymentIntent = async (paymentIntent) => {
    try {

      const res = await getPaymentIntentAction(paymentIntent)
      if (res.data.getPaymentIntent.status === "succeeded") {
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("PROFILE.planPaymentSuccess"),
          isAlertOpen: true
        }))
      }
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getUserData = async () => {
    try {

      const res = await getUserProfile()
      const user = res.data.profile
      if (user?.plan_details?.status === "CANCELED") {
        dispatch(openAlert({
          alertType: "plan-canceled",
          isAlertOpen: true
        }))
      }
      if (user?.plan_details?.status === "WAITING") {
        dispatch(openAlert({
          alertType: "plan-waiting",
          isAlertOpen: true
        }))
      }
      dispatch(setUser({
        ...user
      }))
    } catch (e) {
      // console.log("getUserData / error: ", e)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  useSubscription(LISTEN_OPEN_INCIDENTS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.openIncidents?.total) {
        setOpenIncidents(data?.openIncidents?.total)
      }

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_UNREAD_MESSAGES_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {

      if (data?.unreadMessages?.total) {
        setUnreadMessages(data?.unreadMessages?.total)
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_RUNNING_TASKS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.runningTasks?.total) {
        setTasks(data?.runningTasks?.total)
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_PENDING_TASKS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.pendingTasks?.total) {
        setPendingTasks(data?.pendingTasks?.total)
      }

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_COORDS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      // console.log("LISTEN_COORDS_SUBSCRIPTION / data: ", data)
      // console.log("LISTEN_COORDS_SUBSCRIPTION / error: ", error)
      if (data?.newCoord?.journeys?.length > 0) {
        const items = data?.newCoord?.journeys
        // const hasCoords = items.filter(item => item?.coords[0]?.x && item?.coords[0]?.y)
        // if (hasCoords.length > 0) {
        //   setLastCoordLat(hasCoords[0]?.coords[0]?.x)
        //   setLastCoordLong(hasCoords[0]?.coords[0]?.y)
        // }

        setMapCoords(fixTasks(items))
      } else {
        getStats()
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const fixTasks = (tasks) => tasks.map(item => {
    return {
      position: item?.coords[0]?.x && item?.coords[0]?.y ? [item?.coords[0]?.x, item?.coords[0]?.y] : [],
      details: {
        task: item.tasks && item.tasks.name ? item.tasks.name : "",
        driver: item.driver || "N/A",
        vehicle: item.vehicle || "N/A",
        trailer: item.trailer || "N/A"
      },
      icon: divIcon({
        html: renderToStaticMarkup(<img alt="" src={
          item.incidents > 0
            ? redPinIcon
            : item.pending //is free
              ? bluePinIcon :
              !item.pending
                ? greenPinIcon
                  : bluePinIcon
        } />),
      }),
      _id: item.tasks && item.tasks._id ? item.tasks._id : ""
    }
  }).filter(item => item.position.length > 0)

  const getStats = async () => {
    try {
      const runningTasks = await getRunningTasksAction()
      if (runningTasks?.data?.runningTasks?.total) {
        setTasks(runningTasks?.data?.runningTasks?.total)
      }

      const pendingTasks = await getPendingTasksAction()
      if (pendingTasks?.data?.pendingTasks?.total) {
        setPendingTasks(pendingTasks?.data?.pendingTasks?.total)
      }

      const openIncidents = await getOpenIncidentsAction()
      if (openIncidents?.data?.openIncidents?.total) {
        setOpenIncidents(openIncidents?.data?.openIncidents?.total)
      }

      const unreadMessages = await getUnreadMessagesAction()
      if (unreadMessages?.data?.unreadMessages?.total) {
        localStorage.setItem("numberOfUnreadMessages", String(unreadMessages?.data?.unreadMessages?.total))
        setUnreadMessages(unreadMessages?.data?.unreadMessages?.total)
      }

      const mapCoords = await getMapCoordsAction()

      let items = []
      if (mapCoords?.data?.map) {
        items = mapCoords?.data?.map.journeys
      }

      // const hasCoords = items.filter(item => item?.coords[0]?.x && item?.coords[0]?.y)
      // if (hasCoords.length > 0) {
      //   setLastCoordLat(hasCoords[0]?.coords[0]?.x)
      //   setLastCoordLong(hasCoords[0]?.coords[0]?.y)
      // }
      // console.log("items: ", items)
      setMapCoords(fixTasks(items))
    } catch (e) {
      // console.log("getStats / error: ", e)
    }
  }

  const stats = [
    {
      title: i18n.t('HOME.stats.stat1Title'),
      value: tasksInProcess,
      icon: inProcessIcon,
      onClick: () => navigate(`${TASKS_ROUTE}/?filter=process`)
    },
    {
      title: i18n.t('HOME.stats.stat2Title'),
      value: pendingTasks,
      icon: pendingTasksIcon,
      onClick: () => navigate(`${TASKS_ROUTE}/?filter=pending`)
    },
    {
      title: i18n.t('HOME.stats.stat3Title'),
      value: openIncidents,
      icon: issuesIcon,
      onClick: () => navigate(ISSUES_ROUTE)
    },
    {
      title: i18n.t('HOME.stats.stat4Title'),
      value: unreadMessages,
      icon: messagesIcon,
      onClick: () => navigate(CHAT_ROUTE)
    },
  ]

  // const center = [lastCoordLat || 0.4115, lastCoordLong || -2.7076]
  // const center = [40.416775, -3.703790]
  const center = user?.country_user?.latitude && user?.country_user?.longitude ? [user.country_user.latitude, user.country_user.longitude] : [40.416775, -3.703790]
  // console.log("center: ", center)
  // console.log("user: ", user)
  // console.log("mapCoords: ", mapCoords)
  return (
    <UserHomeContainer>
      <UserHomeContentContainer>
        <HomeStatsContainerGeneral>
          {stats.map((item, i) =>
            <HomeStatContainer onClick={item.onClick} key={i}>
              <HomeStatTitle>
                {item.title}
              </HomeStatTitle>
              <HomeStatContentContainer>
                <HomeStatNumber>
                  {item.value}
                </HomeStatNumber>
                <HomeStatIcon alt="" src={item.icon} />
              </HomeStatContentContainer>
            </HomeStatContainer>
          )}
        </HomeStatsContainerGeneral>
        <MapContainer
          style={{ width: '100%', height: '500px', marginTop: 20, borderRadius: 15 }}
          center={center}
          zoom={5}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {mapCoords?.map((item, i) =>
            <Marker key={i} icon={item.icon} color="red" position={item.position}>
              <Popup>
                <TaskDetailsContainer>
                  <TaskDetailsItemsContainer>
                    {item.details.task && <TaskDetailsItemContainer>
                      <TaskDetailsTitle>
                        {i18n.t('HOME.taskdetails.task')}:
                      </TaskDetailsTitle>
                      <TaskDetailsContentLink onClick={() => navigate(`${TASK_DETAILS_ROUTE}/${item._id}`)}>
                        {item.details.task}
                      </TaskDetailsContentLink>
                    </TaskDetailsItemContainer>}
                    <TaskDetailsItemContainer>
                      <TaskDetailsTitle>
                        {i18n.t('HOME.taskdetails.driver')}:
                      </TaskDetailsTitle>
                      <TaskDetailsContent>
                        {item.details.driver}
                      </TaskDetailsContent>
                    </TaskDetailsItemContainer>
                    <TaskDetailsItemContainer>
                      <TaskDetailsTitle>
                        {i18n.t('HOME.taskdetails.vehicle')}:
                      </TaskDetailsTitle>
                      <TaskDetailsContent>
                        {item.details.vehicle}
                      </TaskDetailsContent>
                    </TaskDetailsItemContainer>
                    <TaskDetailsItemContainer>
                      <TaskDetailsTitle>
                        {i18n.t('HOME.taskdetails.trailer')}:
                      </TaskDetailsTitle>
                      <TaskDetailsContent>
                        {item.details.trailer || "N/A"}
                      </TaskDetailsContent>
                    </TaskDetailsItemContainer>
                  </TaskDetailsItemsContainer>
                </TaskDetailsContainer>
              </Popup>
            </Marker>
          )}
        </MapContainer>
        <InfoItemsContainer>
          <InfoContainer>
            <ColorSquare style={{ backgroundColor: COLORS_TRUCK.BLUE_TRUCK }} />
            <InfoContentParagraph>
              {/* {i18n.t('HOME.map.load')} */}
              {i18n.t('HOME.freeDriver')}
            </InfoContentParagraph>
          </InfoContainer>
          <InfoContainer>
            <ColorSquare style={{ backgroundColor: COLORS_TRUCK.GREEN_TRUCK }} />
            <InfoContentParagraph>
              {i18n.t('HOME.process')}
              {/* {i18n.t('HOME.map.downlaod')} */}
            </InfoContentParagraph>
          </InfoContainer>
          <InfoContainer>
            <ColorSquare style={{ backgroundColor: COLORS_TRUCK.ORANGE_TRUCK }} />
            <InfoContentParagraph>
              {i18n.t('HOME.map.issue')}
            </InfoContentParagraph>
          </InfoContainer>
          {/* <InfoContainer>
            <ColorSquare style={{ backgroundColor: COLORS_TRUCK.BLACK_TRUCK }} />
            <InfoContentParagraph>
              {i18n.t('HOME.map.finished')}
            </InfoContentParagraph>
          </InfoContainer> */}
        </InfoItemsContainer>
      </UserHomeContentContainer>
    </UserHomeContainer>
  )
}

export default UserHomeScreen
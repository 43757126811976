import { gql } from '@apollo/client';

export const GET_USERS_INVOICES_MUTATION = gql`
query invoices($input: InvoiceFiltersInput, $user_id: ObjectId) {
  invoices(input: $input, user_id: $user_id) {
    _id
    amount
    company
    concept {
      de
      es
      fr
      pt
    }
    date
    discount
    final_amount
    id_number
    max_users
    payment_method
    payment_type
    plan_price
    status
    tax
    user {
      _id
      name
      surnames
    }
    users_price
  }
}
`;

export const DOWNLOAD_USER_INVOICE_MUTATION = gql`
query downloadInvoice($invoice_id: ObjectId!) {
  downloadInvoice(invoice_id: $invoice_id) {
    fullpath
        path
        status
  }
}
`;

export const EXPORT_USERS_INVOICES_MUTATION = gql`
query exportInvoices($input: InvoiceFiltersInput) {
  exportInvoices(input: $input){
        fullpath
        path
        status
  }
}
`;

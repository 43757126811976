import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import i18n from '../../i18n/i18n';

const initialState = {
  isMenuOpen: false,
  isAlertOpen: false,
  alertOnYes: () => null,
  alertType: "error",
  alertOnYesButtonTitle: "",
  alertInputPlaceholder: "",
  errorCode: "",
  alertMessage: i18n.t("MESSAGES.internalError"),
  alertImage: "",
  coords: {}
};

type App = {
  isMenuOpen: boolean;
  isAlertOpen: boolean;
  coords: object;
  alertType: string;
  alertMessage: string;
  errorCode: string;
  alertOnYes: Function;
  alertOnYesButtonTitle: string;
  alertInputPlaceholder: string;
  alertImage: string;
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    toggleMenu: (state, action: PayloadAction<App>) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    openMenu: (state, action: PayloadAction<App>) => {
      state.isMenuOpen = true;
    },
    closeMenu: (state, action: PayloadAction<App>) => {
      state.isMenuOpen = false;
    },
    openAlert: (state, action: PayloadAction<App>) => {
      if(action.payload.alertType){
        state.alertType = action.payload.alertType;
      }

      if(action.payload.alertMessage){
        state.alertMessage = action.payload.alertMessage;
      }

      if(action.payload.isAlertOpen){
        state.isAlertOpen = action.payload.isAlertOpen;
      }

      if(action.payload.errorCode){
        state.errorCode = action.payload.errorCode;
      }

      if(action.payload.alertOnYes){
        state.alertOnYes = action.payload.alertOnYes;
      }

      if(action.payload.alertInputPlaceholder){
        state.alertInputPlaceholder = action.payload.alertInputPlaceholder;
      }

      if(action.payload.alertOnYesButtonTitle){
        state.alertOnYesButtonTitle = action.payload.alertOnYesButtonTitle;
      }

      if(action.payload.alertImage){
        state.alertImage = action.payload.alertImage;
      }

      if(action.payload.coords){
        state.coords = action.payload.coords;
      }
    },
    closeAlert: (state, action: PayloadAction<App>) => {
      state.isAlertOpen = false;
    },
  },
});

// ACTIONS
export const {toggleMenu, openMenu, closeMenu, openAlert, closeAlert} =
  appSlice.actions;

// SELECTORS
// export const userInfoSelector = (state: RootState): any => state.appSlice;

export default appSlice.reducer;

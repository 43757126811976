import client from '../../utils/api';
import {
  LOGOUT_MUTATION
} from './side-menu-queries';

export const logoutAction = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client.mutate({
          mutation: LOGOUT_MUTATION,
        });
        removeToken()
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
};
  
const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('confirm-email-code');
    localStorage.removeItem('error-code');
}

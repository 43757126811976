import React, { useEffect, useState } from "react";
import {
  HomeContainer,
  PlansContentContainer,
  HomeDescription,
  Button,
  ButtonText,
  OpacityContainer,
  PlansButtonContainer,
  PlanItemContainer,
  PlansItemsContainer,
  PlanTitle,
  PlanIcon,
  PlanUserIcon,
  PlanItemDescriptionContainer,
  PaddingTextContainer
} from "./plans-styles";
import { getPlans } from "../signup/signup-actions"
import i18n from '../../i18n/i18n';
import { COLORS_TRUCK } from "../../utils/colors";
// import bronzeIcon from "../../assets/plans/bronze-icon.svg";
// import silverIcon from "../../assets/plans/silver-icon.svg";
// import goldIcon from "../../assets/plans/gold-icon.svg";
import bronzeUserIcon from "../../assets/plans/bronze-user-icon.svg";
import silverUserIcon from "../../assets/plans/silver-user-icon.svg";
import goldUserIcon from "../../assets/plans/gold-user-icon.svg";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { useNavigate } from "react-router-dom";
import { SIGNUP_ROUTE } from "../../routes/routes";
import config from "../../config";

const PlansScreen = () => {
  const navigate = useNavigate();
  const [activePlanType, setActivePlanType] = useState("MONTHLY")
  const [plans, setPlans] = useState([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    getPlansFunc()
  }, [])

  const getPlansFunc = async () => {
    try {
      const response = await getPlans()
      if (response && response.data && response.data.plans) {
        setPlans(response.data.plans)
      }
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }
  return (
    <HomeContainer>
      <OpacityContainer>
        <PlansContentContainer>
          <PaddingTextContainer>

            <HomeDescription>
              {i18n.t('PLANS.description')}
            </HomeDescription>
            <PlansButtonContainer>
              <Button
                onClick={() => {
                  setActivePlanType('MONTHLY')
                  setPlans(plans)
                }}
                style={{ background: activePlanType === "MONTHLY" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.WHITE_TRUCK }}
              >
                <ButtonText
                  style={{ color: activePlanType === "MONTHLY" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK }}
                >
                  {i18n.t('PLANS.monthly')}
                </ButtonText>
              </Button>
              <Button
                onClick={() => {
                  setActivePlanType('ANNUALLY')
                  setPlans(plans)
                }}
                style={{ background: activePlanType === "ANNUALLY" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.WHITE_TRUCK }}
              >
                <ButtonText
                  style={{ color: activePlanType === "ANNUALLY" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK }}
                >
                  {i18n.t('PLANS.anual')}
                </ButtonText>
              </Button>
            </PlansButtonContainer>
            <HomeDescription style={{ color: COLORS_TRUCK.ORANGE_TRUCK }}>
              {activePlanType === "ANNUALLY" ? i18n.t('PLANS.subcription-info-annually') : i18n.t('PLANS.subcription-info')}
            </HomeDescription>
          </PaddingTextContainer>
          <PlansItemsContainer>
            {plans.map(item =>
              <PlanItemContainer>
                <PlanTitle style={{ marginBottom: 10 }}>
                  {item.translate[0].langs[String(localStorage.getItem("language") || "es")]}
                </PlanTitle>
                <PlanIcon src={item?.icon?.fullpath} />
                {/* <HomeDescription>
                  {activePlanType === "ANNUALLY" ? item.prices.annual : item.prices.monthly}€
                </HomeDescription>
                + */}
                <HomeDescription>
                  {`${activePlanType === "ANNUALLY" ? item.prices.user_annual : item.prices.user_monthly}€/${i18n.t('PLANS.user')}`}
                </HomeDescription>
                <PlanItemDescriptionContainer>
                  <PlanUserIcon src={item._id === config.BRONZE_PLAN_ID ? bronzeUserIcon : item._id === config.SILVER_PLAN_ID ? silverUserIcon : item._id === config.GOLD_PLAN_ID ? goldUserIcon : ""} />
                  <PlanTitle style={{ fontSize: 20, width: 150, textAlign: 'center' }}>
                    {item._id === config.BRONZE_PLAN_ID ?
                      `${i18n.t('PLANS.bronzePlanText')} ${item.max_users} ${i18n.t('PLANS.users')}`
                      : item._id === config.SILVER_PLAN_ID
                        ? `${i18n.t('PLANS.silverPlanText1')} ${item.min_users} ${i18n.t('PLANS.silverPlanText2')} ${item.max_users} ${i18n.t('PLANS.users')}`
                        : item._id === config.GOLD_PLAN_ID
                          ? `${i18n.t('PLANS.goldPlanText1')} ${item.min_users} ${i18n.t('PLANS.users')}`
                          : null}
                  </PlanTitle>
                </PlanItemDescriptionContainer>
                <Button onClick={() => {
                  localStorage.removeItem("token")
                  localStorage.removeItem("confirm-email-code")
                  navigate(SIGNUP_ROUTE)
                }}>
                  <ButtonText>
                    {i18n.t('PLANS.signup')}
                  </ButtonText>
                </Button>
              </PlanItemContainer>
            )}
          </PlansItemsContainer>
        </PlansContentContainer>
      </OpacityContainer>
    </HomeContainer>
  )
}

export default PlansScreen
import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileFormContainer,
  ProfileBillingContainer,
  ProfileTableContainer,
  TableTitle,
  TableInfoContainer
} from "./billing-styles";
import i18n from '../../../i18n/i18n';
import CategoryButton from "../../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../../assets/profile/search-icon.svg";
import TruckTable from "../../../components/truck-table/TruckTable";
import {
  downloadUserInvoceAction,
  getUsersInvoicesAction,
  exportUsersInvocesAction
} from "./billing-actions"
import TextInput from "../../../components/inputs/text-input/TextInput";
import { openAlert } from "../../../redux/reducers/app.ts";
import { useAppDispatch } from "../../../redux/store.ts";

const BillingScreen = () => {
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [search, setSearch] = useState("")
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [filterBy, setFilterBy] = useState("1")
  const [status, setStatus] = useState("")
  const dispatch = useAppDispatch()

  useEffect(() => {
    getUsersInvoicesFunc()
  }, [search, filterBy])

  const getUsersInvoicesFunc = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        status: status || null,
        company: search || null
      }
      const res = await getUsersInvoicesAction(data)
      if (res?.data?.invoices.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let invoicesArray = invoices.concat(res?.data?.invoices)
      if (!search && !status && !reset) {
        setInvoices(invoicesArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        setInvoices(res?.data?.invoices)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      // if (errorCode === "998") {
      //   setUsers([])
      // }
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // setUsers([])
      getUsersInvoicesFunc()
    }
  };

  const exportUsersInvoicesFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        status: status || null,
        company: search || null
      }
      const res = await exportUsersInvocesAction(data, "")
      var win = window.open(res.data.exportInvoices.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }
  const downloadUserInvoiceFunc = async (invoice_id) => {
    try {
      const res = await downloadUserInvoceAction(invoice_id)
      var win = window.open(res.data.downloadInvoice.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const header = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('BILLING.forms.billing.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={filterBy}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        if (value === "PAID" || value === "UNPAID") {
          // setUsers([])
          setStatus(value)
          setFilterBy(value)
          // getVehiclesFunc()
          setSearch("")
        }
        if (value === "" || value === "1") {
          // setUsers([])
          setStatus("")
          setFilterBy("1")
          setSearch("")
          // getVehiclesFunc()
        }
      }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('BILLING.forms.billing.buttons.filterBy'), icon: filterByIcon },
        { value: 'PAID', label: i18n.t('BILLING.forms.billing.buttons.paid'), icon: filterByIcon },
        { value: 'UNPAID', label: i18n.t('BILLING.forms.billing.buttons.unpaid'), icon: filterByIcon },
      ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('PROFILE.forms.billing.buttons.search')}
      onChange={async (e) => {
        if (e.target.value === "") {
          getUsersInvoicesFunc(true)
        }

        setSearch(e.target.value)

      }}
      onKeyPress={handleKeyPress}
    // disabled={loading}
    />
    <CategoryButton
      onClick={() => exportUsersInvoicesFunc()}
      active={false}
      icon=""
      title={i18n.t('BILLING.forms.billing.buttons.export')}
    />
    {/* <CategoryButton
      onClick={() => null}
      active
      icon=""
      title={i18n.t('BILLING.forms.billing.buttons.add')}
    /> */}
  </ProfileBillingContainer>
  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <ProfileTableContainer>
            <TruckTable
              type="billing-super-admin"
              titles={[
                { label: i18n.t('BILLING.forms.billing.client') },
                { label: i18n.t('BILLING.forms.billing.company') },
                { label: i18n.t('BILLING.forms.billing.concept') },
                { label: i18n.t('BILLING.forms.billing.expiration') },
                { label: i18n.t('BILLING.forms.billing.date') },
                { label: i18n.t('BILLING.forms.billing.state') },
                { label: i18n.t('BILLING.forms.billing.finalAmount') },
                { label: i18n.t('BILLING.forms.billing.actions') }
              ]}
              data={invoices}
              header={header}
              downloadInvoice={(invoice_id) => downloadUserInvoiceFunc(invoice_id)}
              style={{
                maxHeight: 700,
                overflow: "auto"
              }}
              onEndReach={() => {
                if (keepIncreasing) {
                  setOffset(offset + increaseLimit)
                  setLimit(limit + increaseLimit)
                }

                getUsersInvoicesFunc()
              }}
            />
          </ProfileTableContainer>
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default BillingScreen
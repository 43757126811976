import { gql } from '@apollo/client';

export const CREATE_ISSUE_MUTATION = gql`
mutation createIncident($files: [Upload!], $input: CreateIncidentInput!) {
  createIncident(files: $files, input: $input){
   _id
  }
}
`;

export const UPDATE_ISSUE_MUTATION = gql`
mutation updateIncident($input: UpdateIncidentInput!, $incident_id: ObjectId!) {
  updateIncident(input: $input, incident_id: $incident_id){
   _id
  }
}
`

export const UPDATE_ISSUE_PICTURE_MUTATION = gql`
mutation updateIncidentImage($file: Upload!, $input: UpdateObjectInput!) {
  updateIncidentImage(file: $file, input: $input){
   _id
  }
}
`

export const REMOVE_ISSUE_MUTATION = gql`
mutation deleteIncident($incident_id: ObjectId!) {
  deleteIncident(incident_id: $incident_id){
        _id
  }
}
`;

export const GET_ISSUES_MUTATION = gql`
query myIncidents($input: IncidentFiltersInput) {
  myIncidents(input: $input) {
    _id
    comment
    creation_date
    driver
    driver_id
    id_number
    end_date
    images {
      _id
      fullpath
      path
    }
    status
    task
    task_id
    trailer
    trailer_id
    type
    vehicle
    vehicle_id
    checklist {
      epis {
        gloves
        helmet
        high_visibility_garment
        masks
        others
        protective_screen
        safety_footwear
      }
      vehicle {
        batteries
        fire_extinguishers
        luminary
        mirrors
        tire_pressure
        windshield
        windshield_washer
      }
    }
  }
}
`;

export const EXPORT_ISSUES_MUTATION = gql`
query exportMyIncidents($input: IncidentFiltersInput) {
  exportMyIncidents(input: $input){
        fullpath
        path
        status
  }
}
`;
import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const HeaderContainer = styled.div`
background: ${COLORS_TRUCK.WHITE_TRUCK};
padding-left: 20px;
padding-right: 20px;
display: flex;
justify-content: space-between;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
position: relative;

@media (max-width: 600px) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 600px) {
  height: 10vh;
}
`;

export const HeaderButtonsContainer = styled.div`
 display: flex;
 align-items: center;
`;

export const LanguageButtonContainer = styled.div`
 display: flex;
 align-items: center;
 margin-left: 50px;
 margin-right: 50px;
 cursor: pointer;
 
 @media (max-width: 420px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const TruckRedLogo = styled.img`
width: 150px;
cursor: pointer;
@media (max-width: 765px) {
    width: 120px;
  }
@media (max-width: 365px) {
    width: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const TextAsButton = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
margin-left: 10px;
margin-right: 10px;
cursor: pointer;
`;

export const UserIcon = styled.img`
width: 20px;
height: 20px;
margin-left: 10px;
margin-right: 10px;
`;

export const MenuButton = styled.img`
width: 30px;
height: 30px;
cursor: pointer;
@media (min-width: 1200px) {
  display: none;
}
`;

export const ArrowDownIcon = styled.img`
width: 10px;
height: 10px;
`;
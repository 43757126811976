import React from "react";
import { RestorePasswordContainer, RestorePasswordButtonsContainer, RestorePasswordTitle, RestorePasswordDescription, BackButtonContainer } from "./restore-password-screen-styles";
import TextInput from "../../components/inputs/text-input/TextInput";
import passwordIcon from "../../assets/inputs/lock.svg";
import { Button, ButtonText } from "../home/home-styles";
import i18n from '../../i18n/i18n';
import { LOGIN_ROUTE, ENTER_CODE_ROUTE } from "../../routes/routes";
import BackButton from "../../components/back-button/BackButton";
// import SafeLINK from "../../utils/safe-link";
import { useNavigate } from "react-router-dom";
import { forgotPasswordAction } from "./forgot-password-actions";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { emailsRegex } from "../../utils/regex";
import Loader from "../../components/loader/Loader";

const RestorePasswordScreen = () => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const forgotEmail = async () => {
    localStorage.removeItem("confirm-email-code")
    if (email && !email.match(emailsRegex)) {
      dispatch(openAlert({
        alertType: "error",
        alertMessage: i18n.t("GENERAL.emailInvalid"),
        isAlertOpen: true
      }))
      return false;
    }

    try {
      setLoading(true);
      await forgotPasswordAction(email)
      navigate(ENTER_CODE_ROUTE)
      setLoading(false);
    } catch (e) {
      const errorCode = localStorage.getItem("error-code")

      if (errorCode === "988") {
        navigate(ENTER_CODE_ROUTE)
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true
        }))
      }
    }
  }

  return (
    <RestorePasswordContainer>
      <RestorePasswordButtonsContainer>
        <BackButtonContainer>
          <BackButton route={LOGIN_ROUTE} />
        </BackButtonContainer>
        <RestorePasswordTitle>
          {i18n.t('RESTORE_PASSWORD.title')}
        </RestorePasswordTitle>
        <RestorePasswordDescription>
          {i18n.t('RESTORE_PASSWORD.description')}
        </RestorePasswordDescription>
        <TextInput
          icon={passwordIcon}
          placeholder={i18n.t('LOGIN.emailPlaceholder')}
          value={email}
          disabled={loading}
          onChange={({ target: { value } }) => setEmail(value)}
        />

        <Button
          onClick={() => forgotEmail()} style={{ width: 300, marginTop: 20, marginBottom: 20 }}
          disabled={loading}
        >
          {loading ?
            <Loader width={15} height={15} />
            : <ButtonText>
              {i18n.t('RESTORE_PASSWORD.requestCode')}
            </ButtonText>}
        </Button>
      </RestorePasswordButtonsContainer>
    </RestorePasswordContainer>
  )
}

export default RestorePasswordScreen
import { gql } from '@apollo/client';

export const CREATE_PAYMENT_CONCEPT_MUTATION = gql`
mutation createAccounting($input: CreateAccountingInput!) {
  createAccounting(input: $input){
   _id
  }
}
`;

export const UPDATE_PAYMENT_CONCEPT_MUTATION = gql`
mutation updateAccounting($accounting_id: ObjectId!, $input: UpdateAccountingInput!) {
  updateAccounting(accounting_id: $accounting_id, input: $input){
   _id
  }
}
`;

export const DELETE_PAYMENT_CONCEPT_MUTATION = gql`
mutation deleteAccounting($accounting_id: ObjectId!) {
  deleteAccounting(accounting_id: $accounting_id){
   _id
  }
}
`;

export const CALCULATE_PAYMENT_CONCEPT_MUTATION = gql`
mutation calculateAccounting($input: CalculateAccountingInput!) {
  calculateAccounting(input: $input){
    journeys {
      _id
      initial_kms 
      observations
      timezone_end
      timezone_start
      gps_info {
        calculations {
          distance
          dinner
          lunch
          dinner_country {
            name
          }
          dinner_state {
            name
          }
          lunch_country {
            name
          }
          lunch_state {
            name
          }
          nocturnity_international
          nocturnity_national
        }
      }
      creation_datetime
      finish_datetime
      driver
      coords {
        country
        x
        y
      }
      status
      vehicle
      trailer
      checklist {
        epis {
          gloves
          helmet
          high_visibility_garment
          masks
          others
          protective_screen
          safety_footwear
        }
        vehicle {
          batteries
          fire_extinguishers
          luminary
          mirrors
          tire_pressure
          windshield
          windshield_washer
        }
      }
    }
    tasks {
      _id
      company_id
      coords {
        country
        x
        y
      }
      driver
      driver_id
      end_date
      id_number
      incidents
      name
      start_date
      status
      trailer
      trailer_id
      vehicle
      vehicle_id
      loads {
        _id
        address
        comments
        coords
        date
        phone
        place
        ref
        timetable
      }
      unloads {
       _id
       address
       comments
       coords
       date
       phone
       place
       ref
       timetable
      }
    }
    completes_total
    diets_total
    sundays_total
    nocturnal_total
    estimated_kms
    payment_per_kms
    extra_remuneration
    fixed_remuneration
    variable_remuneration
    total
    tasks {
      _id
      coords {
        country
        x
        y
      }
      driver
      driver_id
      end_date
      incidents
      name
      start_date
      status
      trailer
      trailer_id
      loads {
        _id
        address
        comments
        coords
        date
        phone
        place
        ref
        timetable
      }
      unloads {
       _id
       address
       comments
       coords
       date
       phone
       place
       ref
       timetable
      }
      vehicle
      vehicle_id
    }
  }
}
`;

export const GET_PAYMENTS_CONCEPTS_MUTATION = gql`
query myAccounting($input: AccountingFiltersInput) {
  myAccounting(input: $input) {
    _id
    concept
    driver
    driver_id
    end_date
    extra_remuneration
    fixed_remuneration
    start_date
    total
    payment_per_kms
    variable_remuneration
    variable_type
  }
}
`;

export const EXPORT_PAYMENTS_CONCEPTS_MUTATION = gql`
query exportMyAccounting($input: AccountingFiltersInput) {
  exportMyAccounting(input: $input) {
    fullpath
    path
    status
  }
}
`;

export const EXPORT_DRIVER_JOURNEYS_MUTATION = gql`
query exportMyJourneys($driver_id: ObjectId, $input: JourneyFiltersInput) {
  exportMyJourneys(driver_id: $driver_id, input: $input) {
    fullpath
    path
    status
  }
}
`;
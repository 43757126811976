import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileFormContainer,
  ProfileBillingContainer,
  TableTitle,
  TableInfoContainer
} from "./payment-concept-list-styles";
import i18n from '../../i18n/i18n';
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import { PAYMENT_CONCEPT } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/inputs/text-input/TextInput";
import { getCoinAction } from "../profile/profile-actions";
import { getPaymentsConceptsAction, exportPaymentsConcepts } from "../payment-concept/payment-concept-actions";

const PaymentConceptsList = () => {
  const [billingFilterBy, setBillingFilterBy] = useState("")
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [payments, setPayments] = useState([])
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [loading, setLoading] = useState(false)
  const [coinOption, setCoinOption] = useState({})

  useEffect(() => {
    if (user?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    getCoins()
    getPaymentConcepts()
  }, [billingFilterBy, search])

  const getCoins = async () => {
    try {
      const response = await getCoinAction()
      const cointSelected = response.data.findAllCoins.filter( coin => coin._id == user.coin )

      if(cointSelected.length > 0) setCoinOption(cointSelected[0])
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getPaymentConcepts = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        concept: !reset ? search || null : null,
        driver: !reset ? search || null : null,
        variable_type: !reset ? billingFilterBy || null : null,
      }
      const res = await getPaymentsConceptsAction(data)
      if (res?.data?.myAccounting.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let paymentsArray = payments.concat(res?.data?.myAccounting)
      if (!search && !billingFilterBy && !reset) {
        setPayments(paymentsArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        setPayments(res?.data?.myAccounting)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") {
        setPayments([])
      }
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setPayments([])
      getPaymentConcepts()
    }
  };

  const exportPaymentsConceptsFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        concept: search || null,
        driver: search || null,
        variable_type: billingFilterBy || null,
      }
      const res = await exportPaymentsConcepts(data)
      var win = window.open(res.data.exportMyAccounting.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }



  const header = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('ACCOUNTING.forms.accounting.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={billingFilterBy}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => setBillingFilterBy(value)}
      icon={filterByIcon}
      items={[
        { value: '', label: i18n.t('ACCOUNTING.forms.accounting.buttons.filterBy'), icon: filterByIcon },
        { value: 'DIETS', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType1diets') },
        { value: 'MILEAGE', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType2km') },
        { value: 'BOTH', label: i18n.t('DRIVER_DETAILS.forms.variables.both') },
      ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('ISSUES.forms.issues.buttons.search')}
      onChange={async (e) => {
        setSearch(e.target.value)
        if (e.target.value === "") {
          getPaymentConcepts(true)
        }
      }}
      onKeyPress={handleKeyPress}
    // disabled={loading}
    />
    <CategoryButton
      onClick={() => exportPaymentsConceptsFunc()}
      active={false}
      icon=""
      title={i18n.t('ACCOUNTING.forms.accounting.buttons.export')}
    />
    <CategoryButton
      onClick={() => navigate(PAYMENT_CONCEPT)}
      active
      icon=""
      title={i18n.t('ACCOUNTING.forms.accounting.buttons.add')}
    />
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="accounting"
            header={header}
            titles={[
              { label: i18n.t('ACCOUNTING.forms.accounting.name') },
              { label: i18n.t('ACCOUNTING.forms.accounting.driver') },
              { label: i18n.t('ACCOUNTING.forms.accounting.variableType') },
              { label: i18n.t('ACCOUNTING.forms.accounting.fixed') },
              { label: `${i18n.t('ACCOUNTING.forms.accounting.variable')} (${coinOption?.symbol ?? '€'})` },
              { label: i18n.t('ACCOUNTING.forms.accounting.extra') },
              { label: `${i18n.t('ACCOUNTING.forms.accounting.total')} (${coinOption?.symbol ?? '€'})` },
              { label: i18n.t('ACCOUNTING.forms.accounting.actions') }
            ]}
            updateTable={getPaymentConcepts}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(offset + increaseLimit)
                setLimit(limit + increaseLimit)
              }

              getPaymentConcepts()
            }}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
            data={payments}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default PaymentConceptsList
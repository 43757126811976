import React from "react";
import {
  HeaderContainer,
  TruckRedLogo,
  HeaderButtonsContainer,
  UserIcon,
  TextAsButton,
  LanguageButtonContainer,
  MenuButton
  // ArrowDownIcon
} from "./header-styles";
import truckRedLogo from "../../assets/home-header/truck-logo-red.svg"
import userIcon from "../../assets/home-header/user.svg"
import menuIcon from "../../assets/home-header/menu-icon.svg"
// import arrowBlackIcon from "../../assets/home-header/black-arrow-down.svg"
import SafeLINK from "../../utils/safe-link";
import { LOGIN_ROUTE } from "../../routes/routes";
import i18n from '../../i18n/i18n';
import ReactFlagsSelect from 'react-flags-select';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/store.ts";
import { toggleMenu } from "../../redux/reducers/app.ts";
import { useSelector } from "react-redux";

const HomeScreen = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  //  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  return (
    <HeaderContainer>
      <TruckRedLogo onClick={() => navigate('/')} src={truckRedLogo} />
      <HeaderButtonsContainer>
        {user.rol && <UserIcon src={userIcon} />}
        {user.rol ? <SafeLINK
          dangerousLINK={LOGIN_ROUTE}
          text={
            <TextAsButton>
              {user.rol === "superadmin" ? i18n.t('HEADER.superadmin') : i18n.t('HEADER.admin')}
            </TextAsButton>
          }
        />
          : null 
          // <SafeLINK
          //   dangerousLINK={LOGIN_ROUTE}
          //   text={
          //     <TextAsButton>
          //       {i18n.t('HEADER.login')}
          //     </TextAsButton>
          //   }
          // />
          }
        <LanguageButtonContainer>
          <ReactFlagsSelect
            // ref={this.userFlag}
            className="language-picker"
            defaultCountry={String(localStorage.getItem("language") || "ES").toUpperCase()}
            countries={["ES", "GB", "DE", "FR", "PT", "IT"]}
            // selected={(localStorage.getItem("language") || "ES").toUpperCase()}
            showSelectedLabel={false}
            showOptionLabel={false}
            placeholder={(localStorage.getItem("language") || "ES").toUpperCase()}
            onSelect={(selected) => {
              localStorage.setItem("language", String(selected === "GB" ? "EN" : selected).toLowerCase())
              window.location.reload(false);
            }}
          />
          {/* <TextAsButton>
            {i18n.t('HEADER.es')}
          </TextAsButton> */}
          {/* <ArrowDownIcon src={arrowBlackIcon} /> */}
        </LanguageButtonContainer>
        {user.rol && <MenuButton onClick={() => dispatch(toggleMenu())} src={menuIcon} />}
      </HeaderButtonsContainer>
    </HeaderContainer>
  )
}

export default HomeScreen
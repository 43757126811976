import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ENTER_CODE_ROUTE, LOGIN_ROUTE, RESTORE_PASSWORD_ROUTE, ROOT_ROUTE } from '../routes/routes';

class SafeLINK extends Component {
  isSafe(dangerousLINK) {
    const url = dangerousLINK;
    if (url === ROOT_ROUTE) return true;
    if (url === LOGIN_ROUTE ) return true;
    if (url === RESTORE_PASSWORD_ROUTE ) return true;
    if (url === ENTER_CODE_ROUTE ) return true;
    return false;
  }
  render() {
    const dangerousLINK = this.props.dangerousLINK;
    const safeLINK = this.isSafe(dangerousLINK) ? dangerousLINK : '';
    return (
      <Link
        to={safeLINK}
        style={this.props.style}
        className={this.props.className}
        onClick={this.props.onClick}
        target={this.props.newTab ? "_blank" : ""}
        rel={this.props.newTab ? "noopener norefer" : ""}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onTouchStart={this.props.onTouchStart}
        onTouchEnd={this.props.onTouchEnd}
      >
        {this.props.text ?? this.props.children}
      </Link>
    );
  }
}

export default SafeLINK;

import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const VehicleDetailsContainer = styled.div`
width: 100%;
height: 100%;
`;

export const VehicleDetailsContentContainer = styled.div`
width: 100%;
height: 100%;
flex-direction: column;
max-width: 1000px;
margin: auto;
padding-top: 30px;
`;

export const VehicleDetailsFormsContainer = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;

@media (max-width: 1200px) {
  align-items: center;
justify-content: center;
}
`;

export const VehicleDetailsFormInputsContainer = styled.div`
width: 450px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const TableInfoContainer = styled.p`
margin-right: 20px;
display: flex;
flex-direction: column;
`;

export const VehicleDetailsDateInputsContainer = styled.p`
display: flex;
`;

export const TableTitle = styled.p`
font-weight: bold;
margin: 10px;
margin-top: 30px;
`;

export const TableInfo = styled.p`
margin: 10px;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin-right: 20px;
margin: 0;
`;

export const ProfileLastButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;

@media (min-width: 1200px) {
  justify-content: flex-end;
  padding-right: 16%;
}
@media (max-width: 1200px) {
align-items: center;
justify-content: center;
}
`;

export const GeneralErrorText = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 5px;
`;

export const GeneralErrorContainer = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
`;

import React from "react";
import { COLORS_TRUCK } from "../../../utils/colors";
import { TextInputContainer, InputIcon, CustomTextInput } from "./text-input-styles";

const TextInput = (props) => {

    return (
        <TextInputContainer style={Object.assign({ border: props.showError ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : `1px solid rgba(0, 0, 0, 0.08)`}, props.containerStyle)}>
           {props.icon && <InputIcon src={props.icon} />}
           <CustomTextInput 
           {...props}
           autocomplete="new-password"
           onFocus={(event) => {
             event.target.setAttribute('autocomplete', 'off');
           }}
           />
           {props.rightIcon && <InputIcon onClick={props.iconRightClick} style={{ cursor: 'pointer' }} src={props.rightIcon} />}
        </TextInputContainer>
    )
}

export default TextInput
import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../utils/colors';

export const SelectButtonContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
width: 100px;
border-radius: 5px;
cursor: pointer;
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 10px;
position: relative;
`;


export const SelectButtonContent = styled.p`
font-weight: bold;
`;

export const ListContainer = styled.li`
display: flex;
flex-direction: column;
text-align: center;
position: absolute;
top: 53px;
width: 100px;
list-style-type: none;
background-color: white;
border-radius: 5px;
cursor: pointer;
border: 1px solid ${COLORS_TRUCK.BLACK_TRUCK};
z-index: 50;
`

export const ListItem = styled.span`
padding: 10px 5px;
`
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";
import FormNumberInput from "../../components/inputs/forms/form-number-input/FormNumberInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import i18n from "../../i18n/i18n";
import { VEHICLES_ROUTE } from "../../routes/routes";
import { createVehicleAction, getVehiclesAction, removeVehicleAction, updateVehicleAction } from "../vehicles/vehicles-actions";
import {
  VehicleDetailsContainer,
  VehicleDetailsContentContainer,
  VehicleDetailsFormsContainer,
  VehicleDetailsFormInputsContainer,
  TableInfoContainer,
  TableTitle,
  VehicleDetailsDateInputsContainer,
  ProfileLastButtonsContainer,
  GeneralErrorContainer,
  GeneralErrorText
} from "./vehicle-details-styles";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const VehicleDetailsScreen = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [type, setType] = useState("")
  const [loading, setLoading] = useState("")
  const [enrollment, setEnrollment] = useState("")
  const [year, setYear] = useState("")
  const [date, setDate] = useState("")
  const [brand, setBrand] = useState("")
  const [model, setModel] = useState("")
  const [vehicleId, setVehicleId] = useState("")
  const { vehiclePlate } = useParams()
  const isMounted = React.useRef(true)
  const [showInputsErrors, setShowInputsErrors] = useState("")
  const [generalError, setGeneralError] = useState("")
  useEffect(() => {


    if (vehiclePlate && isMounted.current) {
      getVehicleData()
    }

    return () => {
      isMounted.current = false;
    };
  }, [vehiclePlate])

  const isFormCompleted = () => {
    if (type &&
      enrollment
      // year &&
      // date &&
      // brand &&
      // model
    ) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } else {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.completeAllFields"),
      //   isAlertOpen: true
      // }))

      return false
    }
  }

  const getVehicleData = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        plate: vehiclePlate
      }
      const vehicles = await getVehiclesAction(data)
      const vehicle = vehicles?.data?.myVehicles[0]
      setBrand(vehicle?.brand)
      setModel(vehicle?.model)
      setEnrollment(vehicle?.plate)
      setYear(vehicle?.year)
      setDate(vehicle?.itv_date)
      setType(vehicle?.vehicle_type.toUpperCase())
      setVehicleId(vehicle?._id)

      setLoading(false)

      // setDrivers(drivers?.data?.myDrivers)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      navigate(VEHICLES_ROUTE)
    }
  }

  const createVehicle = async () => {
    try {
      setLoading(true)
      let data = {
        brand: brand || null,
        itv_date: date ? moment(date).valueOf() : null,
        model: model || null,
        plate: enrollment || null,
        vehicle_type: type || null,
        year: year ? Number(year) : null,
      }

      if (!vehiclePlate) {
        await createVehicleAction(data)
      } else {
        await updateVehicleAction(vehicleId, data)
      }

      setLoading(false)
      if (!vehiclePlate) {
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("VEHICLE_DETAILS.successMessage"),
          isAlertOpen: true
        }))
        navigate(VEHICLES_ROUTE)
      } else {
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("VEHICLE_DETAILS.updatedSuccessMessage"),
          isAlertOpen: true
        }))
        navigate(VEHICLES_ROUTE)
      }
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const removeVehicle = () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("VEHICLE_DETAILS.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await removeVehicleAction(vehicleId)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("VEHICLE_DETAILS.removedSuccessMessage"),
            isAlertOpen: true
          }))
          setLoading(false)
          navigate(VEHICLES_ROUTE)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  return (
    <VehicleDetailsContainer>
      <VehicleDetailsContentContainer>
        <TableInfoContainer>
          <TableTitle>
            {i18n.t('VEHICLE_DETAILS.form.details')}
          </TableTitle>
        </TableInfoContainer>
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <VehicleDetailsFormsContainer>
          <VehicleDetailsFormInputsContainer>
            <FormSelect
              title={i18n.t('VEHICLE_DETAILS.form.type')}
              value={type}
              onChange={({ target: { value } }) => setType(value)}
              items={[
                { value: 'TRAILER', label: i18n.t('VEHICLE_DETAILS.trailer'), },
                { value: 'TRUCK', label: i18n.t('VEHICLE_DETAILS.truck'), },
              ]}
              required
              disabled={loading}
              showError={!type && showInputsErrors}
            />
            <FormTextInput
              title={i18n.t('VEHICLE_DETAILS.form.enrollment')}
              type={"text"}
              value={enrollment}
              onChange={({ target: { value } }) => setEnrollment(value)}
              required
              disabled={loading}
              showError={!enrollment && showInputsErrors}
            />
            <VehicleDetailsDateInputsContainer>
              <FormNumberInput
                title={i18n.t('VEHICLE_DETAILS.form.year')}
                containerStyle={{ width: 150 }}
                value={year}
                onValueChange={(value) => setYear(value)}
                disabled={loading}
                max={moment().year()}
              />
              <FormDateInput
                title={i18n.t('VEHICLE_DETAILS.form.date')}
                value={date}
                containerStyle={{ width: 150 }}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                minDate={new Date()}
                disabled={loading}

              />
            </VehicleDetailsDateInputsContainer>
          </VehicleDetailsFormInputsContainer>
          <VehicleDetailsFormInputsContainer>
            <FormTextInput
              title={i18n.t('VEHICLE_DETAILS.form.brand')}
              type={"text"}
              value={brand}
              onChange={({ target: { value } }) => setBrand(value)}
              disabled={loading}
            />
            <FormTextInput
              title={i18n.t('VEHICLE_DETAILS.form.model')}
              type={"text"}
              value={model}
              onChange={({ target: { value } }) => setModel(value)}
              disabled={loading}
            />
            <ProfileLastButtonsContainer>
              {vehiclePlate && <CategoryButton
                onClick={() => removeVehicle()}
                active={false}
                icon=""
                title={i18n.t('VEHICLE_DETAILS.form.delete')}
                width={120}
                loading={loading}
              />}
              <CategoryButton
                onClick={() => {
                  if (isFormCompleted()) {
                    createVehicle()
                  }
                }}
                active
                icon=""
                title={i18n.t('PROFILE.buttons.save')}
                width={120}
                loading={loading}
              />
            </ProfileLastButtonsContainer>
          </VehicleDetailsFormInputsContainer>
        </VehicleDetailsFormsContainer>
      </VehicleDetailsContentContainer>
    </VehicleDetailsContainer>
  )
}

export default VehicleDetailsScreen
import styled from 'styled-components';

export const TextInputContainer = styled.div`
width: 300px;
height: 60px;
background: white;
box-sizing: border-box;
border-radius: 16px;
display: flex;
margin: 10px;
justify-content: flex-start;
    align-items: center;
`;

export const InputIcon = styled.img`
width: 20px;
height: 20px;
margin: 20px;
`;

export const CustomTextInput = styled.input`
background-color: transparent;
width: 70%;
height: 90%;
border: none;
`;
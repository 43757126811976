import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileBillingContainer,
  ProfileTableContainer,
} from "./chats-styles";
import i18n from '../../i18n/i18n';
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import { getDrivers } from "../driver-details/driver-details-actions";
import { getChats } from "./chats-actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CHAT_ROUTE } from "../../routes/routes";
import { getUnreadMessagesAction } from "../user-home/user-home-actions";
import { useSubscription } from "@apollo/client";
import { GET_MESSAGES_SUBSCRIPTION } from "./chats-queries";

const ChatsScreen = ({ chatDriverId }) => {
  const navigate = useNavigate();
  const [searchUsers, setSearchUsers] = useState("")
  // const [message, setMessage] = useState("")
  const dispatch = useAppDispatch()
  const userLogged = useSelector((state) => state).userSlice
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  // const [limitMessage, setLimitMessage] = useState(10)
  // const [offsetMessage, setOffsetMessage] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [drivers, setDrivers] = useState([])
  const isMounted = React.useRef(true)

  useEffect(() => {
    localStorage.removeItem("numberOfUnreadMessages")
    if (userLogged?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (userLogged?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    (async () => {
      await getDriversFunc()
    })()

    return () => {
      isMounted.current = false;
    };
  }, [])

  useSubscription(GET_MESSAGES_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      // console.log("Chats / GET_MESSAGES_SUBSCRIPTION / data: ", data)
      // if(error){
      //   console.log("Chats / GET_MESSAGES_SUBSCRIPTION / error: ", error)
      // }

      getDriversFunc()
      // if (driverId &&
      //   data?.newMessage &&
      //   data?.newMessage?.from_user_id === driverId) {
      //   // setMessages([])
      //   goDriverChat(driverId, true, data?.newMessage)
      // }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const mixChat = (drivers, chats) => drivers.map(driver => {
    const driverName = (driver.name && driver.surnames ? `${driver.name} ${driver.surnames}` : driver.name)

    if (chats.filter(chat => chat.from_user_name === driverName).length > 0) {
      return {
        ...driver,
        chat: chats.filter(chat => chat.from_user_name === driverName)[0]
      }
    } else {
      return driver
    }
  })

  const getDriversFunc = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: reset ? null : searchUsers || null,
        driver_id: null
        // status: status || null,
        // type: type || null,
        // payment_type: paymentType || null
      }
      const res = await getDrivers(data)

      const unreadMessages = await getUnreadMessagesAction()
      if (unreadMessages?.data?.unreadMessages?.total) {
        localStorage.setItem("numberOfUnreadMessages", String(unreadMessages?.data?.unreadMessages?.total))
      }

      const chats = await getChatsFunc()

      // console.log("getDriversFunc / chats: ", chats)
      if (res?.data?.myDrivers.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let driversArray = drivers.concat(res?.data?.myDrivers)
      if (!searchUsers) {
        const data = mixChat(driversArray.filter((item, index, self) => index === self.findIndex((t) => t.email === item.email)), chats)
        setDrivers(data.sort((a, b) => moment(a?.chat?.datetime) - moment(b?.chat?.datetime)))
      } else {
        const data = mixChat(res?.data?.myDrivers, chats)
        setDrivers(data.sort((a, b) => moment(a?.chat?.datetime) - moment(b?.chat?.datetime)))
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const getChatsFunc = async () => {
    try {

      const data = {
        message: searchUsers || null,
        pagination: {
          limit: -1,
          offset: 0
        },
        sort: {
          field: "createdAt",
          type: "DESC"
        }
      }

      const res = await getChats("", data)
      const chats = res?.data?.myMessages
      return chats

    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const handleKeyPressUsers = (event) => {
    if (event.key === 'Enter' && searchUsers) {
      event.preventDefault();
      getDriversFunc()
    }
  };

  const header = <ProfileBillingContainer>
    <FormTextInput
      placeholder={i18n.t('CHATS.usersListSearchInputPlaceholder')}
      type={"text"}
      value={searchUsers}
      containerStyle={{ maxWidth: 250 }}
      onChange={({ target: { value } }) => {
        if (value === "") {
          getDriversFunc(true)
        } else {
          getDriversFunc()
        }

        setSearchUsers(value)
      }}
      onKeyPress={handleKeyPressUsers}
      required
      icon={searchIcon}
    />
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileTableContainer
          style={{
            maxHeight: 700,
            maxWidth: 300,
            overflow: "auto",
          }}
          className="chat-users-table-desktop"
        >
          <TruckTable
            type="chatUsers"
            header={header}
            onEndReach={() => {
              console.log("onEndReach / keepIncreasing: ", keepIncreasing)
              if (keepIncreasing) {
                setOffset(offset + increaseLimit)
                setLimit(limit + increaseLimit)
              }

              getDriversFunc()
            }}
            goDriverChat={(driver) => {
              navigate(`${CHAT_ROUTE}/${driver._id}`, { replace: true })
              getDriversFunc()
            }}
            updateTable={() => getDriversFunc()}
            titles={[
              { label: i18n.t('CHATS.picture') },
              { label: i18n.t('CHATS.name') },
            ]}
            chatDriverId={chatDriverId}
            data={drivers}
            loading={loading}
          />
        </ProfileTableContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default ChatsScreen
import styled from 'styled-components';
import homeBgImage from "../../assets/home/home-bg-image.png";
import { COLORS_TRUCK } from '../../utils/colors';

export const HomeContainer = styled.div`
width: 100%;
height: 90vh;
background-image: url(${homeBgImage});
background-repeat: no-repeat;
background-size: cover;
`;

export const OpacityContainer = styled.div`
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.3);
`;

export const HomeContentContainer = styled.div`
max-width: 500px;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: baseline;
padding-left: 100px;

@media (max-width: 765px) {
    padding: 10px;
    height: 60%;
  }
`;

export const HomeTitle = styled.h1`
color: ${COLORS_TRUCK.WHITE_TRUCK};
font-weight: bold;
font-size: 50px;
margin-bottom: 0;
`;

export const HomeDescription = styled.p`
color: ${COLORS_TRUCK.WHITE_TRUCK};
`;

export const ButtonText = styled.p`
font-weight: bold;
color: ${COLORS_TRUCK.WHITE_TRUCK};
padding: 0;
margin: 0;
`;

export const Button = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  background: ${COLORS_TRUCK.ORANGE_TRUCK};
  padding: 12px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
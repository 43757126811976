import React, { useEffect, useState, useLayoutEffect, useMemo } from "react";
import { TruckTableContainer, ActionButton, UserPicture, ProfileTableContainer, TableLoaderContainer, UnreadMessages, TextLink, LoaderContainer } from "./truck-rable-styles";
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import FormSelect from "../inputs/forms/form-select/FormSelect";
import { COLORS_TRUCK } from '../../utils/colors';
import { useNavigate } from "react-router-dom";
import { 
  CHECKLIST_DETAILS, 
  CLIENT_DETAILS_ROUTE, 
  DRIVER_DETAILS_ROUTE, 
  ISSUE_DETAILS, 
  PAYMENT_CONCEPT, 
  TASK_DETAILS_ROUTE, 
  VEHICLE_DETAILS_ROUTE,
  CHECKLIST_JOURNEY_ROUTE
 } from "../../routes/routes";
import i18n from "../../i18n/i18n";
import {
  throttle
} from 'lodash'
import seeIcon from "../../assets/drivers/see-icon.svg";
import Loader from "../loader/Loader";
import avatarDefault from "../../assets/drivers/user.png";
import { getDriverTypes, getDriverPaymentTypes, updateDriverStatusAction, finishJorneyAction } from "../../screens//driver-details/driver-details-actions"
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { removeTaskAction } from "../../screens/task-details/tasks-actions";
import removeIcon from "../../assets/tasks/remove-icon-white.svg";
import downloadIcon from "../../assets/profile/download-icon.svg";
import { removeIssueAction, updateIssueStatusAction } from "../../screens/issue-details/issues-actions";
import moment from "moment-timezone";
import CategoryButton from "../buttons/category-button/CategoryButton";
import { removePaymentConceptAction } from "../../screens/payment-concept/payment-concept-actions";
import ReactCountryFlag from "react-country-flag"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

const TruckTable = ({
  data,
  titles,
  type,
  onEndReach,
  style,
  header,
  loading,
  loadingDownload,
  updateTable,
  goDriverChat,
  downloadInvoice,
  changeDriverStatusSuperAdmin,
  updateDriver,
  chatDriverId
}) => {
  const navigate = useNavigate();
  const [showTitles, setShowTitles] = useState(true);
  const [driverTypes, setDriverTypes] = useState([]);
  const [driverPaymentTypes, setDriverPaymentTypes] = useState([]);
  const [currentLang, setCurrentLang] = useState(String(localStorage.getItem("language") || "es"))
  const dispatch = useAppDispatch()
  const isMounted = React.useRef(true)

  // const [executed, setExecuted] = useState(false)

  const eventHandler = () => {
    if(localStorage.getItem('token'))  onEndReach()
  };
  // const debouncedEventHandler = useMemo(
  //   () => debounce(eventHandler, 300)
  // , []);
  const throttledEventHandler = useMemo(
    () => throttle(eventHandler, 2000)
    // eslint-disable-next-line
    , []);

  useEffect(() => {

    if (isMounted.current) {
      document.addEventListener('scroll', listenToScroll);
      if (window.matchMedia("(min-width: 640px)").matches && type === "chatUsers") {
        setShowTitles(false)
      }

      if (type === "drivers") {
        getDriverTypesFunc()
        getDriverPaymentTypesFunc()
      }
      // preventExcetutedMoreTimes()
    }


    return () => {
      isMounted.current = false;
    };
  }, [type]);

  useLayoutEffect(() => {
    document.removeEventListener('scroll', listenToScroll);
  });

  const listenToScroll = () => {
    // if (isMounted.current) {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height
    if (scrolled >= .98) {
      if (typeof onEndReach === 'function') {
        throttledEventHandler()
      }
    }
    // }

  }

  const changeDriverStatus = async (driverId, value) => {
    try {
      const res = await updateDriverStatusAction(driverId, value)
      updateTable(res?.data?.updateDriverStatus)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const changeIssueStatus = async (issueId, value) => {
    try {
      await updateIssueStatusAction(issueId, value)
      updateTable(true)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getDriverTypesFunc = async () => {
    try {
      const res = await getDriverTypes()
      setDriverTypes(res.data.driverTypes)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getDriverPaymentTypesFunc = async () => {
    try {
      const res = await getDriverPaymentTypes()
      setDriverPaymentTypes(res.data.driverPaymentTypes)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const removePaymentConcept = async (paymentConceptId) => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("ACCOUNTING.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          // setLoading(true)
          await removePaymentConceptAction(paymentConceptId)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("ACCOUNTING.removedSuccessMessage"),
            isAlertOpen: true
          }))
          updateTable(true)
          // setLoading(false)
          // navigate(DRIVERS_ROUTE)
        } catch (e) {
          // setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const removeTask = (taskId) => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("TASKS.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          // setLoading(true)
          await removeTaskAction(taskId)
          updateTable(true)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("TASKS.removedSuccessMessage"),
            isAlertOpen: true
          }))
          // setLoading(false)
          // navigate(VEHICLES_ROUTE)
        } catch (e) {
          // setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const removeIssue = async (issueId) => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("ISSUES.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          // setLoading(true)
          await removeIssueAction(issueId)
          updateTable(true)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("ISSUES.removedSuccessMessage"),
            isAlertOpen: true
          }))
          // setLoading(false)
          // navigate(VEHICLES_ROUTE)
        } catch (e) {
          // setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const endDriverJorneyFunc = async (item) => {
    dispatch(openAlert({
      alertType: "question-input",
      alertMessage: i18n.t("TASKS.areYouSureToEndJorney"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("TASKS.finishJourneyButton"),
      alertInputPlaceholder: "Km",
      alertOnYes: async (value) => {
        try {
          // setLoading(true)
          const data = {
            final_kms: Number(value),
            trailer_id: null,
            vehicle_id: null,
            observations: null
          };
          const res = await finishJorneyAction(item._id, data)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("TASKS.jourjeyFinished"),
            isAlertOpen: true
          }))
          updateTable({
            ...item,
            journey: false
          })
          // setLoading(false)
        } catch (e) {
          // setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))

  }

  const downloadInvoiceFunc = async (invoiceId) => {
    downloadInvoice(invoiceId)
  }

  // const endDriverJorney = (driverId) => {

  //     endDriverJorneyFunc(driverId)
  // }

  const DriversStatusSelect = ({ value, item }) => <FormSelect
    value={value}
    containerStyle={{ width: 150, margin: 'auto' }}
    height={40}
    defaultValue={''}
    onChange={({ target: { value } }) => {
      if (changeDriverStatusSuperAdmin) {
        changeDriverStatusSuperAdmin(item.documentation.driver_id, value)
      } else {
        changeDriverStatus(item.documentation.driver_id, value)
      }
    }}
    items={[
      {
        value: "ACTIVE",
        label: <p
          style={{
            color: COLORS_TRUCK.GREEN_TRUCK,
            margin: 0
          }}
        >
          {i18n.t('DRIVERS.forms.drivers.buttons.active')}
        </p>,
      },
      {
        value: "INACTIVE",
        label: <p
          style={{
            color: COLORS_TRUCK.ORANGE_TRUCK,
            margin: 0
          }}
        >
          {i18n.t('DRIVERS.forms.drivers.buttons.inactive')}
        </p>,
      },
    ]}
    required
  />

  const MemoizedDriversStatusSelect = React.memo(DriversStatusSelect)

  const Tr = TableRow
  const Thead = TableHead
  const Tbody = TableBody
  const Th = TableCell
  const Td = TableCell
  return (
    <div className="table-container">
      {header ? header : null}
      <ProfileTableContainer
        onScroll={listenToScroll}
        style={style}
      >
        <TruckTableContainer >
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <Thead>
                  <Tr>
                    {titles && titles.length > 0 && showTitles ? titles.map(item =>
                      <Th style={{ fontWeight: "bold" }}>{item.label}</Th>
                    ) : null}
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((item, i) => {
                    return type === "billing"
                      ? <Tr key={i}>
                        <Td>{item.id}</Td>
                        <Td>{item.plan}</Td>
                        <Td>{item.period}</Td>
                        <Td>{item.date}</Td>
                        <Td>{item.status}</Td>
                        <Td>{item.amount}</Td>
                        <Td>{item.discount}</Td>
                        <Td>{item.finalAmount}</Td>
                        <Td>{<ActionButton src={item.actionIcon} />}</Td>
                      </Tr>
                      : type === "invoices"
                        ? <Tr key={i}>
                          <Td>{item._id}</Td>
                          <Td>{item.concept[String(localStorage.getItem("language") || "es")]}</Td>
                          <Td>{i18n.t(`PROFILE.payment_types.${item.payment_type.toLowerCase()}`)}</Td>
                          <Td>{item.date}</Td>
                          <Td>{i18n.t(`PROFILE.invoice_status.${item.status.toLowerCase()}`)}</Td>
                          <Td>{item.amount}</Td>
                          <Td>{item.discount}</Td>
                          <Td>{item.final_amount}</Td>
                          {
                            (item.status !== "Unpaid") &&
                            <Td>
                              {
                                !loadingDownload ?
                                <ActionButton onClick={() => downloadInvoiceFunc(item._id)} src={downloadIcon} />
                                :
                                <LoaderContainer>
                                  <Loader color={COLORS_TRUCK.WHITE_TRUCK } height={15} width={15} />
                                </LoaderContainer>
                              }
                            </Td>
                          }
                        </Tr>
                        : type === "drivers"
                          ? <Tr key={i}>
                            <Td>{<UserPicture src={item?.avatar?.fullpath || avatarDefault} alt="" />}</Td>
                            <Td>{item.name}</Td>
                            <Td>{item.surnames}</Td>
                            <Td>{item.email}</Td>
                            <Td>{driverTypes && driverTypes.length > 0 ? driverTypes?.filter(type => type.name === item.type)[0]?.translate[0]?.langs[currentLang] : item.type}</Td>
                            <Td>{driverPaymentTypes && driverPaymentTypes.length > 0 ? driverPaymentTypes?.filter(type => type.name === item?.payment_variables?.payment_type)[0]?.translate[0]?.langs[currentLang] : item?.payment_variables?.payment_type}</Td>
                            <Td>{<MemoizedDriversStatusSelect
                              value={item.status}
                              item={item}
                            />}</Td>
                            <Td>{
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ActionButton
                                  onClick={() => navigate(`${DRIVER_DETAILS_ROUTE}/${item.email}`)}
                                  src={seeIcon} />
                                {item.journey && <CategoryButton
                                  titleStyle={{ fontSize: 10 }}
                                  style={{ width: 100 }}
                                  onClick={() => endDriverJorneyFunc(item)}
                                  active={false}
                                  icon=""
                                  title={i18n.t('PROFILE.buttons.endJorney')}
                                />}
                              </div>
                            }</Td>
                          </Tr>
                          : type === "vehicles"
                            ? <Tr key={i}>
                              <Td>{item.plate}</Td>
                              <Td>{i18n.t(`VEHICLE_DETAILS.${item.vehicle_type.toLowerCase()}`)}</Td>
                              <Td>{item.brand}</Td>
                              <Td>{item.model}</Td>
                              <Td>{item.year}</Td>
                              <Td>{item.itv_date ? moment(item.itv_date).format("DD-MM-YYYY") : ""}</Td>
                              <Td>{<ActionButton onClick={() => navigate(`${VEHICLE_DETAILS_ROUTE}/${item.plate}`)} src={seeIcon} />}</Td>
                            </Tr>
                            : type === "tasks"
                              ? <Tr key={i}>
                                <Td>{item.name}</Td>
                                <Td>{item.start_date ? moment(item.start_date).format("DD-MM-YYYY") : ""}</Td>
                                <Td>{item.end_date ? moment(item.end_date).format("DD-MM-YYYY") : ""}</Td>
                                <Td>{item.estimated_km}</Td>
                                <Td>{item.vehicle}</Td>
                                <Td>{item.trailer || "N/A"}</Td>
                                <Td>{item.driver}</Td>
                                <Td>{item?.incidents || "NO"}</Td>
                                <Td>{i18n.t(`TASKS.${item.status.toUpperCase()}`)}</Td>
                                <Td>{
                                  <div style={{ display: 'flex' }}>
                                    <ActionButton
                                      onClick={() => removeTask(item._id)}
                                      src={removeIcon}
                                    />
                                    <ActionButton
                                      onClick={() => navigate(`${TASK_DETAILS_ROUTE}/${item._id}`)}
                                      style={{ marginLeft: 10 }}
                                      src={seeIcon}
                                    />
                                  </div>
                                }</Td>
                              </Tr>
                              : type === "taskIssues"
                                ? <Tr key={i}>
                                  <Td>{item.id}</Td>
                                  <Td>{item.type}</Td>
                                  <Td>{item.author}</Td>
                                  <Td>{item.vehicle}</Td>
                                  <Td>{item.issueDate}</Td>
                                  <Td>{<FormSelect
                                    value={item.status}
                                    containerStyle={{ width: 150, margin: 'auto' }}
                                    height={40}
                                    defaultValue={''}
                                    onChange={({ target: { value } }) => null}
                                    items={[
                                      {
                                        value: item.status, label: <p
                                          style={{
                                            margin: 0
                                          }}
                                        >
                                          {item.status}
                                        </p>,
                                      },
                                    ]}
                                    required
                                  />}
                                  </Td>
                                  <Td>{item.comment}</Td>
                                  <Td>{
                                    <>
                                      <ActionButton style={{ marginLeft: 10 }} src={item.seeIcon} />
                                    </>
                                  }</Td>
                                </Tr>
                                : type === "issues"
                                  ? <Tr key={i}>
                                    <Td>{item.id_number}</Td>
                                    <Td>{item.type}</Td>
                                    <Td>{item.driver}</Td>
                                    <Td>{item.vehicle}</Td>
                                    <Td>{item.creation_date ? moment(item.creation_date).format("DD-MM-YYYY") : ""}</Td>
                                    <Td>{<FormSelect
                                      value={item.status}
                                      containerStyle={{ width: 150, margin: 'auto' }}
                                      height={40}
                                      defaultValue={''}
                                      onChange={({ target: { value } }) => changeIssueStatus(item._id, value)}
                                      items={[
                                        {
                                          value: "RESOLVED",
                                          label: <p
                                            style={{
                                              color: COLORS_TRUCK.GREEN_TRUCK,
                                              margin: 0
                                            }}
                                          >
                                            {i18n.t('ISSUES.forms.details.status1')}
                                          </p>,
                                        },
                                        {
                                          value: "UNRESOLVED",
                                          label: <p
                                            style={{
                                              color: COLORS_TRUCK.ORANGE_TRUCK,
                                              margin: 0
                                            }}
                                          >
                                            {i18n.t('ISSUES.forms.details.status2')}
                                          </p>,
                                        },
                                      ]}
                                      required
                                    />}
                                    </Td>
                                    <Td>{
                                      <>
                                        <ActionButton onClick={() => removeIssue(item._id)} src={removeIcon} />
                                        <ActionButton onClick={() => navigate(`${ISSUE_DETAILS}/${item._id}`)} style={{ marginLeft: 10 }} src={seeIcon} />
                                      </>
                                    }</Td>
                                  </Tr>
                                  : type === "accounting"
                                    ? <Tr key={i}>
                                      <Td>{item.concept}</Td>
                                      <Td>{item.driver}</Td>
                                      <Td>{i18n.t(`ACCOUNTING.${item.variable_type.toUpperCase()}`)}</Td>
                                      <Td>{item.fixed_remuneration}</Td>
                                      <Td>{item.variable_remuneration}</Td>
                                      <Td>{item.extra_remuneration}</Td>
                                      <Td>{item.total}</Td>
                                      <Td>{
                                        <>
                                          <ActionButton onClick={() => removePaymentConcept(item._id)} src={removeIcon} />
                                          <ActionButton onClick={() => navigate(`${PAYMENT_CONCEPT}/${item._id}`)} style={{ marginLeft: 10 }} src={seeIcon} />
                                        </>
                                      }</Td>
                                    </Tr>
                                    : type === "paymentConceptTasks"
                                      ? <Tr key={i}>
                                        <Td>{item.name}</Td>
                                        <Td>{moment(item.start_date).format("DD-MM-YYYY")}</Td>
                                        <Td>{moment(item.end_date).format("DD-MM-YYYY")}</Td>
                                        <Td>{item.vehicle ? item.vehicle : "N/A"}</Td>
                                        <Td>{item.trailer ? item.trailer : "N/A"}</Td>
                                        <Td>{item.incidents ? item.incidents : "0"}</Td>
                                        <Td>{i18n.t(`TASKS.${item.status.toUpperCase()}`)}</Td>
                                      </Tr>
                                      : type === "paymentConceptJourneys"
                                        ? <Tr key={i}>
                                          <Td>{moment(item.creation_datetime).tz(item.timezone_start).format('DD-MM-YYYY - h:mm a')}</Td>
                                          <Td>{moment(item.finish_datetime).tz(item.timezone_end || item.timezone_start).format('DD-MM-YYYY - h:mm a')}</Td>
                                          <Td>{`${item.gps_info?.calculations?.distance} Km`}</Td>
                                          <Td>{item.gps_info?.calculations?.lunch_state?.name && item.gps_info?.calculations?.lunch_country?.name ? `${item.gps_info?.calculations?.lunch_state?.name} (${item.gps_info?.calculations?.lunch_country?.name})` :  "N/A"}</Td>
                                          <Td>{item.gps_info?.calculations?.dinner_state?.name && item.gps_info?.calculations?.dinner_country?.name ? `${item.gps_info?.calculations?.dinner_state?.name} (${item.gps_info?.calculations?.dinner_country?.name})` : "N/A"}</Td>
                                          <Td>{item.gps_info?.calculations?.nocturnity_national >= 0 ? item.gps_info?.calculations?.nocturnity_national : 0}</Td>
                                          <Td>{item.gps_info?.calculations?.nocturnity_international >= 0 ? item.gps_info?.calculations?.nocturnity_international : 0}</Td>
                                        </Tr>
                                      : type === "journeys"
                                        ? <Tr key={i}>
                                          <Td>{item.driver}</Td>
                                          <Td>{item.timezone_start ? moment(item.creation_datetime).tz(item.timezone_start).format('DD-MM-YYYY - h:mm a') : moment(item.creation_datetime).format('DD-MM-YYYY - h:mm a')}</Td>
                                          {/* <Td>{(item.last_timezone ? item.last_timezone : item.timezone_start) ? moment(item.last_journey).tz(item.last_timezone ? item.last_timezone : item.timezone_start).format('DD-MM-YYYY - h:mm a') : moment(item.last_journey).format('DD-MM-YYYY - h:mm a')}</Td> */}
                                          <Td>{item.vehicle}</Td>
                                          <Td>{item.trailer || "N/A"}</Td>
                                          <Td>{item.initial_city?.name ? <TextLink onClick={() => {
                                            dispatch(openAlert({
                                              alertType: "coords",
                                              coords: {
                                                latitude: item.initial_city.latitude,
                                                longitude: item.initial_city.longitude,
                                                name: item.initial_city?.name
                                              },
                                              isAlertOpen: true
                                            }))
                                          }}>{item.initial_city?.name}</TextLink> : "N/A"}</Td>
                                          <Td>{item.final_city?.name ? <TextLink onClick={() => {
                                            dispatch(openAlert({
                                              alertType: "coords",
                                              coords: {
                                                latitude: item.final_city.latitude,
                                                longitude: item.final_city.longitude,
                                                name: item.final_city?.name
                                              },
                                              isAlertOpen: true
                                            }))
                                          }}>{item.final_city?.name}</TextLink> : "N/A"}</Td>
                                          <Td>{item.gps_info?.calculations?.distance ? `${item.gps_info?.calculations?.distance} Km` : "N/A"}</Td>
                                          <Td>{i18n.t(`JOURNEYS.${item.checklist?.status.toLowerCase()}`)}</Td>
                                          <Td>{item.incidents}</Td>
                                          <Td>{<ActionButton onClick={() => navigate(`${CHECKLIST_JOURNEY_ROUTE}/${item._id}`)} src={seeIcon} />}</Td>
                                        </Tr>
                                        : type === "superAdminHomeTable"
                                          ? <Tr key={i}>
                                            <Td>{
                                              // <Flag src={item.flag} alt="" />
                                              <ReactCountryFlag
                                                countryCode={item.iso2}
                                                svg
                                                style={{
                                                  width: '30px',
                                                  height: '20px'
                                                }}
                                                title={item.label}
                                              />
                                            }</Td>
                                            <Td>{item.label}</Td>
                                            <Td>{item.gold}</Td>
                                            <Td>{item.silver}</Td>
                                            <Td>{item.bronze}</Td>
                                            <Td>{<b>{item.total}</b>}</Td>
                                          </Tr>
                                          : type === "chatUsers"
                                            ? <Tr style={{ cursor: goDriverChat ? "pointer" : "default" }} onClick={() => goDriverChat(item)} key={i}>
                                              <Td>{<UserPicture src={item.avatar?.fullpath} alt="" />}</Td>
                                              <Td style={{ fontWeight: chatDriverId === item._id ? "bold" : "" }}>{`${item.name} ${item.surnames}`}</Td>
                                              <Td>{
                                                <>
                                                  {item?.chat?.unread > 0
                                                    ? <UnreadMessages>{item?.chat?.unread}</UnreadMessages>
                                                    : null}
                                                </>
                                              }</Td>
                                            </Tr>
                                            : type === "billing-super-admin"
                                              ? <Tr key={i}>
                                                <Td>{`${item.user[0]?.name} ${item.user[0]?.surnames}`}</Td>
                                                <Td>{item.company}</Td>
                                                <Td>{item.concept[localStorage.getItem("language") || "es"]}</Td>
                                                <Td>{i18n.t(`PROFILE.payment_types.${item.payment_type.toLowerCase()}`)}</Td>
                                                <Td>{item.date}</Td>
                                                <Td>{i18n.t(`PROFILE.invoice_status.${item.status.toLowerCase()}`)}</Td>
                                                <Td>{item.final_amount}</Td>
                                                <Td>{<ActionButton onClick={() => downloadInvoice(item._id)} src={downloadIcon} />}</Td>
                                              </Tr>
                                              : type === "clients"
                                                ? <Tr key={i}>
                                                  <Td>{<UserPicture src={item?.avatar?.fullpath || avatarDefault} alt="" />}</Td>
                                                  <Td>{item?.company}</Td>
                                                  <Td>{item?.name}</Td>
                                                  <Td>{item?.surnames}</Td>
                                                  <Td>{item?.email}</Td>
                                                  <Td>{item?.plan_details?.name ? item?.plan_details?.name[String(localStorage.getItem("language")) || "es"] : ""}</Td>
                                                  <Td>{item?.status === "ACTIVE" ? <p style={{ color: COLORS_TRUCK.GREEN_TRUCK }}>{i18n.t("GENERAL.active")}</p> : <p style={{ color: COLORS_TRUCK.ORANGE_TRUCK }}>{i18n.t("GENERAL.inactive")}</p>}</Td>
                                                  <Td>{<ActionButton onClick={() => navigate(`${CLIENT_DETAILS_ROUTE}/${item.email}`)} src={seeIcon} />}</Td>
                                                </Tr>
                                                : type === "client-users"
                                                  ? <Tr key={i}>
                                                    <Td>{<UserPicture src={item?.avatar?.fullpath || avatarDefault} alt="" />}</Td>
                                                    <Td>{item.name}</Td>
                                                    <Td>{item.surnames}</Td>
                                                    <Td>{item.email}</Td>
                                                    <Td>
                                                      {<FormSelect
                                                        value={item?.type.toUpperCase()}
                                                        containerStyle={{ width: 150, margin: 'auto' }}
                                                        height={40}
                                                        defaultValue={''}
                                                        onChange={({ target: { value } }) => updateDriver(item._id, value, "type")}
                                                        items={[
                                                          { value: 'INTERNAL', label: i18n.t('DRIVER_DETAILS.forms.info.employerType1intern') },
                                                          { value: 'EXTERNAL', label: i18n.t('DRIVER_DETAILS.forms.info.employerType2external') },
                                                        ]}
                                                        required
                                                      />}
                                                    </Td>
                                                    <Td>
                                                      {<FormSelect
                                                        value={item?.payment_variables?.payment_type.toUpperCase()}
                                                        containerStyle={{ width: 150, margin: 'auto' }}
                                                        height={40}
                                                        defaultValue={''}
                                                        onChange={({ target: { value } }) => updateDriver(item._id, value, "payment_type")}
                                                        items={item.type.toUpperCase() === "EXTERNAL" ? [
                                                          { value: 'MILEAGE', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType2km') },
                                                        ] : [
                                                          { value: 'DIETS', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType1diets') },
                                                          { value: 'MILEAGE', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType2km') },
                                                          { value: 'BOTH', label: i18n.t('DRIVER_DETAILS.forms.variables.both') },
                                                        ]}
                                                        required
                                                      />}
                                                    </Td>
                                                    <Td>{<MemoizedDriversStatusSelect
                                                      value={item.status}
                                                      item={item}
                                                    />}</Td>
                                                  </Tr>
                                                  : null
                  }
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Paper>
        </TruckTableContainer>
        {loading ?
          <TableLoaderContainer>
            <Loader color={COLORS_TRUCK.ORANGE_TRUCK} width={25} height={25} />
          </TableLoaderContainer>
          : !loading && data.length <= 0 ? <p style={{ textAlign: 'center', margin: 30 }}>{i18n.t("GENERAL.noResults")}</p> : null}
      </ProfileTableContainer>
    </div>
  )
}

export default TruckTable
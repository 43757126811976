// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  CREATE_PAYMENT_CONCEPT_MUTATION,
  GET_PAYMENTS_CONCEPTS_MUTATION,
  DELETE_PAYMENT_CONCEPT_MUTATION,
  CALCULATE_PAYMENT_CONCEPT_MUTATION,
  EXPORT_PAYMENTS_CONCEPTS_MUTATION,
  UPDATE_PAYMENT_CONCEPT_MUTATION,
  EXPORT_DRIVER_JOURNEYS_MUTATION
} from './payment-concept-queries';
// import {handleAlert} from '../../general/functions';

/**
 * createPaymentConceptAction - action for create an issue
 *
 * @param {object} formData - createPaymentConceptAction data
 * @returns {any}
 */
export const createPaymentConceptAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CREATE_PAYMENT_CONCEPT_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * updatePaymentConceptAction - action for create an issue
 *
 * @param {string} accounting_id - accounting id
 * @param {object} formData - updatePaymentConceptAction data
 * @returns {any}
 */
export const updatePaymentConceptAction = (
  accounting_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_PAYMENT_CONCEPT_MUTATION,
        variables: {
          accounting_id,
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getPaymentsConceptsAction - action for get drivers
 *
 * @param {object} formData - getPaymentsConceptsAction data
 * @returns {any}
 */
export const getPaymentsConceptsAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_PAYMENTS_CONCEPTS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * exportPaymentsConcepts - action for get drivers
 *
 * @param {object} formData - exportPaymentsConcepts data
 * @returns {any}
 */
export const exportPaymentsConcepts = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_PAYMENTS_CONCEPTS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportDriverJourneys - action for get drivers
 *
 * @param {string} driverId - driver id
 * @param {object} formData - exportDriverJourneys data
 * @returns {any}
 */
export const exportDriverJourneys = (
  driverId,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("exportDriverJourneys / formData: ", formData)
      // console.log("exportDriverJourneys / driverId: ", driverId)
      const response = await client.query({
        query: EXPORT_DRIVER_JOURNEYS_MUTATION,
        variables: {
          driver_id: driverId,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("exportDriverJourneys / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
* removePaymentConceptAction - action for remove an issue
*
* @param {string} paymentId - payment id
* @returns {any}
*/
export const removePaymentConceptAction = (
  paymentId,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);

      const response = await client.mutate({
        mutation: DELETE_PAYMENT_CONCEPT_MUTATION,
        variables: {
          accounting_id: paymentId,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
* calculatePaymentConceptAction - action for remove an issue
*
* @param {string} formData - form data
* @returns {any}
*/
export const calculatePaymentConceptAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);

      const response = await client.mutate({
        mutation: CALCULATE_PAYMENT_CONCEPT_MUTATION,
        variables: {
          input: formData,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
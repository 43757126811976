import React from "react";
import {
  FormFileInputContainer,
  AddFileIcon,
  BackgroundPicture,
  FormTitle,
  FormMainContainer
} from "./form-text-input-styles";
import addFileIcon from "../../../../assets/file-input/add-circle-icon.svg"

const FormFileInput = ({ onEditPicture, picture, title, disabled }) => {

  const uploadFile = (aspect) => {
    const file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = 'image/png, image/jpg, image/jpeg, image/heif, image/heic, image/webp, image/gif';
    file.onchange = (e) => handleFileInput(e, aspect);
    file.click();
  };

  const handleFileInput = async (e, aspect) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      onEditPicture(file)
    }
  };

  return (
    <FormMainContainer>
    {title && <FormTitle>{title}</FormTitle>}
    <FormFileInputContainer style={{ cursor: disabled ? "default" : "pointer" }} onClick={(e) => !disabled ? uploadFile(e) : null}>
      {picture && <BackgroundPicture src={picture && picture.name ? URL.createObjectURL(picture) : picture} />}
      <AddFileIcon src={addFileIcon} />
    </FormFileInputContainer>
    </FormMainContainer>
  )
}

export default FormFileInput
import { gql } from '@apollo/client';

export const REGISTER_MUTATION = gql`
mutation signup($signUpUserInput: CreateUserInput!) {
  signup(signUpUserInput: $signUpUserInput){
    user {
      _id
      name
      surnames
      email
      position
      dni
      coin
      phone
      company
      nif
      company_type
      country
      state
      city
      address
      zip
      confirmed
      rol
    }
    preinvoice {
      status
      items {
        amount
        description
        unit
      }
      payment_method
      payment_type
      final_amount
    }
  }
}
`;

export const GET_PRE_INVOICE_MUTATION = gql`
mutation getPreInvoice($input: GetPreInvoiceInput!) {
  getPreInvoice(input: $input){
    status
    items {
      amount
      description
      unit
    }
    payment_method
    payment_type
    final_amount
    tax
  }
}
`;
export const UPDATE_USER_MUTATION = gql`
mutation updateProfile($input: UpdateUserInput!) {
  updateProfile(input: $input){
      _id
      name
      surnames
      email
      position
      dni
      phone
      coin
      company
      nif
      company_type
      country
      state
      city
      address
      zip
      rol
  }
}
`;

export const CREATE_PAYMENT_INTENT_MUTATION = gql`
mutation createPaymentIntent($createSubscriptionInput: CreateSubscriptionInput!) {
  createPaymentIntent(input: $createSubscriptionInput) {
    message
    payment_intent
    status
    data
  } 
}
`;

export const CONFIRM_PAYMENT_INTENT_MUTATION = gql`
  mutation ConfirmPaymentIntent($confirmPaymentIntentInput: ConfirmPaymentIntentInput!) {
    confirmPaymentIntent(input: $confirmPaymentIntentInput) {
      message
      status
      data
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($type: RolesEnum!, $loginUserInput: LoginUserInput!) {
    login(type:$type, loginUserInput: $loginUserInput) {
      access_token
      driver {
        _id
        address
        avatar {
 					fullpath
          path
        }
        birthday
        city
        company
        company_phone
        country
        dni
        documentation {
          CAP {
						card
            date
          }
          driver_id
          driving_cards {
						A
            A1
            A2
            AM
            B
            BE
            C
            C1
            C1E
            CE
            D
            D1
            D1E
            DE
          }
          sanitary_card {
            card
            date
          }
          sanitary_card_EU {
            card
            date
          }
          tachograph_card {
            card
            date
          }
        }
        email
        name
        payment_variables {
          complete_national
          complete_international
          diets {
            dinner
            dinner_international
            lunch
            lunch_international
            nocturnal
            nocturnal_international
            sunday
          }
          fixed_remuneration
          payment_per_km
          payment_type
          user_id
          values
        }
        personal_email
        phone
        rol
        state
        status
        surnames
        type
        zip
      }
      type
      user {
        _id
        address
        avatar {
          fullpath
          path
        }
        city
        coin
        company
        country_user {
          latitude
          longitude
        }
        company_type
        confirmed
        country
        dni
        email
        email_contact
        language
        name
        nif
        payment_variables {
          complete_national
          complete_international
          diets {
            dinner
            dinner_international
            dinner_timetable
            lunch
            lunch_international
            lunch_timetable
            nocturnal
            nocturnal_international
            nocturnal_timetable
            sunday
          }
          fixed_remuneration
          payment_per_km
          user_id
        }
        phone
        plan_details {
          amount
          discount
          end_date
          final_amount
          max_users
          name {
            es
            pt
            fr
            de
          }
          payment_type
          plan_id
          status
          subscription_id
          tax
          }
          position
          rol
          state
          status
          stripe_details {
            account_connect
            bank_account {
              default
              last_digit
              name
              routing_number
              token
            }
            card {
              brand
              default
              expiry
              last_digit
              token
            }
            customer
            method
            payment_intent
            payment_method
          }
        surnames
        zip
      }
    }
  }
`;

export const GET_CITIES_QUERY = gql`
query getCities(
  $country_code: String,
  $state_id: ObjectId
) {
  cities(country_code: $country_code, state_id: $state_id) {
    _id
    country_code
    name
  }
}
`;

export const SEND_CONFIRM_USER_CODE = gql`
mutation reConfirmUser($email: String!) {
  reConfirmUser(email: $email){
     message
   	 status
  }
}
`;

export const CONFIRM_USER_CODE = gql`
mutation confirmUser($code: String!) {
  confirmUser(code: $code) {
     message
   	 status
  }
}
`;

export const CHECK_EMAIL = gql`
mutation checkEmail($email: String!){
  checkEmail(email: $email){
     result
  }
}
`;

export const GET_COUNTRIES_QUERY = gql`
query getCountries(
  $iso2: String
) {
  countries(iso2: $iso2) {
    _id
    name
    iso2
    iso3
    translate {
      langs {
        es
        pt
        fr
        de
        en
        it
      }
    }
  }
}
`;

export const GET_COUNTRY_STATES = gql`
query getStates(
  $country_code: String
) {
  state(country_code: $country_code) {
    country_code
    name
    iso2
    _id
    translate {
      langs {
        es
        pt
        fr
        de
        en
        it
      }
    }
  }
}
`;

export const GET_DISCOUNTS = gql`
query getDiscounts(
  $name: String
) {
  discounts(name: $name) {
    active
    name
    percentage
  }
}
`;

export const GET_PLANS = gql`
query getPlans(
  $_id: ObjectId
) {
  plans(_id: $_id) {
    _id
    name
    prices {
    annual
      monthly
      user_annual
      user_monthly
  }
    icon {
      fullpath
      path
    }
    max_users
    min_users
    product_stripe_id
    translate {
      langs {
        es
        pt
        fr
        de
        en
        it
      }
    }
  }
}
`;

export const GET_INVOICES = gql`
query myInvoices($input: InvoiceFiltersInput) {
  myInvoices(input: $input) {
    _id
    amount
    company
    concept {
            es
            pt
            fr
            de
          }
    date
    discount
    final_amount
    max_users
    payment_method
    payment_type
    plan_price
    status
    tax
    users_price
  }
}
`;
// import {RootState} from '../store.ts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  address: '',
  city: '',
  company: '',
  company_type: '',
  country: '',
  coin: '',
  dni: '',
  email: '',
  name: '',
  avatar: {},
  confirmed: false,
  email_contact: '',
  language: '',
  payment_variables: {},
  plan_details: {},
  stripe_details: {},
  country_user: {},
  status: '',
  nif: '',
  phone: '',
  position: '',
  rol: '',
  state: '',
  surnames: '',
  zip: '',
  __typename: '',
  _id: '',
  userType: '',
};

type User = {
  _id: string,
  address: string,
  avatar: object,
  city: string,
  company: string,
  company_type: string,
  confirmed: boolean,
  coin: string,
  country: string,
  dni: string,
  email: string,
  email_contact: string,
  language: string,
  name: string,
  nif: string,
  payment_variables: object,
  phone: string,
  plan_details: object,
  position: string,
  rol: string,
  state: string,
  status: string,
  stripe_details: object,
  country_user: object,
  surnames: string,
  zip: string,
  __typename: string,
  userType: string,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.address = action.payload.address;
      state.avatar = action.payload.avatar;
      state.confirmed = action.payload.confirmed;
      state.email_contact = action.payload.email_contact;
      state.language = action.payload.language;
      state.payment_variables = action.payload.payment_variables; 
      state.plan_details = action.payload.plan_details; 
      state.stripe_details = action.payload.stripe_details; 
      state.status = action.payload.status;
      state.city = action.payload.city;
      state.coin = action.payload.coin;
      state.company = action.payload.company;
      state.company_type = action.payload.company_type;
      state.country = action.payload.country;
      state.dni = action.payload.dni;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.nif = action.payload.nif;
      state.phone = action.payload.phone;
      state.position = action.payload.position;
      state.rol = action.payload.rol;
      state.state = action.payload.state;
      state.surnames = action.payload.surnames;
      state.zip = action.payload.zip;
      state.__typename = action.payload.__typename;
      state._id = action.payload._id;
      state.userType = action.payload.userType;
      state.country_user = action.payload.country_user;
    },
    setGeneralLogout: () => initialState,
  },
});

// ACTIONS
export const { setUser, setGeneralLogout } =
  userSlice.actions;

// SELECTORS
// export const userInfoSelector = (state: RootState): any => state.userSlice;

export default userSlice.reducer;

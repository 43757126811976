import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  // ProfileButtonsContainer,
  ProfileFormContainer,
  // ProfileFormInputsContainer,
  // ProfileFormsContainer,
  // ProfileLastButtonsContainer,
  // FixedAmountText,
  // ProfileSmallButtonGroup,
  // ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  // ProfileFormSeparator,
  ProfileBillingContainer,
  // ProfileTableContainer,
  TableTitle,
  TableInfo,
  TableInfoContainer
} from "./drivers-styles";
import TextInput from "../../components/inputs/text-input/TextInput";
import i18n from '../../i18n/i18n';
// import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { DRIVER_DETAILS_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { getDrivers, exportDrivers, finishJorneyAction } from "../driver-details/driver-details-actions";
// import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { useSelector } from "react-redux";

const DriversScreen = () => {
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [drivers, setDrivers] = useState([])
  const [type, setType] = useState("")
  const [paymentType, setPaymentType] = useState("")
  const [status, setStatus] = useState("")
  const [billingFilterBy, setBillingFilterBy] = useState("1")
  const [search, setSearch] = useState("")
  const navigate = useNavigate();
  const user = useSelector((state) => state).userSlice
  // const user = useSelector((state) => state).userSlice
  const isMounted = React.useRef(true)

  useEffect(() => {

    // if (isMounted.current) {
    getDriversFunc()
    // }
    if (user?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    return () => {
      isMounted.current = false;
    };
  }, [status, type, paymentType])

  const getDriversFunc = async (reset = false) => {
    try {
      setLoading(true)

      if (reset) {
        getDrivers([])
        // setLimit(30)
        // setOffset(0)
        // setSearch("")
        // setStatus("")
        // setType("")
        // setPaymentType("")
      }
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: search || null,
        status: status || null,
        type: type || null,
        payment_type: paymentType || null,
        driver_id: null
      }
      const res = await getDrivers(data)
      if (res?.data?.myDrivers.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let driversArray = drivers.concat(res?.data?.myDrivers)
      if ((!search && !status && !type && !paymentType) || limit > 30) {
        setDrivers(driversArray.filter((item, index, self) => index === self.findIndex((t) => t.email === item.email)))
      } else {
        setDrivers(res?.data?.myDrivers)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const exportDriversFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: search || null,
        status: status || null,
        type: type || null,
        payment_type: paymentType || null
      }
      const res = await exportDrivers(data)
      var win = window.open(res.data.exportMyDrivers.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getDriversFunc()
    }
  };

  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('DRIVERS.forms.drivers.title')}
      </TableTitle>
      <TableInfo>
        {`${i18n.t('DRIVERS.forms.drivers.actives')} ${drivers.filter(item => item.status.toLowerCase() === "active").length} ${i18n.t('DRIVERS.forms.drivers.of')} ${drivers.length}`}
      </TableInfo>
    </TableInfoContainer>
    <FormSelect
      value={billingFilterBy}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        setBillingFilterBy(value)
        if (value === "ACTIVE" || value === "INACTIVE") {
          setDrivers([])
          setStatus(value)
          setType("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
          // getDriversFunc()
        }
        if (value === "INTERNAL" || value === "EXTERNAL") {
          setDrivers([])
          setType(value)
          setStatus("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
          //  getDriversFunc()
        }
        if (value === "DIETS" || value === "MILEAGE") {
          setDrivers([])
          setPaymentType(value)
          setStatus("")
          setType("")
          setLimit(30)
          setOffset(0)
        }
        if (value === "" || value === "1") {
          setDrivers([])
          setStatus("")
          setType("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
          // getDriversFunc()
        }
      }}

      defaultValue="1"
      containerStyle={{ width: 220 }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('DRIVERS.forms.drivers.buttons.filterBy'), icon: filterByIcon },
        { value: 'ACTIVE', label: i18n.t('DRIVERS.forms.drivers.buttons.active'), icon: filterByIcon },
        { value: 'INACTIVE', label: i18n.t('DRIVERS.forms.drivers.buttons.inactive'), icon: filterByIcon },
        { value: 'INTERNAL', label: i18n.t('DRIVERS.forms.drivers.buttons.internal'), icon: filterByIcon },
        { value: 'EXTERNAL', label: i18n.t('DRIVERS.forms.drivers.buttons.external'), icon: filterByIcon },
        { value: 'DIETS', label: i18n.t('DRIVERS.forms.drivers.buttons.diets'), icon: filterByIcon },
        { value: 'MILEAGE', label: i18n.t('DRIVERS.forms.drivers.buttons.mileage'), icon: filterByIcon },
      ]}
      required
    />
    {/* <FormSelect
    value={billingFilterBy}
    onChange={({ target: { value } }) => setBillingFilterBy(value)}
    containerStyle={{ width: 220 }}
    items={[
      { value: '1', label: i18n.t('PROFILE.forms.billing.buttons.search'), icon: searchIcon },
    ]}
    required
  /> */}
    <TextInput
      icon={searchIcon}
      value={search}
      type="text"
      placeholder={i18n.t('DRIVERS.forms.drivers.searchPlaceHolder')}
      containerStyle={{ width: 250 }}
      onChange={(e) => {
        // if (e.target.value === "") {
        // }
        getDriversFunc()

        setSearch(e.target.value)
      }}
      onKeyPress={handleKeyPress}
    // disabled={loading}
    />
    <CategoryButton
      onClick={() => exportDriversFunc()}
      active={false}
      icon=""
      title={i18n.t('DRIVERS.forms.drivers.buttons.export')}
    />
    <CategoryButton
      onClick={() => navigate(DRIVER_DETAILS_ROUTE)}
      active
      icon=""
      title={i18n.t('DRIVERS.forms.drivers.buttons.add')}
    />
  </ProfileBillingContainer>

// console.log("drivers: ", drivers)
  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            loading={loading}
            type="drivers"
            header={tableHeader}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(offset + increaseLimit)
                setLimit(limit + increaseLimit)
              }

              getDriversFunc()
            }}
            updateTable={(updatedDriver) => {
              setDrivers([])
              setOffset(0)
              setLimit(30)

              if (updatedDriver) {
                setDrivers(drivers.map(driver => driver._id === updatedDriver._id ? updatedDriver : driver))
              }

            }}
            titles={[
              { label: i18n.t('DRIVERS.forms.drivers.picture') },
              { label: i18n.t('DRIVERS.forms.drivers.name') },
              { label: i18n.t('DRIVERS.forms.drivers.lastnames') },
              { label: i18n.t('DRIVERS.forms.drivers.email') },
              { label: i18n.t('DRIVERS.forms.drivers.type') },
              { label: i18n.t('DRIVERS.forms.drivers.payments') },
              { label: i18n.t('DRIVERS.forms.drivers.status') },
              { label: i18n.t('DRIVERS.forms.drivers.actions') },
            ]}
            data={drivers}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default DriversScreen
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json';
import es from './languages/es.json';
import de from './languages/de.json';
import fr from './languages/fr.json';
import pt from './languages/pt.json';
import it from './languages/it.json';
const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  },
  pt: {
    translation: pt
  },
  it: {
    translation: it
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng:
      localStorage.getItem('language') === 'us'
        ? 'en'
        : localStorage.getItem('language') || 'es',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

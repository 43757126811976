import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from '../src/redux/store.ts';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "./config"
import client from './utils/api';
import {ApolloProvider} from '@apollo/client';
import moment from 'moment-timezone'
import moment2 from 'moment'
import 'moment/locale/es'
const promise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);
moment.tz.setDefault(config.TIME_ZONE);
moment2.locale(localStorage.getItem('language') || 'es')

ReactDOM.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Elements stripe={promise}>
            <App />
          </Elements>
        </Provider>
      </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

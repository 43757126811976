import styled from 'styled-components';

export const FormFileInputContainer = styled.div`
width: 100px;
height: 100px;
border: 1px solid rgba(0,0,0,0.23);
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
margin: 5px;
position: relative;
`;
export const FormMainContainer = styled.div`
display: flex;
flex-direction: column;
`;

export const AddFileIcon = styled.img`
width: 30px;
height: 30px;
z-index: 1;
`;

export const BackgroundPicture = styled.img`
width: 100%;
height: 100%;
position: absolute;
z-index: 0;
`;

export const FormTitle = styled.p`
font-size: 11px;
margin-bottom: 0;
margin-left: 5px;
`;
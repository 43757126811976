import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  // ProfileButtonsContainer,
  ProfileFormContainer,
  // ProfileFormInputsContainer,
  // ProfileFormsContainer,
  // ProfileLastButtonsContainer,
  // FixedAmountText,
  // ProfileSmallButtonGroup,
  // ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  // ProfileFormSeparator,
  ProfileBillingContainer,
  // ProfileTableContainer,
  TableTitle,
  // TableInfo,
  TableInfoContainer
} from "./vehicles-styles";
import i18n from '../../i18n/i18n';
import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { VEHICLE_DETAILS_ROUTE } from "../../routes/routes";
import { getVehiclesAction, exportVehicles } from "./vehicles-actions";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import TextInput from "../../components/inputs/text-input/TextInput";
import { useSelector } from "react-redux";

const VehiclesScreen = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [vehicles, setVehicles] = useState([])
  const [filterBy, setFilterBy] = useState("1")
  const [loading, setLoading] = useState(false)
  // const [vehiclePlate, setVehiclePlate] = useState("")
  const [search, setSearch] = useState("")
  const [type, setType] = useState("")
  const isMounted = React.useRef(true)
  const user = useSelector((state) => state).userSlice

  useEffect(() => {

    // if (isMounted.current) {
    getVehiclesFunc()
    // }

    if(user?.plan_details?.status === "CANCELED"){
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }
    
    return () => {
      isMounted.current = false;
    };
  }, [type, search])

  const getVehiclesFunc = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination:{
          limit: limit,
          offset: offset
        },
        plate: !reset ? search || null : null,
        vehicle_type: !reset ? type || null : null,
        year: null,
        model: null,
        itv_date: null,
        brand: null
      }
      const res = await getVehiclesAction(data)
      if(res?.data?.myVehicles?.length <= 0){
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let vehiclesArray = vehicles.concat(res?.data?.myVehicles)
      if ((!search && !type) || limit > 30) {
        setVehicles(vehiclesArray.filter((item, index, self) => index === self.findIndex((t) => t.plate === item.plate)))
      } else {
        setVehicles(res?.data?.myVehicles)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setVehicles([])
      getVehiclesFunc()
    }
  };

  const exportVahiclesFunc = async () => {
    try {
      const data = {
        pagination:{
          limit: limit,
          offset: offset
        },
        // vehicle_type: type || null
        // plate: search || null
      }
      const res = await exportVehicles(data)
      var win = window.open(res.data.exportMyVehicles.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const header = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('DRIVERS.forms.drivers.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={filterBy}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 99999 }}
      defaultValue="1"
      onChange={({ target: { value } }) => {
        setFilterBy(value)
        if (value === "TRUCK" || value === "TRAILER") {
          setVehicles([])
          setType(value)
          // getVehiclesFunc()
          setSearch("")
        }
        if (value === "" || value === "1") {
          setVehicles([])
          setType("")
          setSearch("")
          // getVehiclesFunc()
        }

      }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('VEHICLES.forms.vehicles.buttons.filterBy'), icon: filterByIcon },
        { value: 'TRAILER', label: i18n.t('VEHICLES.trailer'), icon: filterByIcon },
        { value: 'TRUCK', label: i18n.t('VEHICLES.truck'), icon: filterByIcon },
      ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('VEHICLES.searchPlaceHolder')}
      onChange={async (e) => {
        setSearch(e.target.value)
        if (e.target.value === "") {
          getVehiclesFunc(true)
        }


      }}
      onKeyPress={handleKeyPress}
      // disabled={loading}
    />
    <CategoryButton
      onClick={() => exportVahiclesFunc()}
      active={false}
      icon=""
      title={i18n.t('PROFILE.forms.billing.buttons.export')}
    />
    <CategoryButton
      onClick={() => navigate(VEHICLE_DETAILS_ROUTE)}
      active
      icon=""
      title={i18n.t('VEHICLES.forms.vehicles.buttons.add')}
    />
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="vehicles"
            header={header}
            onEndReach={() => {
              if(keepIncreasing){
                setOffset(offset + increaseLimit)
                setLimit(limit + increaseLimit)
              }

              getVehiclesFunc()
            }}
            titles={[
              { label: i18n.t('VEHICLES.forms.vehicles.enrollment') },
              { label: i18n.t('VEHICLES.forms.vehicles.type') },
              { label: i18n.t('VEHICLES.forms.vehicles.brand') },
              { label: i18n.t('VEHICLES.forms.vehicles.model') },
              { label: i18n.t('VEHICLES.forms.vehicles.year') },
              { label: i18n.t('VEHICLES.forms.vehicles.date') },
              { label: i18n.t('VEHICLES.forms.vehicles.actions') },
            ]}
            data={vehicles}
            loading={loading}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default VehiclesScreen
import { gql } from '@apollo/client';

export const LISTEN_UNREAD_MESSAGES_SUBSCRIPTION = gql`
subscription unreadMessages {
  unreadMessages {
        total
  }
}
`;

export const LISTEN_RUNNING_TASKS_SUBSCRIPTION = gql`
subscription runningTasks {
  runningTasks {
        total
  }
}
`;

export const NEW_NOTIFICATION_SUBSCRIPTION = gql`
subscription newNotification {
  newNotification {
        datetime
        message
        read
        to_user_id
  }
}
`;

export const LISTEN_PENDING_TASKS_SUBSCRIPTION = gql`
subscription pendingTasks {
  pendingTasks {
        total
  }
}
`;

export const LISTEN_OPEN_INCIDENTS_SUBSCRIPTION = gql`
subscription openIncidents {
  openIncidents {
        total
  }
}
`;

export const LISTEN_COORDS_SUBSCRIPTION = gql`
subscription newCoord {
  newCoord {
    journeys {
      pending
      coords {
        country
        x
        y
      }
      vehicle
      trailer
      driver
      incidents
      tasks {
        _id
        name
      } 
   }
  }
}
`;

export const GET_RUNNING_TASKS_MUTATION = gql`
query runningTasks {
  runningTasks {
    total
  }
}
`;

export const GET_PENDING_TASKS_MUTATION = gql`
query pendingTasks {
  pendingTasks {
    total
  }
}
`;

export const GET_OPEN_INCIDENTS_MUTATION = gql`
query openIncidents {
  openIncidents {
    total
  }
}
`;

export const GET_UNREAD_MESSAGES_MUTATION = gql`
query unreadMessages {
  unreadMessages {
    total
  }
}
`;

export const GET_MAP_COORDS_MUTATION = gql`
query map {
  map {
    journeys {
      pending
      coords {
        country
        x
        y
      }
      vehicle
      trailer
      driver
      incidents
      tasks {
        _id
        name
      }
    }
  }
}
`;
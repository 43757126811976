import styled from 'styled-components';

export const MainContainer = styled.div`
width: 100vw;
height: 90vh;

@media (max-width: 900px) {
    height: 100%;
}
`;

export const RoutesContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
`;

export const RouteContainer = styled.div`
width: 80%;
height: 100%;
display: flex;
z-index: 1;
@media (max-width: 1000px) {
    width: 100%;
}
`;

export const RouteContainerAuth = styled.div`
width: 100%;
height: 100%;
display: flex;
z-index: 1;
`;
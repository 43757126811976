// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  CREATE_DRIVER_MUTATION,
  GET_DRIVERS_MUTATION,
  UPDATE_DRIVER_MUTATION,
  UPDATE_DRIVER_STATUS_MUTATION,
  EXPORT_DRIVERS_MUTATION,
  UPDATE_DRIVER_PIC_MUTATION,
  REMOVE_DRIVER_MUTATION,
  GET_DRIVER_TYPES_MUTATION,
  GET_DRIVER_PAYMENT_TYPES_MUTATION,
  FINISH_JOURNEY_MUTATION
} from './driver-details-queries';
// import {handleAlert} from '../../general/functions';

/**
 * createDriverAction - action for create a driver
 *
 * @param {object} formData - createDriverAction data
 * @returns {any}
 */
export const createDriverAction = (
  formData,
  images
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CREATE_DRIVER_MUTATION,
        variables: {
          input: {
            ...formData
          },
          images: images
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeDriverAction - action for create a driver
 *
 * @param {string} driverId - driver id
 * @returns {any}
 */
export const removeDriverAction = (
  driverId,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_DRIVER_MUTATION,
        variables: {
          driver_id: driverId,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateDriverPicAction - action for create a driver
 *
 * @param {File} file - updateDriverPicAction file
 * @returns {any}
 */
export const updateDriverPicAction = (
  driver_id,
  file,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_DRIVER_PIC_MUTATION,
        variables: {
          driver_id: driver_id,
          file: file,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateDriverAction - action for update a driver
 *
 * @param {String} driver_id - driver id
 * @param {object} formData - updateDriverAction data
 * @returns {any}
 */
export const updateDriverAction = (
  driver_id,
  formData,
  images
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_DRIVER_MUTATION,
        variables: {
          driver_id: driver_id,
          input: {
            ...formData
          },
          images
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateDriverStatusAction - action for update a driver
 *
 * @param {String} driver_id - driver id
 * @param {String} status - driver status
 * @returns {any}
 */
export const updateDriverStatusAction = (
  driver_id,
  status,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_DRIVER_STATUS_MUTATION,
        variables: {
          driver_id: driver_id,
          status: status,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};


/**
 * getDrivers - action for get drivers
 *
 * @param {object} formData - getDrivers data
 * @returns {any}
 */
 export const getDrivers = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getDrivers / formData: ", formData)
      const response = await client.query({
        query: GET_DRIVERS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getDrivers / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getDrivers / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getDriverTypes - action for get driver types
 *
 * @param {object} formData - getDrivers data
 * @returns {any}
 */
 export const getDriverTypes = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_DRIVER_TYPES_MUTATION,
        variables: {
          input: {
            // ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * setDriverPaymentTypes - action for get driver payment types
 *
 * @param {object} formData - getDrivers data
 * @returns {any}
 */
 export const getDriverPaymentTypes = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_DRIVER_PAYMENT_TYPES_MUTATION,
        variables: {
          input: {
            // ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportDrivers - action for get drivers
 *
 * @param {object} formData - exportDrivers data
 * @returns {any}
 */
 export const exportDrivers = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_DRIVERS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * finishJorneyAction - action for finish driver jorney
 *
 * @param {object} formData - finishJorneyAction data
 * @returns {any}
 */
 export const finishJorneyAction = (
  driver_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: FINISH_JOURNEY_MUTATION,
        variables: {
          driver_id: driver_id,
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

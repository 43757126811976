import config from '../config';
import userSlice from './reducers/user.ts';
import appSlice from './reducers/app.ts';
import {persistReducer, persistStore} from 'redux-persist';
import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {encryptTransform} from 'redux-persist-transform-encrypt';
import {useDispatch, useSelector, TypedUseSelectorHook} from 'react-redux';
import storage from 'redux-persist/lib/storage';

export const reducers = combineReducers({
  userSlice,
  appSlice
});

const persistConfig = {
  key: '@truckdriverapp',
  storage: storage,
  whitelist: ['userSlice', 'appSlice'],
  transforms: [
    encryptTransform({
      secretKey: config.SECRET_KEY_STORAGE,
      onError: function (error) {
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const storeTruck = configureStore({
  reducer: persistedReducer,
  middleware: [],
});

persistStore(storeTruck);

type AppDispatch = typeof storeTruck.dispatch;

export type RootState = ReturnType<typeof storeTruck.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default storeTruck;

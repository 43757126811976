import { ApolloClient, split, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import Store from '../redux/store.ts';
import { onError } from '@apollo/client/link/error';
import config from '../config';
import { setGeneralLogout } from "../redux/reducers/user.ts";
import { openAlert } from "../redux/reducers/app.ts";
import i18n from '../i18n/i18n';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';

const httpLink = createUploadLink({
  uri: config.API_URL_APOLLO_CLIENT,
});

// console.log('config.API_URL_APOLLO_CLIENT: ', config.API_URL_APOLLO_CLIENT);
const errorControl = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    // console.log('graphQLErrors: ', graphQLErrors);
    const error = graphQLErrors[0];
    const {
      statusCode,
      // type, 
      // message,
      langs
    } = error
    // localStorage.setItem("error-message", String(message))
    localStorage.setItem("error-code", String(statusCode))
    // console.log('api error ', error);
    // console.log('error code ', statusCode);
    // console.log('error message ', message);

    if(statusCode !== 996){
      if (statusCode) {
        Store.dispatch(openAlert({
          alertType: "error",
          alertMessage: langs[String(localStorage.getItem("language") || "es")],
          errorCode: statusCode,
        }))
      } else {
        Store.dispatch(openAlert({
          alertType: "error",
          alertMessage: i18n.t("MESSAGES.internalError"),
          errorCode: statusCode,
        }))
      }
    }

    // if (type === 'userNotActive') return;
    if (statusCode === 401 || statusCode === 999) {
      Store.dispatch(openAlert({
        alertType: "error",
        alertMessage: langs[String(localStorage.getItem("language") || "es")],
        errorCode: statusCode,
      }))
      Store.dispatch(setGeneralLogout())
      return;
    }

  }
  if (networkError && networkError?.message !== "undefined") {
    let message = i18n.t("MESSAGES.internalError")
    const error = JSON.parse(JSON.stringify(networkError));
    // console.log('networkError: ', error);
    // console.log('networkError / error: ', error);
    if (error.response?.status >= 500) {
      message = i18n.t("ERRORS.connectionError1");
    } else {
      message = i18n.t("ERRORS.connectionError2");
    }
    Store.dispatch(openAlert({
      alertType: "error",
      alertMessage: message,
      isAlertOpen: true,
    }))
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.API_URL_APOLLO_SUBSCRIPTION,
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem('token');
      const finalToken = token ? `Bearer ${token}` : "";
      // console.log("api.js / confirmEmailCode: ", confirmEmailCode)
      // console.log("api.js / token: ", token)
      // console.log("api.js / finalToken: ", finalToken)
      return {
        authorization: finalToken
      };
    },
  }),
);
// wsLink.subscriptionClient.close();

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  const finalToken = token ? `Bearer ${token}` : "";
  // console.log("api.js / confirmEmailCode: ", confirmEmailCode)
  // console.log("api.js / token: ", token)
  // console.log("api.js / finalToken: ", finalToken)
  return {
    headers: {
      ...headers,
      Authorization: finalToken
    },
  };
});

const client = new ApolloClient({
  link: errorControl.concat(authLink.concat(splitLink)),
  cache: new InMemoryCache(),
});

export default client;

import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../utils/colors';

export const ProfileContainer = styled.div`
width: 100%;
height: 100%;
`;

export const ProfileContentContainer = styled.div`
width: 100%;
height: 100%;
flex-direction: column;
max-width: 1000px;
margin: auto;
padding-top: 30px;
`;

export const ProfileFormContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: column;

@media (max-width: 900px) {
  align-items: center;
justify-content: center;
}

`;

export const ProfileFormsContainer = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;

@media (max-width: 1200px) {
  align-items: center;
justify-content: center;
}
`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProfileFormInputsContainer = styled.div`
width: 470px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileLastButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;

@media (min-width: 1200px) {
  justify-content: flex-end;
  padding-right: 16%;
}
@media (max-width: 1200px) {
align-items: center;
justify-content: center;
}
`;

export const ProfileFormSeparator = styled.div`
width: 100%;
background: #302521;
opacity: 0.1;
height: 2px;
margin-top: 20px;
margin-bottom: 20px;
`;

export const GeneralErrorText = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 5px;
`;

export const GeneralErrorContainer = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
`;
import React from "react";
import {
  EnterCodeContainer,
  EnterCodeButtonsContainer,
  EnterCodeTitle,
  EnterCodeDescription,
  MessageIcon,
  PaddingTextContainer,
  BackButtonContainer
} from "./enter-code-screen-styles";
import TextInput from "../../components/inputs/text-input/TextInput";
import keyIcon from "../../assets/general/key-icon.svg";
import { Button, ButtonText } from "../home/home-styles";
// import { TextAsButton } from "../header/header-styles";
import { COLORS_TRUCK } from '../../utils/colors';
import i18n from '../../i18n/i18n';
// import BackButton from "../../components/back-button/BackButton";
import messageIcon from "../../assets/restore-password/message-icon.svg";
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RESTORE_PASSWORD_ROUTE, ENTER_NEW_PASSWORD_ROUTE } from "../../routes/routes";
import BackButton from "../../components/back-button/BackButton";
import { verifyTokenAction } from "../restore-password/forgot-password-actions";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";

export const Span = ({ children, onClick, style }) => (
  <span
    style={Object.assign(
      { cursor: 'pointer' },
      style
    )}
    onClick={onClick}
  >
    {children}
  </span>
);

const EnterCodeScreen = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  return (
    <EnterCodeContainer>
      <EnterCodeButtonsContainer>
        <BackButtonContainer>
          <BackButton route={RESTORE_PASSWORD_ROUTE} />
        </BackButtonContainer>
        <MessageIcon src={messageIcon} />
        <PaddingTextContainer>
          <EnterCodeTitle>
            {i18n.t('ENTER_CODE.title')}
          </EnterCodeTitle>
          <EnterCodeDescription>
            {i18n.t('ENTER_CODE.description')}
          </EnterCodeDescription>
        </PaddingTextContainer>
        <TextInput
          value={code}
          onChange={({ target: { value } }) => setCode(value)}
          icon={keyIcon}
          placeholder={i18n.t('ENTER_CODE.input-placeholder')}
          disabled={loading}
        />
        <Button
          disabled={code === "" || loading}
          onClick={ async () => {
            try {
              setLoading(true);
              localStorage.setItem("confirm-email-code", String(code))
              const result = await verifyTokenAction(code)
              navigate(ENTER_NEW_PASSWORD_ROUTE)
              setLoading(false);
            } catch(e){
              setLoading(false);
              dispatch(openAlert({
                alertType: "error",
                isAlertOpen: true
              }))
            }
          }} style={{ width: 300, marginTop: 20, marginBottom: 20 }}>
          <ButtonText>
            {i18n.t('ENTER_CODE.verify')}
          </ButtonText>
        </Button>
        <PaddingTextContainer>
          <Trans i18nKey='ENTER_CODE.verify-text'>
            bla
            <Span style={{ color: COLORS_TRUCK.ORANGE_TRUCK }} onClick={() => navigate(RESTORE_PASSWORD_ROUTE)}>bla</Span>
            bla
          </Trans>
        </PaddingTextContainer>
      </EnterCodeButtonsContainer>
    </EnterCodeContainer>
  )
}

export default EnterCodeScreen
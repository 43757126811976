import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';
import truckImage from "../../assets/login/orange-truck.png"

export const EnterNewPasswordContainer = styled.div`
width: 100%;
background-image: url(${truckImage});
background-repeat: no-repeat;
background-size: cover;
@media (min-width: 420px) {
    height: 90vh;
  }
`;

export const EnterNewPasswordButtonsContainer = styled.div`
max-width: 500px;
height: 100%;
background: ${COLORS_TRUCK.WHITE_TRUCK};
margin-left: auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media (max-width: 420px) {
    padding-top: 50px;
  }
`;

export const PasswordPointsContainer = styled.div`
display: flex;
align-items: center;
`;

export const TruckLogoWords = styled.img`
width: 100px;
height: 100px;
`;

export const CircleIcon = styled.img`
width: 10px;
height: 10px;
margin-right: 10px;
`;

export const EnterNewPasswordTitle = styled.h1`
color: ${COLORS_TRUCK.BLACK_TRUCK};
font-size: 30px;
text-align: center;
`;

export const EnterNewPasswordDescription = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
`;

export const EnterNewPasswordDescriptionPoint = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
margin: 0;
margin-top: 5px;
`;

export const PaddingTextContainer = styled.div`
padding-left: 100px;
padding-right: 100px;
@media (max-width: 420px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const MessageIcon = styled.img`
width: 100px;
height: 100px;
`;

export const BackButtonContainer = styled.div`
margin-left: 100px;
align-self: flex-start;
@media (max-width: 420px) {
    margin-left: 10px;
  }
`;
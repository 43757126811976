import React from "react";
import { SelectButtonContainer, SelectButtonContent, ListContainer, ListItem } from "./select-button-styles";
import { COLORS_TRUCK } from "../../../utils/colors";
import './select-button.css';

import Loader from "../../loader/Loader";
import { useState } from "react";
const SelectButton = ({ items, active, title, width, loading, style, exportExcel, exportCSV, titleStyle }) => {
  const [list, setList] = useState(false)
  const [option, setOption] = useState('')

  const showList = (e) => {
    if(e) setList(!list)
  }

  const getOption = (data) => {
    let { id } = data.target
    if(id) exportFunction(id)
  }

  const exportFunction = (option) => {
    option == 'XLSX' ? exportExcel() : exportCSV()
  }
  return (
   <>
        <SelectButtonContainer
            onClick={showList}
            style={Object.assign(
                loading ? { cursor: "default" } : {},
                active ? { color: COLORS_TRUCK.WHITE_TRUCK, background: COLORS_TRUCK.ORANGE_TRUCK, width: width || 150 } : { color: COLORS_TRUCK.ORANGE_TRUCK, width: width || 150 },
                style
            )}
        >
            {loading
                ? <>
                <Loader color={active ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK } />
                </>
                : <>
                <SelectButtonContent style={Object.assign(active ? { color: COLORS_TRUCK.WHITE_TRUCK } : { color: COLORS_TRUCK.ORANGE_TRUCK }, titleStyle)}>
                    {title}
                </SelectButtonContent>
            </>}
            
            {
                list && !loading ?
                    <ListContainer>
                        {
                          items.map(item => (
                            <ListItem id={item.id} onClick={ getOption }> { item.name }</ListItem>
                          ))
                        }
                    </ListContainer>
                    :
                    <></>
            }
        </SelectButtonContainer>
   </>
  )
}

export default SelectButton
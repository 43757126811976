import React from "react";
import {
  TextInputContainer,
  InputIcon,
  CustomTextInput,
  InputTitle,
  FormTextInputMainContainer,
  CopyIcon,
  ErrorMessage,
  CustomTextInputMaps
} from "./form-text-input-styles";
import copyIcon from "../../../../assets/tasks/copy-icon-red.svg"
import { COLORS_TRUCK } from "../../../../utils/colors";
import config from "../../../../config";
import i18n from "../../../../i18n/i18n";

const FormTextInput = ({
  allowJustLetters,
  mainContainerStyle,
  containerStyle,
  icon,
  title,
  value,
  onChange,
  iconRightClick,
  rightComponent,
  canCopy,
  errorMessage,
  titleStyle,
  leftComponent,
  showError,
  disabled,
  required,
  type,
  rightIcon,
  onKeyPress,
  placeholder,
  isAddressAutocomplete,
  ref
}) => {
  return (
    <FormTextInputMainContainer style={mainContainerStyle}>
      {title ? <InputTitle style={titleStyle} >
        {title}{required ? <span style={{ fontSize: 20 }}>{` *`}</span> : ""}
      </InputTitle>
        : null}
      {leftComponent ?
        leftComponent
        : null}
      <TextInputContainer style={Object.assign({ border: ` 1px solid ${showError || errorMessage ? COLORS_TRUCK.ORANGE_TRUCK : `rgba(0, 0, 0, 0.23)`}` }, containerStyle)}>
        {icon && <InputIcon src={icon} />}
        {isAddressAutocomplete
          ?
          <CustomTextInputMaps
            apiKey={config.GOOGLE_PLACES_API}
            minLengthAutocomplete={3}
            // autocompletionRequest={{
            //   componentRestrictions: {
            //     country: ["es"],
            //   },
            // }}
            selectProps={{
              value: value,
              onChange: (data) => {
                onChange({ target: { value: data.label } })
              },
              // onBlur: () => {
              // },
              isOptionDisabled: () => false,
              isDisabled: disabled,
              isClearable: true,
              placeholder: value || i18n.t("GENERAL.searchAddress"),
              components: {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                NoOptionsMessage: () => i18n.t("GENERAL.noOptionAddress"),
              },
              styles: {
                placeholder: provided => ({
                  ...provided,
                  color: "#B4B4B5",
                  fontFamily: "Metropolis-Regular",
                  fontSize: 13,
                }),
                input: provided => ({
                  ...provided,
                  color: "black",
                  fontFamily: "Metropolis-Regular",
                  fontSize: 13,
                  ":focus": {
                    outline: "none",
                    outlineColor: 'transparent',
                  }
                }),
                menuList: provided => ({
                  ...provided,
                  color: "black",
                  padding: 10,
                }),
                menu: provided => ({
                  ...provided,
                  zIndex: 9999,
                }),
                control: provided => ({
                  ...provided,
                  fontSize: 13,
                  width: 297,
                  maxWidth: 300,
                  border: "none",
                  borderRadius: 5,
                  height: 58,
                  ":focus": {
                    outline: "none",
                    outlineColor: 'transparent',
                    textDecoration: "none",
                    border: "none",
                  },
                }),
              },
            }}
          />
          : <input
            ref={ref}
            disabled={disabled}
            value={value}
            required={required}
            type={type}
            placeholder={placeholder}
            onChange={({ target: { value } }) => {
              if (allowJustLetters && type === "text") {
                onChange({ target: { value: value.replace(/\d$/, '') } })
              } else {
                onChange({ target: { value } })
              }
            }}
            autocomplete="false"
            autoComplete="off"
            onFocus={(event) => {
              event.target.setAttribute('autocomplete', 'off');
            }}
            onKeyPress={onKeyPress}
            style={{
              backgroundColor: "transparent",
              width: "90%",
              height: "90%",
              border: "none",
              fontSize: 15,
              color: COLORS_TRUCK.BLACK_TRUCK,
              margin: 0,
              padding: 0,
              paddingLeft: 15
            }}
          />}
        {rightIcon && <InputIcon
          onClick={iconRightClick}
          style={{ cursor: 'pointer' }}
          src={rightIcon}
        />}
        {rightComponent ? rightComponent : null}
        {canCopy
          ? <CopyIcon onClick={() => navigator.clipboard.writeText(value)} src={copyIcon} />
          : null}
      </TextInputContainer>
      {errorMessage && <ErrorMessage>{`* ${errorMessage}`}</ErrorMessage>}
    </FormTextInputMainContainer>
  )
}

export default FormTextInput
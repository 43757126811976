// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  GET_JOURNEYS_MUTATION,
  EXPORT_JOURNEYS_MUTATION,
} from './journeys-queries';
// import {handleAlert} from '../../general/functions';

/**
 * getJourneysAction - action for get drivers
 *
 * @param {string} driver_id - getJourneysAction driver id
 * @param {object} formData - getJourneysAction filtering data
 * @returns {any}
 */
export const getJourneysAction = (
  driver_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getJourneysAction / driver_id: ", driver_id)
      // console.log("getJourneysAction / formData: ", formData)
      const response = await client.query({
        query: GET_JOURNEYS_MUTATION,
        variables: {
          driver_id: driver_id || null,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getJourneysAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getJourneysAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};
/**
 * exportJourneysAction - action for get drivers
 *
 * @param {string} driver_id - exportJourneysAction data
 * @param {object} formData - exportJourneysAction data
 * @returns {any}
 */
export const exportJourneysAction = (
  driver_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_JOURNEYS_MUTATION,
        variables: {
          driver_id: driver_id || null,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileButtonsContainer,
  ProfileFormContainer,
  ProfileFormInputsContainer,
  ProfileFormsContainer,
  // ProfileLastButtonsContainer,
  // FixedAmountText,
  // ProfileSmallButtonGroup,
  // ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  ProfileFormSeparator,
  // ProfileBillingContainer,
  // ProfileTableContainer,
  // TableTitle,
  // TableInfoContainer,
  TruckLogoWords,
  LoginTitle,
  RadioButton,
  SubTitle,
  PaymentFormTextsContainer,
  PaymentFormTitle,
  PaymentFormSubTitle,
  DiscountSuccessMessage,
  DiscountErrrorMessage,
  PaymentFormCodeInputContainer,
  PaymentDataTableContainer,
  PaymentDataTableItemsContainer,
  PaymentDataTableItemContainer,
  EnterCodeSubTitle,
  EnterCodeButtonsContainer,
  EnterCodeTitle,
  EnterCodeDescription,
  MessageIcon,
  PaddingTextContainer,
  EnterCodeSubTitleTwo,
  EnterCodeLink,
  EnterLinkContainer,
  EnterLinkDot,
  GeneralErrorContainer,
  GeneralErrorText,

  PlansContentContainer,
  HomeDescription,
  Button,
  ButtonText,
  PlansButtonContainer,
  PlanItemContainer,
  PlansItemsContainer,
  PlanTitle,
  PlanIcon,
  PlanUserIcon,
  PlanItemDescriptionContainer,
} from "./signup-styles";
import keyIcon from "../../assets/general/key-icon.svg";
import pointDot from "../../assets/confirm-code/point-dot.svg";
import messageIcon from "../../assets/restore-password/message-icon.svg";
import i18n from '../../i18n/i18n';
// import config from "../../config"
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
// import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
// import userPic from "../../assets/profile/user-pic.png";
// import UserCard from "../../components/user-card/UserCard";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormRadioButtonsInput from "../../components/inputs/forms/form-radio-buttons-input/FormRadioButtonsInput";
import passwordIcon from "../../assets/inputs/lock.svg";
import offEye from "../../assets/inputs/eye-off.svg";
import redEye from "../../assets/general/eye-icon-red.svg";
// import filterByIcon from "../../assets/profile/filter-by-icon.svg";
// import searchIcon from "../../assets/profile/search-icon.svg";
// import TruckTable from "../../components/truck-table/TruckTable";
// import { billingArray } from "./billingArray";
import FormNumberInput from "../../components/inputs/forms/form-number-input/FormNumberInput";
import truckLogoWords from "../../assets/login/truck-logo-words.svg";
import { COLORS_TRUCK } from "../../utils/colors";
import {
  getCitiesAction,
  getCountriesAction,
  getPlans,
  getStatesAction,
  registerAction,
  loginAction,
  createPaymentIntentAction,
  confirmPaymentIntentAction,
  getDiscounts,
  updateUserAction,
  getPreInvoiceAction,
  sendConfirmUserCode,
  confirmUserCode,
  checkEmailExist
} from "./signup-actions";
import { getCoinAction } from "../profile/profile-actions";
import BackButton from "../../components/back-button/BackButton";
import "./stripe.css";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../routes/routes";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { emailsRegex } from "../../utils/regex";
import { useSelector } from "react-redux";
// import { setToken } from "../../redux/reducers/user.ts";
// import { truncate } from "fs/promises";
import bronzeUserIcon from "../../assets/plans/bronze-user-icon.svg";
import silverUserIcon from "../../assets/plans/silver-user-icon.svg";
import goldUserIcon from "../../assets/plans/gold-user-icon.svg";
import { setUser } from "../../redux/reducers/user.ts";
import { openMenu } from "../../redux/reducers/app.ts";
import config from "../../config";

const SignupScreen = () => {
  const navigate = useNavigate()
  const isMounted = React.useRef(true)
  const dispatch = useAppDispatch()
  const app = useSelector((state) => state).appSlice
  const [currentForm, setCurrentForm] = useState("companyInfo")
  const [countries, setCountries] = useState([])
  const [showInputsErrors, setShowInputsErrors] = useState("")
  const [generalError, setGeneralError] = useState("")
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [coins, setCoins] = useState([])
  const [discountLoading, setDiscountLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const [discountCode, setDiscountCode] = useState("")
  const [preinvoice, setPreinvoice] = useState("")
  const [discountExist, setDiscountExist] = useState(false)
  const [discountPorcentage, setDiscountPorcentage] = useState("")
  const [paymentMethodId, setPaymentMethodId] = useState("")
  const [loading, setLoading] = useState(false)
  const [discountError, showDiscountError] = useState(false)
  const [discountErrorMessage, setDiscountErrorMessage] = useState(false)
  const [loggedUser, setLoggedUser] = useState(null)
  const [usersNumberError, setUsersNumberError] = useState("")
  const location = useLocation();
  //input Errors
  const [contactEmailError, setContactEmailError] = useState("")
  const [accessEmailError, setAccessEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  //confirm email
  const [isEmailConfirm, setIsEmailConfirm] = useState(false)
  const [confirmEmailCode, setConfirmEmailCode] = useState("")
  //company info
  const [socialReason, setSocialReason] = useState("")
  const [nif, setNif] = useState("")
  const [country, setCountry] = useState("")
  const [coin, setCoin] = useState("")
  const [countryIso2, setCountryIso2] = useState("")
  const [countryIso3, setCountryIso3] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [address, setAddress] = useState("")
  const [companyType, setCompanyType] = useState("")
  //representativeAndAccessData
  const [phone, setPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [representativeName, setRepresentativeName] = useState("")
  const [representativeLastName, setRepresentativeLastName] = useState("")
  const [dni, setDni] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [secondPassword, setSecondPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState("")
  const [secondPasswordShow, setSecondPasswordShow] = useState("")
  //paymentType
  const [paymentType, setPaymentType] = useState("MONTHLY")
  const [usersNumber, setUsersNumber] = useState("")
  const [plan, setPlan] = useState("")
  const [planObj, setPlanObj] = useState("")
  //paymentData
  // const [userNumber, setUserNumber] = useState("")
  // const [cardNumber, setCardNumber] = useState("")
  // const [holderName, setHolderName] = useState("")
  // const [cvc, setCvc] = useState("")
  //stripe
  const [creditCardError, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(async () => {
    if (isMounted.current) {
      await getPlansFunc()
    }

    if (isMounted.current) {
      await getCountries()
    }
    await getCoins()
    if (location.state && location.state.email && location.state.password && !loggedUser) {
      const loginRes = await loginAction({ email: location.state.email, password: location.state.password })
      const user = loginRes?.data?.login?.user
      if (user) {
        setUserFromLogin(user)
      }
    }
    return () => {
      isMounted.current = false;
      localStorage.removeItem("token")
      // localStorage.removeItem("confirm-email-code")
    };
  }, [country, countryIso2])

  // useEffect(() => {
  //   // if (isMounted.current) {
  //   // }


  //   return () => {
  //     localStorage.removeItem("token")
  //     localStorage.removeItem("confirm-email-code")
  //   }
  // }, [country, countryIso2])

  const setUserFromLogin = async (user) => {
    // await getCountries()
    const countryIso2 = countries.filter(item => user?.country === item.value)[0]?.iso2
    const countryIso3 = countries.filter(item => user?.country === item.value)[0]?.iso3
    // const countryIso3 = countries.filter(item => user?.country === value)[0]?.iso3
    setCountryIso2(countryIso2)
    setCountryIso3(countryIso3)
    getStates(countryIso2)
    getCities(countryIso2, user?.state)
    setProvince(user?.state)
    setCity(user?.city)
    setLoggedUser(user)
    //confirm email
    setIsEmailConfirm(user?.confirmed === "UNCONFIRMED" ? false : true)
    setConfirmEmailCode("")
    //company info
    setSocialReason(user?.company)
    setNif(user?.nif)
    setCountry(user?.country)
    setCoin(user?.coin)
    // setCountryIso2(country)
    setPostalCode(user?.zip)
    setAddress(user?.address)
    setCompanyType(user?.company_type.toUpperCase())
    //representativeAndAccessData
    setPhone(user?.phone)
    setContactEmail(user?.email_contact)
    setRepresentativeName(user?.name)
    setRepresentativeLastName(user?.surnames)
    setDni(user?.dni)
    setPosition(user?.position)
    setEmail(user?.email)
    // setPassword()

    // setPasswordShow()

    // hiddenLogin(true, true, true)
  }

  const getCoins = async () => {
    try {
      const response = await getCoinAction()
      const cointArray = response.data.findAllCoins.map( coin => { return { label: `${coin.name} ${coin.symbol}`, value: coin._id } })

      setCoins(cointArray)
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }
  
  const isCompanyInfoCompleted = () => {

    if (socialReason &&
      nif &&
      country &&
      province &&
      // city &&
      postalCode &&
      address &&
      companyType &&
      coin
      ) {
      setShowInputsErrors(false)
      setGeneralError("")
      return true
    } else {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.completeAllFields"),
      //   isAlertOpen: true
      // }))

      return false
    }
  }

  const isRepresentativeAndAccessDataCompleted = () => {
    const emailValidation = contactEmail && !contactEmail.match(emailsRegex)
    const accessEmailValidation = email && !email.match(emailsRegex)
    const passwordValidation = password && password.length < 8
    const passwordMatchValidation = password !== secondPassword
    if (emailValidation) {
      setShowInputsErrors(true)
      setContactEmailError(i18n.t("SIGNUP.errors.contactEmailInvalid"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.errors.contactEmailInvalid"),
      //   isAlertOpen: true
      // }))
      // return false;
    } else {
      setContactEmailError("")
    }

    if (accessEmailValidation) {
      setShowInputsErrors(true)
      setAccessEmailError(i18n.t("SIGNUP.errors.accessEmailInvalid"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.errors.accessEmailInvalid"),
      //   isAlertOpen: true
      // }))
      // return false;
    } else {
      setAccessEmailError("")
    }

    if (passwordValidation) {
      setShowInputsErrors(true)
      setPasswordError(i18n.t("GENERAL.passwordLength"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("ERRORS.errors.passwordLength"),
      //   isAlertOpen: true
      // }))
      // return false;
    }

    if (passwordMatchValidation) {
      setShowInputsErrors(true)
      setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("ERRORS.errors.passwordLength"),
      //   isAlertOpen: true
      // }))
      // return false;
    }

    if (!passwordValidation && !passwordMatchValidation) {
      setPasswordError("")
    }

    if (emailValidation ||
      accessEmailValidation ||
      passwordValidation ||
      passwordMatchValidation) {
      return false
    }

    if (// phone &&
      // contactEmail &&
      representativeName &&
      representativeLastName &&
      dni &&
      position &&
      email &&
      password) {
      setShowInputsErrors("")
      setGeneralError("")

      return true
    } else {
      setShowInputsErrors(true)

      if (
        !(contactEmail && !contactEmail.match(emailsRegex)) &&
        !(email && !email.match(emailsRegex)) &&
        !(password && password.length < 8)
      ) {
        setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      }
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.completeAllFields"),
      //   isAlertOpen: true
      // }))
      return false
    }
  }

  const isPaymentPlanCompleted = () => {

    if (paymentType &&
      usersNumber &&
      plan) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } else {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.completeAllFields"),
      //   isAlertOpen: true
      // }))

      return false
    }
  }

  const isPaymentDataCompleted = () => {
    if (// userNumber &&
      // cardNumber &&
      // holderName &&
      // cvc
      !creditCardError &&
      !disabled) {
      return true
    } else {
      setError(i18n.t("SIGNUP.completeAllFields"))
      // dispatch(openAlert({
      //   alertType: "error",
      //   alertMessage: i18n.t("SIGNUP.completeAllFields"),
      //   isAlertOpen: true
      // }))
      return false
    }

  }


  // const plansToShow = plans?.map(item => { return { value: item?._id, label: item.translate[0].langs[String(localStorage.getItem("language") || "es").toLowerCase()] || item.name } })

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    },
    hidePostalCode: true
  };

  const getCities = async (countryIso2, province) => {
    try {
      const response = await getCitiesAction(countryIso2, province)
      const cities = response?.data?.cities?.map(city => { return { label: city.name, value: city._id } })
      setCities(cities)
    } catch (e) {
      // dispatch(openAlert({
      //   isAlertOpen: true
      // }))
    }
  }

  const getStates = async (countryIso2) => {
    try {
      const response = await getStatesAction(countryIso2)
      setStates(response?.data?.state?.map(state => { return { label: state.translate[0].langs[localStorage.getItem("language") || "es"], value: state._id } }))
    } catch (e) {
      // dispatch(openAlert({
      //   isAlertOpen: true
      // }))
    }
  }

  const getCountries = async () => {
    try {
      const response = await getCountriesAction()
      if (response && response.data) {
        setCountries(response.data.countries.map(country => { return { label: country.translate[0].langs[localStorage.getItem("language") || "es"], value: country._id, iso2: country.iso2, iso3: country.iso3 } }))
      }
    } catch (e) {
      // dispatch(openAlert({
      //   isAlertOpen: true
      // }))
    }
  }

  const getPlansFunc = async () => {
    try {
      const response = await getPlans()
      if (response && response.data && response.data.plans) {
        setPlans(response.data.plans)
      }
    } catch (e) {
      // dispatch(openAlert({
      //   isAlertOpen: true
      // }))
    }
  }

  const applyDiscount = async () => {
    if (discountCode) {
      try {
        setDiscountLoading(true)
        const discounts = await getDiscounts(discountCode)
        const discount = discounts?.data?.discounts[0]
        if (discounts?.data?.discounts.length > 0 && !discount?.active) {
          showDiscountError(true)
          setDiscountErrorMessage(i18n.t('SIGNUP.errors.discountCodeExpired'))
        } else {
          showDiscountError(true)
          setDiscountErrorMessage(i18n.t('SIGNUP.errors.discountCodeInvalid'))
        }

        if (discount?.active) {
          setDiscountExist(true)
          setDiscountPorcentage(discount.percentage)
          // dispatch(openAlert({
          //   alertType: "success",
          //   alertMessage: i18n.t("SIGNUP.successDiscount"),
          //   isAlertOpen: true
          // }))
          setDiscountLoading(false)
          showDiscountError(false)
        } else {
          setDiscountLoading(false)
          setDiscountExist(false)
          // dispatch(openAlert({
          //   alertType: "error",
          //   alertMessage: i18n.t("SIGNUP.errors.discountCodeInvalid"),
          //   isAlertOpen: true
          // }))
        }
      } catch (e) {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true
        }))
        setDiscountLoading(false)
        setDiscountExist(false)
        showDiscountError(false)
        // dispatch(openAlert({
        //   alertType: "error",
        //   alertMessage: i18n.t("SIGNUP.errors.discountCodeInvalid"),
        //   isAlertOpen: true
        // }))
      }
    }
  }

  const login = async () => {
    // if (email === "admin" && password === "admin") {
    //   dispatch(setUser({ name: 'esteban contreras', email: 'esteban@gmail.com', admin: true }))
    // } else {
    //   dispatch(setUser({ name: 'esteban contreras', email: 'esteban@gmail.com', admin: false }))
    // }
    try {
      setLoading(true)
      const response = await loginAction({ email, password })
      if (response && response.data) {
        const user = response.data.login.user
        const userType = response.data.login.type
        const token = response.data.login.access_token
        setLoading(false)
        localStorage.setItem("token", token)
        dispatch(openMenu())
        dispatch(setUser({
          ...user,
          userType: userType
        }))
      }
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const makePayment = async () => {
    try {
      setLoading(true)

      //CREATE PAYMENT METHOD
      const payment_method_id = await createPaymentMethod()
      //  setPaymentMethodId(payment_method_id)

      if(!payment_method_id){
        setLoading(false)
        return
      }
      // dispatch(setToken(access_token))
      let data = {
        payment_method: payment_method_id,
        payment_type: paymentType,
        plan_id: plan,
        users: Number(usersNumber),
      }

      if (discountCode && discountExist) {
        data.discount = discountCode
      }

      //HIDDEN LOGIN
      await hiddenLogin()

      //CREATE PAYMENT INTENT
      const res = await createPaymentIntentAction(data)
      const paymentIntentId = res.data.createPaymentIntent.payment_intent
      const paymentIntentStatus = res.data.createPaymentIntent.status

      // console.log("paymentIntentStatus: ", paymentIntentStatus)
      if (paymentIntentStatus !== "free_trial") {
        //CONFIRM PAYMENT INTENT
        const confirmRes =  await confirmPaymentIntentAction({
          payment_intent: paymentIntentId,
        })
        if (confirmRes.data.confirmPaymentIntent.status === "requires_action") {
          await login()
          const redirectUrl = confirmRes?.data?.confirmPaymentIntent?.data?.next_action?.redirect_to_url?.url
          // console.log("redirectUrl: ", redirectUrl)
          if (redirectUrl) {
            window.location.replace(redirectUrl)
          }

          return
        }
      }

      await login()
      dispatch(openAlert({
        alertType: "success",
        alertMessage: i18n.t("SIGNUP.successMessage"),
        isAlertOpen: true
      }))

      navigate(LOGIN_ROUTE)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const validateFirstStep = () => {
    if (isCompanyInfoCompleted()) {
      setCurrentForm("representativeAndAccessData")
    }
  }
  const validateSecondStep = () => {
    if (isCompanyInfoCompleted() && isRepresentativeAndAccessDataCompleted()) {
      setCurrentForm("paymentPlan")
    }
  }

  const validateThirdStep = () => {
    const minUsers = planObj?.min_users
    const maxUsers = planObj?.max_users

    if (usersNumber < minUsers || usersNumber > maxUsers) {
      setUsersNumberError(`${i18n.t('SIGNUP.errors.usersNumber')} ${minUsers} ${i18n.t('SIGNUP.errors.usersNumber2')} ${maxUsers}`)
      return
    } else {
      setUsersNumberError("")
    }

    if (isCompanyInfoCompleted() && isPaymentPlanCompleted() && isRepresentativeAndAccessDataCompleted()) {
      signup()
    }
  }

  const validateFourthStep = () => {
    if (isCompanyInfoCompleted() && isPaymentPlanCompleted() && isPaymentDataCompleted() && isRepresentativeAndAccessDataCompleted()) {
      makePayment()
    }
  }

  const getPreInvoice = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const lang = String(localStorage.getItem("language") || "ES").toUpperCase() === "US" ? "EN" : String(localStorage.getItem("language") || "ES").toUpperCase()
        //GET PRE INVOICE AGAIN
        let invoiceData = {
          payment_method: "CREDIT_CARD",
          plan_id: plan,
          payment_type: paymentType,
          users: Number(usersNumber),
          language: lang,
          iso3: countryIso3
        }

        if (discountExist && discountCode) {
          invoiceData.discount = discountCode
        }

        const response = await getPreInvoiceAction(invoiceData)
        const invoice = response?.data?.getPreInvoice
        setPreinvoice(invoice)
        resolve()
      } catch (e) {
        reject(e)
        dispatch(openAlert({
          isAlertOpen: true
        }))
      }
    })
  }

  const hiddenLogin = (redirect, resolveAll, canSetLoading) => {
    return new Promise(async (resolve, reject) => {
      try {
        //LOGIN
        const loginData = {
          email,
          password,
        }
        const loginRes = await loginAction(loginData)
        const user = loginRes.data.login.user

        setLoggedUser(user)
        setUserFromLogin(user)
        await getPreInvoice()

        if (user.plan_details.status !== "DISABLED") {
          dispatch(openAlert({
            alertType: "error",
            alertMessage: i18n.t("SIGNUP.you-already-have-an-account-and-you-paid"),
            isAlertOpen: true
          }))

          if (canSetLoading) {
            setLoading(false)
          }

          setCurrentForm("representativeAndAccessData")
          // navigate(LOGIN_ROUTE)
          return;
        }
        if (user.confirmed === "CONFIRMED") {
          setIsEmailConfirm(true)
          if (redirect) {
            if (canSetLoading) {
              setLoading(false)
            }

            setCurrentForm("paymentForm")
            return;
          }

        } else {
          setIsEmailConfirm(false)
          if (redirect) {
            setCurrentForm("confirmEmail")
          }
        }

        //SUCCESS
        if (canSetLoading) {
          setLoading(false)
        }

        const access_token = loginRes.data.login.access_token
        localStorage.setItem("token", access_token)
        resolve()
      } catch (e) {
        if (canSetLoading) {
          setLoading(false)
        }

        if (resolveAll) {
          resolve()
        } else {
          reject(e)
        }
      }
    })
  }

  const editUser = (formData) => {
    return new Promise(async (resolve, reject) => {
      try {
        //update user
        // await updateUserAction(formData)
        const updateUserRes = await updateUserAction(formData)
        setLoading(false)
        resolve()
      } catch (e) {
        resolve(e)
      }
    })
  }

  const signup = async () => {

    const lang = String(localStorage.getItem("language") || "ES").toUpperCase() === "US" ? "EN" : String(localStorage.getItem("language") || "ES").toUpperCase()
    let formData = {
      address: address || null,
      city: city || null,
      company: socialReason || null,
      company_type: companyType || null,
      country: country || null,
      dni: dni || null,
      coin: coin || null,
      email_contact: contactEmail || null,
      language: lang || null,
      name: representativeName || null,
      nif: nif || null,
      password: password || null,
      phone: phone ? String(phone) : null,
      position: position || null,
      state: province || null,
      surnames: representativeLastName || null,
      zip: postalCode || null,
      email: email || null
    }
    try {
      setLoading(true)

      //UPDATE USER IF EXIST
      const token = localStorage.getItem("token")
      if (token) {
        await editUser(formData)
        await hiddenLogin(true, false, true)
        if (loggedUser?.confirmed === "UNCONFIRMED") {
          await getConfirmEmailCode()
        }

        return;
      }


      //SIGNUP
      // formData.email = email
      formData.payment_method = "CREDIT_CARD"
      formData.payment_type = paymentType
      formData.plan_id = plan
      formData.users = Number(usersNumber)
      const response = await registerAction(formData)
      const invoice = response.data.signup.preinvoice
      setPreinvoice(invoice)

      //HIDDEN LOGIN
      await hiddenLogin(false, true, true)


      //SUCCESS
      // await getConfirmEmailCode()
      setCurrentForm("confirmEmail")
      setLoading(false)
    } catch (e) {
      const token = localStorage.getItem("token")
      const errorCode = localStorage.getItem("error-code")
      if (app.errorCode === 992 || errorCode === "992") {
        if (token) {
          await editUser(formData)
        }

        await hiddenLogin(true, true, true)
      } else {
        await hiddenLogin(true, true, true)
        setLoading(false)
        dispatch(openAlert({
          isAlertOpen: true
        }))
      }

    }

  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const createPaymentMethod = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            // Include any additional collected billing details.
            // name: representativeName,
          },
        })
      // console.log("createPaymentMethod / result", result)
      if (!result.error) {
        // Otherwise send paymentMethod.id to your server (see Step 4)
        const payment_method_id = result.paymentMethod.id
        resolve(payment_method_id)
      }
      
      if (result.error) {
          // console.log("createPaymentMethod / result.error", result.error)
          resolve()
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true,
            alertMessage: result?.error?.message
          }))
        }
      } catch (e) {
        // console.log("createPaymentMethod / error:", e)
        reject()
      }
    })
  };

  const confirmEmail = async () => {
    if (!confirmEmailCode) {
      setShowInputsErrors(true)
      return
    }

    try {
      setLoading(true)
      await hiddenLogin(true, false, true)
      // localStorage.setItem("confirm-email-code", confirmEmailCode)
      const response = await confirmUserCode(confirmEmailCode)

      // setCurrentForm("paymentForm")
      // setIsEmailConfirm(true)
      setLoading(false)
      // localStorage.removeItem("confirm-email-code")
      setCurrentForm("paymentForm")
    } catch (e) {
      // localStorage.removeItem("confirm-email-code")
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getConfirmEmailCode = (successMessage) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await sendConfirmUserCode(email)
        if (successMessage) {
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("SIGNUP.ENTER_CODE.email-sended"),
            isAlertOpen: true
          }))
        }

        resolve()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        dispatch(openAlert({
          isAlertOpen: true
        }))
        reject(e)
      }
    })
  }

  const validateEmail = async () => {
    if (email && !location.state && !localStorage.getItem("token")) {
      const emailExistRes = await checkEmailExist(email)
      if (emailExistRes.data.checkEmail.result) {
        setShowInputsErrors(true)
        setAccessEmailError(i18n.t("SIGNUP.errors.emailAlreadyExistError"))
        return false
      } else {
        setAccessEmailError("")
        return true
      }
    } else {
      return true
    }
  }
  const usersNumberMax = plans.filter(item => item._id === plan)[0]?.max_users
  const usersNumberMin = plans.filter(item => item._id === plan)[0]?.min_users
  const paymentPlanDisabled = loading || (localStorage.getItem("token") && (location?.state ? location.state.plan_details.status === "DISABLED" ? false : true : false))
  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <TruckLogoWords src={truckLogoWords} />
        {currentForm !== "confirmEmail" && <LoginTitle>
          {i18n.t('SIGNUP.title')}
        </LoginTitle>}
        <ProfileFormContainer>
          {currentForm === "companyInfo"
            ? <>
              <SubTitle>
                {i18n.t('SIGNUP.companyInfo')}
              </SubTitle>
              {/* <UserCard
                user={{
                  name: "Antonio García Segura",
                  position: "Transportes Hnos García - DIRECTOR",
                  picture: userPic
                }}
                onEditPicture={() => null}
              /> */}
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.socialReason')}
                    type={"text"}
                    value={socialReason}
                    showError={!socialReason && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setSocialReason(value)}
                    required
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.nif')}
                    type={"text"}
                    value={nif}
                    showError={!nif && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setNif(value)}
                    required
                  />
                  <FormRadioButtonsInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.companyType')}
                    value={companyType}
                    defaultValue="COMPANY"
                    showError={!companyType && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => loading ? null : setCompanyType(value)}
                    items={[
                      { value: 'COMPANY', label: i18n.t('SIGNUP.companyInfoTable.companyType1') },
                      { value: 'AGENCY', label: i18n.t('SIGNUP.companyInfoTable.companyType2') },
                    ]}
                    required
                  />
                  <FormSelect
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.country')}
                    value={country}
                    showError={!country && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => {
                      const iso2 = countries.filter(item => item.value === value)[0]?.iso2
                      const iso3 = countries.filter(item => item.value === value)[0]?.iso3
                      setCountry(value)
                      setCountryIso2(iso2)
                      setCountryIso3(iso3)
                      getStates(iso2)
                    }}
                    items={countries}
                    required
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer> 
                  {(country && states) && <FormSelect
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.province')}
                    value={province}
                    showError={!province && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => {
                      setProvince(value)
                      getCities(countryIso2, value)
                    }}
                    items={states}
                    required
                  />}
                  {(country && province && cities) && <FormSelect
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.city')}
                    value={city}
                    // showError={!city && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setCity(value)}
                    items={cities}
                  />}
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.address')}
                    type={"text"}
                    value={address}
                    showError={!address && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setAddress(value)}
                    required
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.companyInfoTable.postalCode')}
                    type={"text"}
                    value={postalCode}
                    showError={!postalCode && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setPostalCode(value)}
                    required
                  />
                  <FormSelect
                    disabled={loading}
                    title={i18n.t('PROFILE.forms.company.coin')}
                    value={coin}
                    onChange={({ target: { value } }) => setCoin(value)}
                    items={coins}
                    required
                    showError={!coin && showInputsErrors}
                  />
                </ProfileFormInputsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "representativeAndAccessData"
            ? <>
              <SubTitle>
                {i18n.t('SIGNUP.representativeAndAccessData.title')}
              </SubTitle>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.representativeName')}
                    type={"text"}
                    value={representativeName}
                    onChange={({ target: { value } }) => setRepresentativeName(value)}
                    showError={!representativeName && showInputsErrors ? true : false}
                    required
                    allowJustLetters
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.representativeLastName')}
                    type={"text"}
                    value={representativeLastName}
                    showError={!representativeLastName && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => {
                      // var letters = /^[A-Za-z]+$/;
                      // if (!value) {
                      // setRepresentativeLastName(value)
                      // }
                      // if (value.match(letters)) {
                      setRepresentativeLastName(value)
                      // }
                    }}
                    allowJustLetters
                    required
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.representativePosition')}
                    type={"text"}
                    value={position}
                    showError={!position && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setPosition(value)}
                    required
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.representativeDni')}
                    type={"text"}
                    value={dni}
                    showError={!dni && showInputsErrors ? true : false}
                    onChange={({ target: { value } }) => setDni(value)}
                    required
                  />
                  <FormTextInput
                  title={i18n.t('SIGNUP.representativeAndAccessData.contactPhone')}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    disabled={loading}
                    errorMessage={contactEmailError}
                    title={i18n.t('SIGNUP.representativeAndAccessData.contactEmail')}
                    type={"email"}
                    value={contactEmail}
                    onChange={({ target: { value } }) => setContactEmail(value)}
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.accessEmail')}
                    errorMessage={accessEmailError}
                    type={"email"}
                    //  placeHolder={localStorage.getItem("token") && loggedUser ? loggedUser.email : ""}
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    required
                    showError={!email && showInputsErrors ? true : false}
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.password')}
                    iconRightClick={() => setPasswordShow(!passwordShow)}
                    rightIcon={passwordShow ? offEye : redEye}
                    errorMessage={passwordError}
                    icon={passwordIcon}
                    type={passwordShow ? "text" : "password"}
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                    required
                    showError={!password && showInputsErrors ? true : false}
                  />
                  <FormTextInput
                    disabled={loading}
                    title={i18n.t('SIGNUP.representativeAndAccessData.repeatPassword')}
                    iconRightClick={() => setSecondPasswordShow(!secondPasswordShow)}
                    rightIcon={secondPasswordShow ? offEye : redEye}
                    errorMessage={passwordError}
                    icon={passwordIcon}
                    type={secondPasswordShow ? "text" : "password"}
                    value={secondPassword}
                    onChange={({ target: { value } }) => setSecondPassword(value)}
                    required
                    showError={!secondPassword && showInputsErrors ? true : false}
                  />
                </ProfileFormInputsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "paymentPlan"
            ? <>
              <SubTitle>
                {i18n.t('SIGNUP.subscriptionPlan.title')}
              </SubTitle>
              <PlansContentContainer>
                <PaddingTextContainer>

                  <HomeDescription>
                    {i18n.t('PLANS.description')}
                  </HomeDescription>
                  <PlansButtonContainer>
                    <Button
                      onClick={() => {
                        setPaymentType('MONTHLY')
                        // setPlansArray(plansArray)
                      }}
                      style={{ background: paymentType === "MONTHLY" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.WHITE_TRUCK }}
                    >
                      <ButtonText
                        style={{ color: paymentType === "MONTHLY" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK }}
                      >
                        {i18n.t('PLANS.monthly')}
                      </ButtonText>
                    </Button>
                    <Button
                      onClick={() => {
                        setPaymentType('ANNUALLY')
                        // setPlansArray(plansArray)
                      }}
                      style={{ background: paymentType === "ANNUALLY" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.WHITE_TRUCK }}
                    >
                      <ButtonText
                        style={{ color: paymentType === "ANNUALLY" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK }}
                      >
                        {i18n.t('PLANS.anual')}
                      </ButtonText>
                    </Button>
                  </PlansButtonContainer>
                  <HomeDescription style={{ color: COLORS_TRUCK.BLACK_TRUCK }}>
                    {paymentType === "ANNUALLY" ? i18n.t('PLANS.subcription-info-annually') : i18n.t('PLANS.subcription-info')}
                  </HomeDescription>
                </PaddingTextContainer>
                <PlansItemsContainer>
                  {plans.map(item =>
                    <PlanItemContainer
                      onClick={() => {
                        setUsersNumber("")
                        if (!paymentPlanDisabled) {
                          setPlan(item._id)
                          setPlanObj(item)
                        }
                      }}
                      style={{
                        border: showInputsErrors && !plan ? `2px solid rgb(248,72,18,0.8)` : String(item._id) === String(plan) ? `2px solid rgb(48, 37, 33, 0.5)` : "",
                        cursor: !paymentPlanDisabled ? "pointer" : "default"
                      }}>
                      <PlanTitle style={{ marginBottom: 10 }}>
                        {item.translate[0].langs[String(localStorage.getItem("language") || "es")]}
                      </PlanTitle>
                      <PlanIcon src={item?.icon?.fullpath} />
                      {/* <HomeDescription>
                        {paymentType === "ANNUALLY" ? item.prices.annual : item.prices.monthly}€
                      </HomeDescription>
                      + */}
                      <HomeDescription>
                        {`${paymentType === "ANNUALLY" ? item.prices.user_annual : item.prices.user_monthly}€/${i18n.t('PLANS.user')}`}
                      </HomeDescription>
                      <PlanItemDescriptionContainer>
                        <PlanUserIcon src={String(item._id) === String(config.BRONZE_PLAN_ID)
                          ? bronzeUserIcon
                          : String(item._id) === String(config.SILVER_PLAN_ID)
                            ? silverUserIcon
                            : String(item._id) === String(config.GOLD_PLAN_ID)
                              ? goldUserIcon
                              : ""}
                        />
                        <PlanTitle style={{ fontSize: 20, width: 150, textAlign: 'center' }}>
                          {item._id === config.BRONZE_PLAN_ID ?
                            `${i18n.t('PLANS.bronzePlanText')} ${item.max_users} ${i18n.t('PLANS.users')}`
                            : item._id === config.SILVER_PLAN_ID
                              ? `${i18n.t('PLANS.silverPlanText1')} ${item.min_users} ${i18n.t('PLANS.silverPlanText2')} ${item.max_users} ${i18n.t('PLANS.users')}`
                              : item._id === config.GOLD_PLAN_ID
                                ? `${i18n.t('PLANS.goldPlanText1')} ${item.min_users} ${i18n.t('PLANS.users')}`
                                : null}
                        </PlanTitle>
                      </PlanItemDescriptionContainer>
                      {/* <Button>
                        <ButtonText>
                          {i18n.t('PLANS.signup')}
                        </ButtonText>
                      </Button> */}
                    </PlanItemContainer>
                  )}
                </PlansItemsContainer>
              </PlansContentContainer>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer style={{ margin: "auto" }}>
                  {/* <FormRadioButtonsInput
                    disabled={paymentPlanDisabled}
                    title={i18n.t('SIGNUP.subscriptionPlan.paymentType')}
                    value={paymentType}
                    // defaultValue=""
                    onChange={({ target: { value } }) => paymentPlanDisabled ? null : setPaymentType(value)}
                    items={[
                      { value: 'MONTHLY', label: i18n.t('SIGNUP.subscriptionPlan.paymentType1') },
                      { value: 'ANNUALLY', label: i18n.t('SIGNUP.subscriptionPlan.paymentType2') },
                    ]}
                    required
                    showError={!paymentType && showInputsErrors ? true : false}
                  /> */}
                  {/* {plansToShow && plansToShow.length > 0
                    ? <FormRadioButtonsInput
                      disabled={paymentPlanDisabled}
                      title={i18n.t('SIGNUP.subscriptionPlan.plan')}
                      value={plan}
                      // defaultValue=""
                      onChange={({ target: { value } }) => {
                        if (!paymentPlanDisabled) {
                          setPlan(value)
                          setUsersNumber("")
                        }
                      }}
                      items={plansToShow}
                      required
                      showError={!plan && showInputsErrors ? true : false}
                    />
                    : null} */}
                  {(plan && plans) && <FormNumberInput
                    disabled={paymentPlanDisabled}
                    title={i18n.t('SIGNUP.subscriptionPlan.usersNumber')}
                    value={usersNumber}
                    onValueChange={(value) => paymentPlanDisabled ? null : setUsersNumber(value)}
                    required
                    max={usersNumberMax}
                    min={usersNumberMin}
                    errorMessage={usersNumberError}
                    showError={!usersNumber && showInputsErrors ? true : false}
                  />}
                </ProfileFormInputsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "paymentData"
            ? <>
              <SubTitle>
                {i18n.t('SIGNUP.paymentData.title')}
              </SubTitle>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer style={{ margin: "auto", minHeight: 200 }}>
                  <GeneralErrorContainer>
                    <GeneralErrorText>{i18n.t("SIGNUP.rememberPaymentAfterAMonth")}</GeneralErrorText>
                  </GeneralErrorContainer>
                  <form className="form-payment" id="payment-form" onSubmit={createPaymentMethod}>
                    <CardElement
                      id="card-element"
                      options={cardStyle}
                      onChange={handleChange}
                    />
                    {creditCardError && <SubTitle style={{ color: COLORS_TRUCK.ORANGE_TRUCK, marginTop: 10, fontSize: 14 }}>
                      {creditCardError}
                    </SubTitle>}
                    {/* <button
                      disabled={processing || disabled || succeeded}
                      id="submit"
                    >
                      <span id="button-text">
                        {processing ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          "Pay now"
                        )}
                      </span>
                    </button> */}
                    {/* Show any error that happens when processing the payment */}
                    {/* {error && (
                      <div className="card-error" role="alert">
                        {error}
                      </div>
                    )} */}
                    {/* Show a success message upon completion */}
                    {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
                      Payment succeeded, see the result in your
                      <a
                        href={`https://dashboard.stripe.com/test/payments`}
                      >
                        {" "}
                        Stripe dashboard.
                      </a> Refresh the page to pay again.
                    </p> */}
                  </form>
                  {/* <FormNumberInput
                  disabled={loading}
                    title={i18n.t('SIGNUP.paymentData.cardNumber')}
                    value={cardNumber}
                    onValueChange={(value) => setCardNumber(value)}
                    required
                    showError={!dni && showInputsErrors ? true : false}
                  />
                  <FormTextInput
                  disabled={loading}
                    title={i18n.t('SIGNUP.paymentData.holderName')}
                    type={"text"}
                    value={holderName}
                    onChange={({ target: { value } }) => setHolderName(value)}
                    required
                    showError={!dni && showInputsErrors ? true : false}
                  />
                  <FormNumberInput
                  disabled={loading}
                    title={i18n.t('SIGNUP.paymentData.cvc')}
                    value={cvc}
                    onValueChange={(value) => setCvc(value)}
                    required
                    showError={!dni && showInputsErrors ? true : false}
                  /> */}
                </ProfileFormInputsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "paymentForm" && preinvoice
            ? <>
              <SubTitle>
                {i18n.t('SIGNUP.paymentForm.subTitle')}
              </SubTitle>
              <ProfileFormsContainer style={{ justifyContent: 'center' }}>
                <ProfileFormInputsContainer style={{ flexDirection: 'column', width: 300, alignItems: 'baseline' }}>
                  <PaymentFormTextsContainer>
                    <PaymentFormTitle>
                      {i18n.t('SIGNUP.paymentForm.planName')}
                    </PaymentFormTitle>
                    <PaymentFormSubTitle>
                      {preinvoice.items[0].description}
                    </PaymentFormSubTitle>
                  </PaymentFormTextsContainer>
                  <PaymentFormTextsContainer>
                    <PaymentFormTitle>
                      {i18n.t('SIGNUP.paymentForm.paymentMethod')}
                    </PaymentFormTitle>
                    <PaymentFormSubTitle>
                      {i18n.t('SIGNUP.paymentForm.creditCard')}
                    </PaymentFormSubTitle>
                  </PaymentFormTextsContainer>
                  <PaymentFormTextsContainer>
                    <PaymentFormTitle>
                      {i18n.t('SIGNUP.paymentForm.paymentType')}
                    </PaymentFormTitle>
                    <PaymentFormSubTitle>
                      {paymentType === "MONTHLY" ? i18n.t('SIGNUP.paymentForm.monthly') : i18n.t('SIGNUP.paymentForm.annually')}
                    </PaymentFormSubTitle>
                  </PaymentFormTextsContainer>
                  <PaymentFormTextsContainer>
                    <PaymentFormTitle>
                      {i18n.t('SIGNUP.paymentForm.discountCode')}
                    </PaymentFormTitle>
                    <PaymentFormCodeInputContainer>
                      <FormTextInput
                        disabled={loading}
                        // title={i18n.t('SIGNUP.paymentForm.accessEmail')}
                        type={"text"}
                        // mainContainerStyle={{ width: 200 }}
                        containerStyle={{ width: 150 }}
                        value={discountCode}
                        onChange={({ target: { value } }) => setDiscountCode(value)}
                        required
                        showError={!discountCode && showInputsErrors ? true : false}
                      />
                      <CategoryButton
                        loading={discountLoading}
                        // loading={true}
                        onClick={() => applyDiscount()}
                        active={false}
                        disabled={loading || !discountCode || discountLoading}
                        width={80}
                        icon=""
                        title={i18n.t('SIGNUP.paymentForm.buttons.apply')}
                      />
                    </PaymentFormCodeInputContainer>
                    {discountExist && <DiscountSuccessMessage>
                      {i18n.t('SIGNUP.paymentForm.discountCodeApplyed')}
                    </DiscountSuccessMessage>}
                    {discountError && <DiscountErrrorMessage>
                      {discountErrorMessage}
                    </DiscountErrrorMessage>}
                  </PaymentFormTextsContainer>
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer style={{ flexDirection: 'column', width: 300, alignItems: 'baseline' }}>
                  <PaymentDataTableContainer>
                    <PaymentDataTableItemsContainer>
                      <PaymentDataTableItemContainer>
                        <PaymentFormTitle>
                          {i18n.t('SIGNUP.paymentForm.description')}
                        </PaymentFormTitle>
                        {/* <PaymentFormSubTitle>
                          {preinvoice.items[0].description}
                        </PaymentFormSubTitle> */}
                        <PaymentFormSubTitle style={{ marginTop: 10 }}>
                          {`${preinvoice.items[1].description}`}
                          {/* {`${preinvoice.items[1].description} ${i18n.t('SIGNUP.paymentForm.users')}`} */}
                        </PaymentFormSubTitle>
                      </PaymentDataTableItemContainer>
                      <PaymentDataTableItemContainer>
                        <PaymentFormTitle>
                          {i18n.t('SIGNUP.paymentForm.unit')}
                        </PaymentFormTitle>
                        {/* <PaymentFormSubTitle>
                          {preinvoice.items[0].unit}
                        </PaymentFormSubTitle> */}
                        <PaymentFormSubTitle style={{ marginTop: 10 }}>
                          {preinvoice.items[1].unit}
                        </PaymentFormSubTitle>
                      </PaymentDataTableItemContainer>
                      <PaymentDataTableItemContainer>
                        <PaymentFormTitle>
                          {i18n.t('SIGNUP.paymentForm.amount')}
                        </PaymentFormTitle>
                        {/* <PaymentFormSubTitle>
                          {preinvoice.items[0].amount}€
                        </PaymentFormSubTitle> */}
                        <PaymentFormSubTitle style={{ marginTop: 10 }}>
                          {preinvoice.items[1].amount}€
                        </PaymentFormSubTitle>
                      </PaymentDataTableItemContainer>
                    </PaymentDataTableItemsContainer>
                    <ProfileFormSeparator />
                    {discountCode && discountExist && discountPorcentage
                      ? <>
                        <PaymentDataTableItemsContainer>
                          <PaymentDataTableItemContainer>
                            <PaymentFormTitle style={{ color: COLORS_TRUCK.ORANGE_TRUCK }}>
                              {i18n.t('SIGNUP.paymentForm.discount')}
                            </PaymentFormTitle>
                          </PaymentDataTableItemContainer>
                          <PaymentDataTableItemContainer>
                            <PaymentFormTitle>
                              {i18n.t('SIGNUP.paymentForm.subTotal')}
                            </PaymentFormTitle>
                            <PaymentFormSubTitle style={{ marginTop: 10, color: COLORS_TRUCK.ORANGE_TRUCK }}>
                              {discountPorcentage}%
                            </PaymentFormSubTitle>
                          </PaymentDataTableItemContainer>
                          <PaymentDataTableItemContainer>
                            <PaymentFormTitle>
                              {(preinvoice.items[0].amount + preinvoice.items[1].amount).toFixed(2)}€
                            </PaymentFormTitle>
                            <PaymentFormSubTitle style={{ marginTop: 10, color: COLORS_TRUCK.ORANGE_TRUCK }}>
                              {(preinvoice.final_amount * (discountPorcentage / 100)).toFixed(2)}€
                            </PaymentFormSubTitle>
                          </PaymentDataTableItemContainer>
                        </PaymentDataTableItemsContainer>
                        <ProfileFormSeparator />
                      </>
                      : null}
                    {preinvoice.tax
                      ? <>
                        <PaymentDataTableItemsContainer>
                          <PaymentDataTableItemContainer>
                            <PaymentFormTitle style={{ color: COLORS_TRUCK.ORANGE_TRUCK }}>
                              {i18n.t('SIGNUP.paymentForm.taxes')}
                            </PaymentFormTitle>
                          </PaymentDataTableItemContainer>
                          <PaymentDataTableItemContainer>
                            <PaymentFormTitle>
                              {i18n.t('SIGNUP.paymentForm.subTotal')}
                            </PaymentFormTitle>
                            <PaymentFormSubTitle style={{ marginTop: 10, color: COLORS_TRUCK.ORANGE_TRUCK }}>
                              {preinvoice.tax}%
                            </PaymentFormSubTitle>
                          </PaymentDataTableItemContainer>
                          <PaymentDataTableItemContainer>
                            <PaymentFormTitle>
                              {(Number(preinvoice.items[0].amount) + Number(preinvoice.items[1].amount)).toFixed(2)}€
                            </PaymentFormTitle>
                            <PaymentFormSubTitle style={{ marginTop: 10, color: COLORS_TRUCK.ORANGE_TRUCK }}>
                              {((Number(preinvoice.items[0].amount) + Number(preinvoice.items[1].amount)) * preinvoice.tax).toFixed(2)}€
                            </PaymentFormSubTitle>
                          </PaymentDataTableItemContainer>
                        </PaymentDataTableItemsContainer>
                        <ProfileFormSeparator />
                      </>
                      : null}
                    <PaymentDataTableItemsContainer>
                      <PaymentDataTableItemContainer>

                      </PaymentDataTableItemContainer>
                      <PaymentDataTableItemContainer>
                        <PaymentFormTitle style={{ fontWeight: "bold" }}>
                          {i18n.t('SIGNUP.paymentForm.total')}
                        </PaymentFormTitle>
                      </PaymentDataTableItemContainer>
                      <PaymentDataTableItemContainer>
                        <PaymentFormTitle style={{ fontWeight: "bold" }}>
                          {discountPorcentage ? ((preinvoice.final_amount - (preinvoice.final_amount * (discountPorcentage / 100))).toFixed(2)) : preinvoice.final_amount.toFixed(2)}€
                        </PaymentFormTitle>
                      </PaymentDataTableItemContainer>
                    </PaymentDataTableItemsContainer>
                  </PaymentDataTableContainer>
                </ProfileFormInputsContainer>
              </ProfileFormsContainer>
            </> : null}
          {
            currentForm === "confirmEmail"
              ? <>
                <EnterCodeButtonsContainer>
                  <PaddingTextContainer>
                    <EnterCodeTitle>
                      {i18n.t('SIGNUP.ENTER_CODE.title')}
                    </EnterCodeTitle>
                    <EnterCodeSubTitle>
                      {i18n.t('SIGNUP.ENTER_CODE.sub-title')}
                    </EnterCodeSubTitle>
                    <MessageIcon src={messageIcon} />
                    <EnterCodeDescription>
                      {i18n.t('SIGNUP.ENTER_CODE.description')}
                    </EnterCodeDescription>
                  </PaddingTextContainer>
                  <PaddingTextContainer>
                    <EnterCodeSubTitleTwo>
                      {i18n.t('SIGNUP.ENTER_CODE.didnt-receive')}
                    </EnterCodeSubTitleTwo>
                    <EnterLinkContainer>
                      <EnterLinkDot src={pointDot} />
                      <EnterCodeLink style={{ color: COLORS_TRUCK.BLACK_TRUCK, cursor: 'default' }}>
                        {i18n.t('SIGNUP.ENTER_CODE.check-spam')}
                      </EnterCodeLink>
                    </EnterLinkContainer>
                    <EnterLinkContainer>
                      <EnterLinkDot src={pointDot} />
                      <EnterCodeLink onClick={() => getConfirmEmailCode(true)}>
                        {i18n.t('SIGNUP.ENTER_CODE.resend-email')}
                      </EnterCodeLink>
                    </EnterLinkContainer>
                    <EnterLinkContainer>
                      <EnterLinkDot src={pointDot} />
                      <EnterCodeLink onClick={() => setCurrentForm("representativeAndAccessData")}>
                        {i18n.t('SIGNUP.ENTER_CODE.edit-data')}
                      </EnterCodeLink>
                    </EnterLinkContainer>
                  </PaddingTextContainer>
                  <FormTextInput
                    disabled={loading}
                    icon={keyIcon}
                    titleStyle={{ color: COLORS_TRUCK.BLACK_TRUCK, fontWeight: 'bold' }}
                    title={i18n.t('SIGNUP.ENTER_CODE.input-placeholder')}
                    type={"text"}
                    showError={showInputsErrors}
                    value={confirmEmailCode}
                    onChange={({ target: { value } }) => {
                      setConfirmEmailCode(value)
                      // localStorage.setItem("confirm-email-code", value)
                    }}
                  // required
                  />
                  <CategoryButton
                    loading={loading}
                    onClick={() => confirmEmail()}
                    style={{ marginTop: 20, marginBottom: 20 }}
                    width={300}
                    active
                    disabled={loading}
                    icon=""
                    title={i18n.t('SIGNUP.ENTER_CODE.verify')}
                  />

                </EnterCodeButtonsContainer>
              </>
              : null
          }
        </ProfileFormContainer>
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        {currentForm === "paymentForm" && <GeneralErrorContainer>
          <GeneralErrorText>{i18n.t("SIGNUP.enjoyFirstMonthFree")}</GeneralErrorText>
        </GeneralErrorContainer>}
        <ProfileButtonsContainer>
          {loading || currentForm === "confirmEmail" ? null
            : currentForm !== "paymentData"
              ? <>
                <RadioButton
                  onClick={async () => {
                    setCurrentForm("companyInfo")
                  }}
                  style={{ backgroundColor: currentForm === "companyInfo" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }}
                />
                <RadioButton
                  onClick={async () => {
                    const isValid = await validateEmail()
                    if (isValid && isCompanyInfoCompleted()) {
                      setCurrentForm("representativeAndAccessData")
                    }
                  }}
                  style={{ backgroundColor: currentForm === "representativeAndAccessData" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }}
                />
                <RadioButton
                  onClick={async () => {
                    const isValid = await validateEmail()
                    if (!isValid) {
                      setCurrentForm("representativeAndAccessData")
                    }

                    if (isValid && isCompanyInfoCompleted() && isRepresentativeAndAccessDataCompleted()) {
                      setCurrentForm("paymentPlan")
                    }
                  }}
                  style={{ backgroundColor: currentForm === "paymentPlan" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }}
                />
                <RadioButton
                  // onClick={async () => {
                  //   const isValid = await validateEmail()
                  //   if (!isValid) {
                  //     setCurrentForm("representativeAndAccessData")
                  //   }

                  //   if (isValid && isCompanyInfoCompleted() && isRepresentativeAndAccessDataCompleted() && isPaymentPlanCompleted()) {
                  //     setCurrentForm("paymentData")
                  //   }
                  // }}
                  style={{
                    backgroundColor: currentForm === "paymentForm" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.BLACK_TRUCK,
                    cursor: 'default'
                  }}
                />
              </>
              : <>
                <BackButton onClick={() => setCurrentForm("paymentForm")} route="" />
              </>}
        </ProfileButtonsContainer>
        {currentForm !== "confirmEmail"
          ? currentForm !== "paymentForm"
            ? <CategoryButton
              loading={loading}
              onClick={async () => {
                if (currentForm === "companyInfo") {
                  validateFirstStep()
                }
                if (currentForm === "representativeAndAccessData") {
                  const isValid = await validateEmail()

                  if (isValid) {
                    validateSecondStep()
                  }
                }
                if (currentForm === "paymentPlan") {
                  validateThirdStep()
                }
                if (currentForm === "paymentData") {
                  validateFourthStep()
                }
              }}
              active
              disabled={loading}
              icon=""
              title={i18n.t('SIGNUP.buttons.continue')}
            />
            : <CategoryButton
              loading={loading}
              onClick={() => setCurrentForm("paymentData")}
              active
              disabled={loading}
              icon=""
              title={i18n.t('SIGNUP.paymentForm.buttons.paymentData')}
            />
          : null}
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default SignupScreen
// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  CREATE_VEHICLE_MUTATION,
  GET_VEHICLES_MUTATION,
  UPDATE_VEHICLE_MUTATION,
  EXPORT_VEHICLES_MUTATION,
  REMOVE_VEHICLE_MUTATION
} from './vehicles-queries';
// import {handleAlert} from '../../general/functions';

/**
 * createVehicleAction - action for create a driver
 *
 * @param {object} formData - createVehicleAction data
 * @returns {any}
 */
export const createVehicleAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: CREATE_VEHICLE_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeVehicleAction - action for create a driver
 *
 * @param {string} vehicleId - vehicle id
 * @returns {any}
 */
export const removeVehicleAction = (
  vehicleId,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_VEHICLE_MUTATION,
        variables: {
          vehicle_id: vehicleId,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateVehicleAction - action for update a driver
 *
 * @param {String} vehicle_id - vehicle id
 * @param {object} formData - updateVehicleAction data
 * @returns {any}
 */
export const updateVehicleAction = (
  vehicle_id,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_VEHICLE_MUTATION,
        variables: {
          input: {
            ...formData
          },
          vehicle_id: vehicle_id,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getVehiclesAction - action for get drivers
 *
 * @param {object} formData - getVehiclesAction data
 * @returns {any}
 */
 export const getVehiclesAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_VEHICLES_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportVehicles - action for get drivers
 *
 * @param {object} formData - exportVehicles data
 * @returns {any}
 */
 export const exportVehicles = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: EXPORT_VEHICLES_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};
import React from "react";
import { UserCardContainer, UserCardImage, UserCardContentContainer, UserCardTitle, UserCardPosition, UserCardImageContainer, UserCardImageAddIcon } from "./user-card-styles";
import addImageIcon from "../../assets/user-card/add-camera-icon.svg";
import { COLORS_TRUCK } from '../../utils/colors';

const UserCard = ({ user, onEditPicture, borders = true }) => {

  const readerFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const uploadFile = (aspect) => {
    const file = document.createElement('input');
    file.type = 'file';
    file.style.display = 'none';
    file.accept = 'image/png, image/jpg, image/jpeg, image/heif, image/heic, image/webp, image/gif';
    file.onchange = (e) => handleFileInput(e, aspect);
    file.click();
  };

  const handleFileInput = async (e, aspect) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      onEditPicture(file)
    }
  };

  return (
    <UserCardContainer>
      <UserCardImageContainer>
        <UserCardImage style={{ border: borders ? `4px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : {} }} src={user?.picture && user?.picture?.name ? URL.createObjectURL(user?.picture) : user?.picture} />
        <UserCardImageAddIcon onClick={uploadFile} src={addImageIcon} />
      </UserCardImageContainer>
      <UserCardContentContainer>
        <UserCardTitle>
          {user.name}
        </UserCardTitle>
        <UserCardPosition>
          {user.position}
        </UserCardPosition>
      </UserCardContentContainer>
    </UserCardContainer>
  )
}

export default UserCard
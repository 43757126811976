import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  // ProfileButtonsContainer,
  ProfileFormContainer,
  // ProfileFormInputsContainer,
  // ProfileFormsContainer,
  // ProfileLastButtonsContainer,
  // FixedAmountText,
  // ProfileSmallButtonGroup,
  // ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  // ProfileFormSeparator,
  ProfileBillingContainer,
  TableTitle,
  // TableInfo,
  TableInfoContainer,
  TableSubTitle,
  TableInfoContainer2
} from "./journeys-styles";
import i18n from '../../i18n/i18n';
// import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import TruckTable from "../../components/truck-table/TruckTable";
import { useNavigate } from "react-router-dom";
import { getJourneysAction, exportJourneysAction } from "./journeys-actions";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import { getDrivers } from "../driver-details/driver-details-actions";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";

const JourneysScreen = () => {
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(30)
  const [driverId, setDriverId] = useState("")
  const [drivers, setDrivers] = useState([])
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [loading, setLoading] = useState("1")
  const [journeys, setJourneys] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  // const { filter } = useParams()

  useEffect(() => {
    getJourneysFunc()
    if (user?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    getDriversFunc()
  }, [limit, offset, driverId, endDate, startDate])

  const getDriversFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        email: null,
        status: null,
        type: null,
        payment_type: null,
        driver_id: null
      }
      const res = await getDrivers(data)

      setDrivers(res?.data?.myDrivers)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const getJourneysFunc = async (reset) => {
    try {
      if(reset){
        setLimit(30)
        setOffset(0)
      }

      setLoading(true)
      const data = {
        _id: null,
        pagination: {
          limit: limit,
          offset: offset
        },
        creation_datetime: startDate || null,
        finish_datetime: endDate || null,
        sort: {
          field: "createdAt",
          type: "DESC"
        },
        status: "FINISHED",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      // console.log("getJourneysAction / data: ", data)
      // console.log("getJourneysAction / driverId: ", driverId)
      const res = await getJourneysAction(driverId, data)
      // console.log("getJourneysAction / res: ", res)
      if (res?.data?.journeys.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let journeysArray = [...journeys].concat(res?.data?.journeys)
      if ((!startDate && !endDate && !driverId) || limit > 30) {
        setJourneys(journeysArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        setJourneys(res?.data?.journeys)
      }
      setLoading(false)
    } catch (e) {
      // console.log("getJourneysFunc / error: ", e)
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") {
        setJourneys([])
      }
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const exportJourneysFunc = async () => {
    try {
      const data = {
        _id: driverId || null,
        pagination: {
          limit: limit,
          offset: offset
        },
        creation_datetime: startDate || null,
        finish_datetime: endDate || null,
        status: "FINISHED",
        sort: {
          field: "createdAt",
          type: "DESC"
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const res = await exportJourneysAction(driverId, data)
      // console.log("exportJourneysFunc / res: ", res)
      var win = window.open(res.data.exportMyJourneysList.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('JOURNEYS.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      title={i18n.t('ACCOUNTING.forms.concept.driver')}
      value={driverId}
      onChange={({ target: { value } }) => {
        if(!loading){
          setLimit(30)
          setOffset(0)
          setDriverId(value)
        }
      }}
      items={drivers.map(driver => ({ value: driver._id, label: `${driver.name} ${driver.surnames}` }))}
      width={200}
      fatherContainer={{ zIndex: 99999 }}
      containerStyle={{ width: 200, marginRight: 20 }}
    />
    <TableInfoContainer2>
      <TableSubTitle>
        {i18n.t('JOURNEYS.sinceDate')}
      </TableSubTitle>
    </TableInfoContainer2>
    <FormDateInput
      title={i18n.t('ACCOUNTING.forms.concept.startDate')}
      value={startDate}
      containerStyle={{ width: 160 }}
      onChange={(newValue) => {
        setStartDate(newValue);
      }}
      disabled={loading}
    />
    <TableInfoContainer2>
      <TableSubTitle>
        {i18n.t('JOURNEYS.toDate')}
      </TableSubTitle>
    </TableInfoContainer2>
    <FormDateInput
      title={i18n.t('ACCOUNTING.forms.concept.endDate')}
      value={endDate}
      containerStyle={{ width: 160 }}
      minDate={startDate}
      // maxDate={new Date()}
      onChange={(newValue) => {
        setEndDate(newValue);
      }}
      disabled={loading}
    />
    <CategoryButton
      onClick={() => exportJourneysFunc()}
      active={false}
      icon=""
      title={i18n.t('TASKS.forms.tasks.buttons.export')}
      style={{ marginTop: 40 }}
    />
  </ProfileBillingContainer>

// console.log("journeys: ", journeys)
  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="journeys"
            header={tableHeader}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(() => {
                  return offset + increaseLimit
                })
                setLimit(() => {
                  return limit + increaseLimit
                })
              }

              getJourneysFunc()
            }}
            updateTable={(reset) => getJourneysFunc(reset)}
            titles={[
              { label: i18n.t('JOURNEYS.driver') },
              { label: i18n.t('JOURNEYS.date') },
              // { label: i18n.t('JOURNEYS.lastJourney') },
              { label: i18n.t('JOURNEYS.vehicle') },
              { label: i18n.t('JOURNEYS.trailer') },
              { label: i18n.t('JOURNEYS.start') },
              { label: i18n.t('JOURNEYS.end') },
              { label: i18n.t('JOURNEYS.distance') },
              { label: i18n.t('JOURNEYS.checklistStatus') },
              { label: i18n.t('JOURNEYS.incidents') },
              { label: i18n.t('JOURNEYS.checklist') },
            ]}
            data={journeys}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default JourneysScreen
import { gql } from '@apollo/client';

export const CREATE_TASK_MUTATION = gql`
mutation createTask($input: CreateTaskInput!) {
  createTask(input: $input){
   _id
  }
}
`;

export const CREATE_TASK_LOAD_MUTATION = gql`
mutation addTaskLoad($input: [TaskLoadWithUnloadsIdsInput!]!, $task_id: ObjectId!) {
  addTaskLoad(input: $input, task_id: $task_id){
   _id
  }
}
`;

export const CREATE_TASK_DOWNLOAD_MUTATION = gql`
mutation addTaskUnLoad($input: [TaskUnLoadWithUnloadsIdsInput!]!, $task_id: ObjectId!) {
  addTaskUnLoad(input: $input, task_id: $task_id){
   _id
  }
}
`;

export const REMOVE_TASK_MUTATION = gql`
mutation deleteTask($task_id: ObjectId!) {
  deleteTask(task_id: $task_id){
        _id
  }
}
`;

export const REMOVE_TASK_LOAD_MUTATION = gql`
mutation deleteTaskLoad($input: UpdateObjectInput!) {
  deleteTaskLoad(input: $input){
        _id
  }
}
`;

export const REMOVE_TASK_DOWNLOAD_MUTATION = gql`
mutation deleteTaskUnLoad($input: UpdateObjectInput!) {
  deleteTaskUnLoad(input: $input){
        _id
  }
}
`;

export const REMOVE_TASK_UNLOAD_MUTATION = gql`
mutation deleteTaskUnload($input: UpdateObjectInput!) {
  deleteTaskUnload(input: $input){
        _id
  }
}
`;

export const UPDATE_TASK_MUTATION = gql`
mutation updateTask($input: UpdateTaskInput!, $task_id: ObjectId!) {
  updateTask(input: $input, task_id: $task_id){
   _id
  }
}
`

export const UPDATE_TASK_LOAD_MUTATION = gql`
mutation updateTaskLoad($input: UpdateTaskLoadInput!) {
  updateTaskLoad(input: $input){
   _id
  }
}
`

export const UPDATE_TASK_DOWNLOAD_MUTATION = gql`
mutation updateTaskUnLoad($input: UpdateTaskUnloadInput!) {
  updateTaskUnLoad(input: $input){
   _id
  }
}
`

export const GET_TASKS_MUTATION = gql`
query myTasks($input: TaskFiltersInput!) {
  myTasks(input: $input){
    _id
   coords {
     country
     x
     y
   }
   driver
   driver_id
   end_date
   incidents
   name
   estimated_km
   start_date
   status
   trailer
   trailer_id
   images {
    _id
    fullpath
    object_id
    path
    type
   }
   loads {
     _id
     address
     comments
     coords
     date
     phone
     place
     ref
     timetable
     delivered
     unloads_ids
     email
   }
   unloads {
    _id
    address
    comments
    coords
    date
    phone
    place
    ref
    timetable
    delivered
    loads_ids
    email
   }
   vehicle
   vehicle_id
  }
}
`;

export const GET_TASKS_STATUSES_MUTATION = gql`
query taskStatuses($name: String) {
  taskStatuses(name: $name){
    _id
    name
    translate {
      langs {
       de
       es
       fr
       pt
       en
       it
      }
    }
  }
}
`;

export const GET_TASK_IMAGES_MUTATION = gql`
query showTaskImages($input: ShowImagesInput!) {
  showTaskImages(input: $input){
    _id
    fullpath
    path
  }
}
`;

export const EXPORT_TASKS_CSV_MUTATION = gql`
query exportMyTasks($input: TaskFiltersInput) {
  exportMyTasks(input: $input){
        fullpath
        path
        status
  }
}
`;

export const EXPORT_TASKS_EXCEL_MUTATION = gql`
query writeTaskInExcelFile($input: TaskFiltersInput) {
  writeTaskInExcelFile(input: $input){
        fullpath
        path
        status
  }
}
`;

export const IMPORT_TASKS_MUTATION = gql`
mutation processTasksFromExcel($file: Upload!) {
  processTasksFromExcel(file: $file) 
}
`;

import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const SideMenuContainer = styled.div`
max-width: 300px;
min-width: 300px;
width: 20%;
height: 90vh;
overflow: auto;
// border: 1px solid red;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
z-index: 2;
background: ${COLORS_TRUCK.WHITE_TRUCK};

@media (max-width: 1000px) {
    position: absolute;
  }
`;

export const SideMenuButtonContainer = styled.div`
width: 100%;
height: 70px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`;

export const SideMenuButtonContentContainer = styled.div`
width: 150px;
height: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const SideMenuButtonTitle = styled.p`
// font-weight: bold;
`;

export const SideMenuButtonIcon = styled.img`
width: 20px;
height: 20px;
margin-right: 10px;
`;

export const UnreadMessages = styled.div`
padding: 1px;
    border-radius: 50%;
    font-weight: bold;
    text-align: center;
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
`;
import React from "react";
import {
  TextInputContainer,
  InputIcon,
  InputTitle,
  FormTextInputMainContainer,
  // SelectIcon  
} from "./form-multi-select-styles";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { COLORS_TRUCK } from "../../../../utils/colors";
import Select from 'react-select'

const FormMultiSelect = (props) => {

  const dot = (color = 'transparent') => (props.icon ? {
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      content: `url(${props.icon})`,
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
      marginBottom: 10,
    },
  } : {});

  return (
    <FormTextInputMainContainer style={props.fatherContainer}>
      {props.title ? <InputTitle>
        {props.title}{props.required ? <span style={{ fontSize: 20 }}>{` *`}</span> : ""}
      </InputTitle>
        : null}
      <TextInputContainer style={props.containerStyle}>
        {/* {props.icon && <InputIcon src={props.icon} />} */}
        <Select
          options={props.items}
          // value={props.value}
          isMulti
          defaultValue={props.value}
          isDisabled={props.disabled}
          // value={props.value && props.items?.length > 0 ? { value: props.value, label: props.items?.filter(item => item.value === props.value)[0]?.label } : ""}
          onChange={(value, actionMeta) => {
            props.onChange({ target: { value: value[0], actionMeta } })
          }}
          styles={{
            container: (provided) => ({
              ...provided,
              width: props.width ? props.width : "100%",
              border: props.showError ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : ``
            }),
            placeholder: (provided) => ({
              ...provided,
              paddingLeft: props.icon ? 30 : ''
            }),
            singleValue: (provided) => ({
              ...provided,
              paddingLeft: props.icon ? 30 : ''
            }),
            control: (provided) => ({
              ...provided,
              minHeight: props.height ? props.height : 60,
            }),
            input: (styles) => ({ 
              ...styles, 
              minHeight: props.height ? props.height : 60,
              ...dot()
             }),
          }}
          placeholder={props.placeholder || props.title}
          // style={{ width: '100%' }}
          // onChange={value => this.handleChange(value)}
          // defaultValue={{ label: props.title, value: "" }}
        />
        {/* <Select
            style={{ width: '100%' }}
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            defaultValue={props.defaultValue}
          >
            {props.items.map((item, i) =>
              <MenuItem key={i} value={item.value}>
                {item.icon ? <SelectIcon src={item.icon} /> : ""}
                {item.label}
                </MenuItem>
            )}
          </Select> */}
        {props.rightIcon && <InputIcon
          onClick={props.iconRightClick}
          style={{ cursor: 'pointer' }}
          src={props.rightIcon}
        />}
      </TextInputContainer>
    </FormTextInputMainContainer>
  )
}

export default FormMultiSelect
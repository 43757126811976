import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';

const AlertModal = React.forwardRef((props, ref) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  if (!props.isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className='Modal'>
      <div className={`overlay ${props.overlayClassName}`} onClick={props.outsideClick}>
        <div className={`Modal__container ${props.modalClassName}`} style={props.style} onClick={handleClick} ref={ref}>
          {props.children}
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
});

export default AlertModal;
